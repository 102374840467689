const Kapsam = resolve => {
    require.ensure(['../views/apps/kapsam/kapsam'], () => {
        resolve(require('../views/apps/kapsam/kapsam'))
    })
};
const Admin = resolve => {
    require.ensure(['../views/apps/kapsam/kapsamListesi'], () => {
        resolve(require('../views/apps/kapsam/kapsamListesi'))
    })
};
const KapsamSozlesmeListesi = resolve => {
    require.ensure(['../views/apps/kapsam/kapsamSozlesmeListesi'], () => {
        resolve(require('../views/apps/kapsam/kapsamSozlesmeListesi'))
    })
};



import layouts from '../layout'
import Vue from 'vue'
Vue.component('gorevler', Admin);
Vue.component('kapsamsozlesmeler', KapsamSozlesmeListesi);


export default {
    path: '/kapsam',
    name: 'kapsam',
    component: Kapsam,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [
        {
            path: '/admin',
            name: 'kapsamlistesi',
            component: Admin,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                searchable: true,
                tags: ['app']
            }
        },
    ]
}