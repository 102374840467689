const he = require("he");
const moment = require("moment");

var funtions = {
  linkTemizle(text) {
    var trMap = {
      çÇ: "c",
      ğĞ: "g",
      şŞ: "s",
      üÜ: "u",
      ıİ: "i",
      öÖ: "o",
    };
    for (var key in trMap) {
      text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
    }
    return text
      .replace(/[^-a-zA-Z0-9\s]+/gi, "")
      .replace(/\s/gi, "-")
      .replace(/[-]+/gi, "-")
      .toLowerCase();
  },
  strip_html_tags(str) {
    if (str) {
      str = str.replace(/&nbsp;/gi, "");
      if (str === null || str === "") return false;
      else str = str.toString();
      return str.replace(/<[^>]*>/g, "");
    }
  },
  randomText(length) {
    let today = new Date();
    let day = today.getDate();
    let mounth = today.getMonth() + 1;
    let year = today.getFullYear();
    let bugun =
      year +
      "" +
      (mounth <= 9 ? "0" + mounth : mounth) +
      "" +
      (day <= 9 ? "0" + day : day);

    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return bugun + "-" + text;
  },
  htmlDecode(input) {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  },

  htmlDecode2(input) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(input, "text/html");
    return doc.body.innerHTML;
  },

  updateListImage(self, type, durum, name) {
    var item = {
      type: type,
      value: [
        {
          durum: durum,
          [type]: name,
        },
      ],
    };
    self.updateData.push(item);
  },
  updateListFile(self, type, durum, link, name) {
    var item = {
      type: type,
      value: [
        {
          durum: durum,
          link: link,
          belgeAdi: name,
        },
      ],
    };
    self.updateData.push(item);
  },

  routeSayfa(name, self) {
    let tabs = [...self.$store.getters.getTabList];
    var header = this.linkTemizle(name.replace(" ", ""));
    var obj = tabs.find((data) => data.name === header);
    if (obj === undefined) {
      var item = {
        title: name,
        name: header,
        contentName: header,
      };
      self.$store.commit("changeTabListAdd", item);
      self.$store.commit("changeActiveTabs", header);
    } else {
      self.$store.commit("changeActiveTabs", header);
    }
  },

  sayfaKapat(sayfa, self) {
    let tabs = [...self.$store.getters.getTabList];
    var obj = tabs.find((data) => data.name === sayfa);
    if (obj !== undefined) {
      tabs.forEach((tab, index) => {
        if (tab.name === sayfa) {
          self.$store.commit("changeTabListRemove", index);
          if (tabs[index]) {
            self.$store.commit("changeActiveTabs", tabs[index].name);
          } else if (tabs[index - 1]) {
            self.$store.commit("changeActiveTabs", tabs[index - 1].name);
          }
        }
      });
    }
    self.$store.commit("changeTabsClose", true);
  },

  formatMoney(str) {
    // vigullu noktalı para değerini sadece noktalı formata çevirme
    var string = str.replace(/[.,]/g, function (match) {
      return match === "." ? "," : ".";
    });
    return this.addDecimalZeros(string.replace(/,/g, "")).replace(/\s/g, "");
  },

  addDecimalZeros(str) {
    // virgulden sonra kusurat yoksa kusurat ekleme
    if (str.indexOf(".") !== -1) {
      let decimal = str.split(".")[1];
      if (decimal.length === 1) {
        str = str + "0";
      } else if (decimal.length === 0) {
        str = str + ".00";
      }
    } else {
      str = str + ".00";
    }
    return str;
  },

  decodeMoney(value) {
    // veritabından gelen para değerlerini virgullu noktalı formata cevirme
    if (value) {
      value = this.addDecimalZeros(value.toString());
      let parts = value.split(".");
      let beforeDecimal = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      let afterDecimal = parts[1] ? "," + parts[1] : "";
      return (beforeDecimal + afterDecimal).replace(/\s/g, "");
    }
    return "0,00";
  },

  removeSlashesAndDecodeStrings(obj) {
    // objedeki tüm stringlerdeki \ ları silen ve decode edilmiş özel karakterleri encode eden fonksiyon
    // veritabanına veriler kayıt edilirken guvenlik acısından özel karakterler (< gibi) decode edilerek (temizle fonksiyonlarından geçiriyorlar) kaydediliyor.
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === "string" && obj[key] != "") {
          obj[key] = obj[key].replace(/\\/g, "");
          obj[key] = he.decode(obj[key]);
        }
      }
    }
    return obj;
  },

  // bulundugumuz yıldaki aylarda kaç gün var bulup donduren fonksiyon
  daysInMonths() {
    const year = moment().year(); // Bulunduğumuz yılın değerini alıyoruz
    const months = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ]; // Tüm ayların isimlerini alıyoruz
    const daysInMonths = []; // Her ayın ismini ve kaç gün olduğunu saklayacağımız bir dizi tanımlıyoruz
    const monthsColor = [
      "#B7A5E1",
      "#98A8E3",
      "#98C7E5",
      "#7FE2CE",
      "#80D97F",
      "#C2E797",
      "#FFD297",
      "#FEBA97",
      "#FFA897",
      "#F5A6C3",
      "#D292DC",
      "#F47697",
    ]; // her ay için bir renk kodu atanıcak

    for (let i = 0; i < 12; i++) {
      const monthName = months[i];
      const daysCount = moment(
        `${i + 1}-01-${year}`,
        "MM-DD-YYYY"
      ).daysInMonth();
      daysInMonths.push({
        month: monthName,
        days: daysCount,
        color: monthsColor[i],
        monthIndex: i + 1,
        count: 0,
      });
    }
    return daysInMonths;
  },
  /*
    daysInMonths(startingYear, numberOfYears) {
        const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']; // Tüm ayların isimlerini alıyoruz
        const monthsColor = ['#B7A5E1', '#98A8E3', '#98C7E5', '#7FE2CE', '#80D97F', '#C2E797', '#FFD297', '#FEBA97', '#FFA897', '#F5A6C3', '#D292DC', '#F47697']; // her ay için bir renk kodu atanıcak
    
        const daysInMonths = [];
    
        for (let yearIndex = 0; yearIndex < numberOfYears; yearIndex++) {
            const year = startingYear + yearIndex;
    
            for (let i = 0; i < 12; i++) {
                const monthName = months[i];
                const daysCount = moment(`${i + 1}-01-${year}`, "MM-DD-YYYY").daysInMonth();
                daysInMonths.push({ year: year, month: monthName, days: daysCount, color: monthsColor[i], monthIndex: i + 1 });
            }
        }
    
        return daysInMonths;
    },*/

  daysInMonths2(startingMonth) {
    const year = moment().year(); // Bulunduğumuz yılın değerini alıyoruz
    const months = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ]; // Tüm ayların isimlerini alıyoruz
    const daysInMonths = []; // Her ayın ismini ve kaç gün olduğunu saklayacağımız bir dizi tanımlıyoruz
    const monthsColor = [
      "#B7A5E1",
      "#98A8E3",
      "#98C7E5",
      "#7FE2CE",
      "#80D97F",
      "#C2E797",
      "#FFD297",
      "#FEBA97",
      "#FFA897",
      "#F5A6C3",
      "#D292DC",
      "#F47697",
    ]; // her ay için bir renk kodu atanıcak

    const startingMonthIndex = months.findIndex(
      (month) => month.toLowerCase() === startingMonth.toLowerCase()
    );
    if (startingMonthIndex === -1) {
      console.error("Geçersiz başlangıç ayı: " + startingMonth);
      return daysInMonths;
    }

    for (let i = startingMonthIndex; i < months.length; i++) {
      const monthName = months[i];
      const daysCount = moment(
        `${i + 1}-01-${year}`,
        "MM-DD-YYYY"
      ).daysInMonth();
      daysInMonths.push({
        month: monthName,
        days: daysCount,
        color: monthsColor[i],
        monthIndex: i + 1,
      });
    }
    return daysInMonths;
  },

  daysInMonths3() {
    const now = new Date(); // Şu anki tarihi alıyoruz
    const currentYear = now.getFullYear(); // Şu anki yıl
    const nextYear = currentYear + 1; // Bir sonraki yıl

    const months = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];

    const monthsColor = [
      "#B7A5E1",
      "#98A8E3",
      "#98C7E5",
      "#7FE2CE",
      "#80D97F",
      "#C2E797",
      "#FFD297",
      "#FEBA97",
      "#FFA897",
      "#F5A6C3",
      "#D292DC",
      "#F47697",
    ];

    const daysInMonths = [];

    for (let i = 0; i < 24; i++) {
      const year = currentYear + Math.floor(i / 12); // Yıl hesaplaması

      const monthIndex = i % 12;
      const monthName = months[monthIndex];
      const daysCount = new Date(year, monthIndex + 1, 0).getDate(); // Ayın son gününü alıyoruz

      daysInMonths.push({
        month: monthName,
        days: daysCount,
        color: monthsColor[monthIndex],
        year: year,
        monthIndex: monthIndex + 1,
        count: 0,
      });
    }

    return daysInMonths;
  },

  getStartingMonthIndex(baslangicTarihi) {
    const startingMonth = moment(baslangicTarihi).month() + 1;
    return startingMonth;
  },

  // Hex kodu verilen renkleri alpha değerine gore rgba formatına cevirir
  hexToRgba(hex, alpha) {
    // hex kodunu parçalara ayır
    let r = parseInt(hex.substring(1, 3), 16);
    let g = parseInt(hex.substring(3, 5), 16);
    let b = parseInt(hex.substring(5, 7), 16);
    // rgba formatına çevir
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  },

  findObjectDifference(obj1, obj2) {
    const diff = {};
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (
          key == "resim" &&
          Array.isArray(obj2[key]) &&
          obj2[key].length >= 1
        ) {
          diff[key] = obj2[key];
        } else if (key != "resim" && obj1[key] !== obj2[key]) {
          diff[key] = obj2[key];
        }
      }
    }

    return diff;
  },
};
export default funtions;
