import WsProvider from './provider/WSProvider'

const serviceName = "webservis";
const moduleName = "sayfa";

var sayfaService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    sayfaList(baslangic, limit, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            baslangic,
            limit,
            durum,
            serviceName: moduleName,
            methodName: "sayfaListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    sayfaAra(baslik, durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            baslik,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "sayfaArama"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    sayfaAktifPasifSayisi() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "sayfaAktifPasifSayisi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    sayfaCount() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "sayfaSayisi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    sayfaDurumSayisi(durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum,
            serviceName: moduleName,
            methodName: "sayfaDurumSayisi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    menuSayfalar(durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            goster: durum,
            serviceName: moduleName,
            methodName: "menuSayfaListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    sayfaDurumGuncelle(sayfaID, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            sayfaID: sayfaID,
            durum: durum,
            serviceName: moduleName,
            methodName: "sayfaAktifPasifEt"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });

        return result;
    },

    sayfaPasifYap(sayfaID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            sayfaID: sayfaID,
            durum: 0,
            serviceName: moduleName,
            methodName: "sayfaAktifPasifEt"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    sayfaAktifYap(sayfaID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            sayfaID: sayfaID,
            durum: 1,
            serviceName: moduleName,
            methodName: "sayfaAktifPasifEt"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    menuKategoriListesi() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            data: {
                token: userDataBGSurec,
                serviceName: "menu",
                methodName: "menuKategoriListele"
            }
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    sayfaDetay(sayfaID, link) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            sayfaID: sayfaID,
            link: link,
            serviceName: moduleName,
            methodName: "sayfaDetay"

        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    //'ustSayfaID','goster','baslik','altBaslik','icerik','resimKonum','menuKonum','galeriKonum','durum, 'seoKategori','seoSorguTuru','durum','seoSayac', 'ustMenuID','menuKategoriID','menuBaslik','menuIcon','durum','menuSiraNo'
    sayfaGuncelle(sayfaID, ustSayfaID, goster, ustMenuID, menuIcon, menuKategoriID, menuBaslik, menuSiraNo, baslik, altBaslik, icerik, sunucudanEklenenResimler, resim, resimKonum, menuKonum, galeriKonum, durum, seoBaslik, seoIcerik, link) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            sayfaID: sayfaID,
            ustSayfaID: ustSayfaID,
            goster: goster,
            ustMenuID: ustMenuID,
            menuKategoriID: menuKategoriID,
            menuBaslik: menuBaslik,
            menuSiraNo: menuSiraNo,
            baslik: baslik,
            altBaslik: altBaslik,
            icerik: icerik,
            sunucudanEklenenResimler: sunucudanEklenenResimler,
            resim: resim,
            resimKonum: resimKonum,
            menuKonum: menuKonum,
            galeriKonum: galeriKonum,
            durum: durum,
            seoKategori: 1,
            seoSorguTuru: 1,
            seoDurum: durum,
            seoSayac: 0,
            link: link,
            menuIcon: menuIcon,
            seoBaslik,
            seoIcerik,
            serviceName: moduleName,
            methodName: "sayfaGuncelle"
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },


    sayfaEkle(ustSayfaID, goster, ustMenuID, menuKategoriID, menuBaslik, menuSiraNo, baslik, altBaslik, icerik, sunucudanEklenenResimler, resim, icon, resimKonum, menuKonum, galeriKonum, durum, seoBaslik, seoIcerik) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            ustSayfaID: ustSayfaID,
            goster: goster,
            ustMenuID: ustMenuID,
            menuKategoriID: menuKategoriID,
            menuBaslik: menuBaslik,
            menuSiraNo: menuSiraNo,
            baslik: baslik,
            altBaslik: altBaslik,
            icerik: icerik,
            sunucudanEklenenResimler: sunucudanEklenenResimler,
            resim: resim,
            menuIcon: icon,
            resimKonum: resimKonum,
            menuKonum: menuKonum,
            galeriKonum: galeriKonum,
            durum: durum,
            seoBaslik: seoBaslik,
            seoIcerik: seoIcerik,
            seoKategori: 1,
            seoSorguTuru: 1,
            seoDurum: durum,
            seoSayac: 0,
            serviceName: moduleName,
            methodName: "sayfaEkle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result

    }


}

export default sayfaService