const Tutanak = resolve => {
    require.ensure(['../views/apps/tutanak/Tutanak'], () => {
        resolve(require('../views/apps/tutanak/Tutanak'))
    })
};
const TutanakTanimla = resolve => {
    require.ensure(['../views/apps/tutanak/TutanakTanimla'], () => {
        resolve(require('../views/apps/tutanak/TutanakTanimla'))
    })
};
const TutanakListesi = resolve => {
    require.ensure(['../views/apps/tutanak/TutanakListesi'], () => {
        resolve(require('../views/apps/tutanak/TutanakListesi'))
    })
};
const TutanakGuncelle = resolve => {
    require.ensure(['../views/apps/tutanak/TutanakGuncelle'], () => {
        resolve(require('../views/apps/tutanak/TutanakGuncelle'))
    })
};


import layouts from '../layout'
import Vue from 'vue'
Vue.component('tutanaklistesi', TutanakListesi);
Vue.component('tutanaktanimla', TutanakTanimla);
Vue.component('tutanakguncelle', TutanakGuncelle);


export default {
    path: '/tutanak',
    name: 'tutanak',
    component: Tutanak,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [{
        path: 'liste',
        name: 'tutanakListesi',
        component: TutanakListesi,
        meta: {
            auth: true,
            layout: layouts.navLeft,
            searchable: true,
            tags: ['app']
        }
    }],

}