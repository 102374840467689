<template>
    <div>
        <FullCalendar
            id='calendar'
            ref="fullCalendar"
            :defaultView="defaultView"
            :events="eventsOrt"
            :locale="locale"
            :eventTimeFormat="eventTimeFormats"
            :plugins="calendarPlugins"
            :firstDay="options.firstDay"
            :customButtons="options.customButtons"
            :header="options.headerOrt"
            :height="options.height"
            :allDaySlot="options.allDaySlot"
            :slotEventOverlap="options.slotEventOverlap"
            :selectable="options.selectable"
            :navLinks="options.navLinks"
            :editable="options.editable"
            :eventLimit="options.eventLimit"
            :dayMaxEventRows="true"
            @dateClick="dayClick"
            @eventClick="eventClick"
            class="calendar-wrap card-base card-shadow--medium"
        />
    </div>
</template>


<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import {Calendar} from '@fullcalendar/core';
import trLocale from '@fullcalendar/core/locales/tr';

export default {
    components: {
        FullCalendar,
    },
    data(){
        return {
            defaultView: "dayGridMonth",
            eventsOrt: [
                { title: 'ABS - ALTYAPI  YAĞMUR SUYU TESİSATI', color: '#C26AD0', start: '2023-02-01', end: '2023-02-10'},     // yeşil : 1abc9c pembe: ff7979 sarı: f1c40f mavi:3990ae
                { title: 'Akpolat - YANGIN TESİSATI &HİDRANT', color: '#700500', date: '2023-02-01', end:'2023-02-03'},
                { title: 'BG Prefabrik - ARA KAT TOPPING BETONU', color: '#7A0055', start: '2023-02-01', end:'2023-02-09' },
                { title: 'Murat Kablo - OTOMATİK KEPENK', color: '#4C9DD2', start: '2023-02-01', end: '2023-02-03'},
                { title: 'Karakol - KÖŞE TENEKE KAPAMALAR', color: '', start: '2023-02-03', end:'2023-03-15' },
                { title: 'Uzay - CEPHE POLİCARBON', start: '2023-02-03', end: '2023-02-05', color: '#F906E7' },
                { title: 'ABS - ARAZİ TESVİYE &DOLGU', start: '2023-02-06', end: '2023-02-13', color: '#0329BA' },
                { title: 'KNB Ayakkabı - ISI YALITIMI', start: '2023-02-09', end: '2023-02-12', color: '#98C7E5' },
                { title: 'ÖZPA A - KAROLAJ ÇELİKLERİ', start: '2023-02-12', start: '2023-02-20', color: '#006668' },
                { title: 'ÖZPA B - KAROLAJ ÇELİKLERİ', start: '2023-02-09', end: '2023-02-12', color: '#006668' },
                { title: 'ÖZPA C - ISI YALITIMI', start: '2023-02-09', end: '2023-02-15', color: '#98C7E5' },    
                { title: 'Selen - TEMEL DOLGU', start: '2023-02-18', end: '2023-02-22', color: '#f1c00CA9C40f' },               
                { title: 'Selen - CEPHE POLİCARBON', start: '2023-02-14', end: '2023-02-19', color: '#F906E7' },
                { title: 'Tezmaksan A - TEMEL SU YALITIMI', start: '2023-02-14', end: '2023-03-15', color: '#6B003B' },
                { title: 'Tezmaksan B - TEMEL DOLGU', start: '2023-02-17', end: '2023-02-27', color: '#00CA9C' },
                { title: 'Tezmaksan C - TEMEL DOLGU', start: '2023-02-19', end: '2023-05-20', color: '#00CA9C' },
                { title: 'Akpolat - KÖŞE TENEKE KAPAMALAR', start: '2023-02-14', end: '2023-02-28', color: '#6F4CC0' },
                { title: 'Karakol - CEPHE POLİCARBON', start: '2023-02-17', end: '2023-02-06', color: '#F906E7' },
                { title: 'Emay Panel - ARAZİ TESVİYE &DOLGU', start: '2023-02-22', end: '2023-02-25', color: '#0329BA' },
                { title: 'Şahin Metal - ELEKTRİK İŞLERİ', start: '2023-02-22', end: '2023-02-26', color: '#C2E797' },
                { title: 'Gökrail 2 - ISI YALITIMI', start: '2023-02-25', color: '#98C7E5' },
                { title: 'Gökrail 3 - ARAZİ TESVİYE &DOLGU', start: '2023-02-25', end: '2023-03-10', color: '#0329BA' },
                { title: 'Murat Kablo - TEMEL SU YALITIMI', start: '2023-02-25', end: '2023-03-15', color: '#6B003B' },
            ],
            locale: trLocale,
            eventTimeFormats: {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            },
            calendarPlugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
            options: {
                    customButtons: {
                        addEvent2: {
                            text: '✚',
                            click: this.cerrahiEkleme
                        }
                    },
                    header: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth addEvent2'
                    },
                    headerOrt: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
                    },
                    height: "auto",
                    firstDay: 1,
                    allDaySlot: true,
                    slotEventOverlap: true,
                    selectable: true,
                    selectHelper: true,
                    timeFormat: 'HH:mm',
                    navLinks: true, // can click day/week names to navigate views
                    editable: false,
                    eventLimit: true, // allow "more" link when too many events
                }
        }
    },

    methods: {
        dayClick(arg) {
            //console.log("day click : ",{arg})
        },

        eventClick(arg) {
            //  console.log("event click : ",{arg})
        },
    }
}
</script>


<style lang="scss">
    @import '~@fullcalendar/core/main.css';
    @import '~@fullcalendar/daygrid/main.css';
    @import '~@fullcalendar/timegrid/main.css';
    @import '~@fullcalendar/list/main.css';

</style>