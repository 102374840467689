const Gecikme = resolve => {
    require.ensure(['../views/apps/gecikme/Gecikme'], () => {
        resolve(require('../views/apps/gecikme/Gecikme'))
    })
};
const GecikmeListesi = resolve => {
    require.ensure(['../views/apps/gecikme/GecikmeListesi'], () => {
        resolve(require('../views/apps/gecikme/GecikmeListesi'))
    })
};


import layouts from '../layout'
import Vue from 'vue'
Vue.component('gecikmeler', GecikmeListesi);

export default {
    path: '/gecikmeler',
    name: 'gecikmeler',
    component: GecikmeListesi,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
}