import WsProvider from "./provider/WSProvider";
import axios from "axios";

var serviceName = "webservis";
var moduleName = "yonetim";

var yoneticiService = {
  imagePath: WsProvider.personelResim,
  imagePathBuyuk: WsProvider.imagePathBuyuk,
  imagePathOrta: WsProvider.imagePathOrta,
  imagePathKucuk: WsProvider.imagePathKucuk,
  belgePath: WsProvider.documentPath,
  path: WsProvider.path,

  girisYap(kullaniciAdi, sifre) {
    var param = {
      user: kullaniciAdi,
      pwd: sifre,
      methodName: "logIn",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yetkiAtama(yoneticiID, atanacakYetkiler) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      yoneticiID: yoneticiID,
      atanacakYetkiler: atanacakYetkiler,
      methodName: "yetkiAtama",
      serviceName: moduleName,
    };

    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  sifreSmsGonder(yoneticiID) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      yoneticiID: yoneticiID,
      methodName: "sifreSmsGonder",
      serviceName: moduleName,
    };

    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  gorevAtama(yoneticiID, musteriID, projeID, sozlesmeIDler) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      yoneticiID: yoneticiID,
      musteriID: musteriID,
      projeID: projeID,
      sozlesmeIDler: sozlesmeIDler,
      methodName: "gorevAtama",
      serviceName: moduleName,
    };

    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  gorevAtamaKategori(yoneticiID, musteriID, projeID, sozlesmeKategoriIDler) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      yoneticiID: yoneticiID,
      musteriID: musteriID,
      projeID: projeID,
      sozlesmeKategoriIDler: sozlesmeKategoriIDler,
      methodName: "gorevAtamaKategori",
      serviceName: moduleName,
    };

    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yetkiListesi() {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      serviceName: moduleName,
      methodName: "yetkiListesi",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticilerinYetkileri() {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      serviceName: moduleName,
      methodName: "yoneticilerinYetkileri",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticiGorevListele() {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      serviceName: moduleName,
      methodName: "yoneticiGorevListele",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticiGorevListele2() {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      serviceName: moduleName,
      methodName: "yoneticiGorevListele2",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticiTanimlama(
    yonetimKodu,
    yetkiTuru,
    sozlesmeKategoriID,
    tcNo,
    resim,
    yoneticiAdi,
    sifre,
    adSoyad,
    gorev,
    eposta,
    cepTelefonu,
    sabitTelefon,
    dahiliTelefon
  ) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      yonetimKodu: yonetimKodu,
      yetkiTuru: yetkiTuru,
      sozlesmeKategoriID: sozlesmeKategoriID,
      TC: tcNo,
      adSoyad: adSoyad,
      gorev: gorev,
      eposta: eposta,
      resim: resim,
      cepTelefonu: cepTelefonu,
      sabitTelefon: sabitTelefon,
      dahiliTelefon: dahiliTelefon,
      yoneticiAdi: yoneticiAdi,
      sifre: sifre,
      durum: 1,
      methodName: "yoneticiEkle",
      serviceName: moduleName,
    };

    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticiTanimlama2(
    yonetimKodu,
    yetkiTuru,
    sozlesmeKategoriID,
    tcNo,
    resim,
    yoneticiAdi,
    adSoyad,
    gorev,
    eposta,
    cepTelefonu,
    sabitTelefon,
    dahiliTelefon
  ) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      yonetimKodu: yonetimKodu,
      yetkiTuru: yetkiTuru,
      sozlesmeKategoriID: sozlesmeKategoriID,
      TC: tcNo,
      adSoyad: adSoyad,
      gorev: gorev,
      eposta: eposta,
      resim: resim,
      cepTelefonu: cepTelefonu,
      sabitTelefon: sabitTelefon,
      dahiliTelefon: dahiliTelefon,
      yoneticiAdi: yoneticiAdi,
      durum: 1,
      methodName: "yoneticiEkle",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticiFilter(
    personelAdi,
    personelKullaniciAdi,
    yetkiTuru,
    sozlesmeKategoriAdi,
    telefon,
    dahili,
    sabit,
    durum,
    baslangic,
    limit,
    sozlesmeKategoriID
  ) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      personelAdi,
      personelKullaniciAdi,
      yetkiTuru,
      sozlesmeKategoriAdi,
      telefon,
      dahili,
      sabit,
      durum,
      baslangic,
      limit,
      sozlesmeKategoriID,
      serviceName: moduleName,
      methodName: "yoneticiFilter",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticiGuncelle(
    yoneticiID,
    sozlesmeKategoriID,
    resim,
    adSoyad,
    eposta,
    cepTelefonu,
    sabitTelefon,
    dahiliTelefon,
    rol,
    yoneticiAdi
  ) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      yoneticiID: yoneticiID,
      sozlesmeKategoriID: sozlesmeKategoriID,
      resim: resim,
      adSoyad: adSoyad,
      eposta,
      yoneticiAdi,
      cepTelefonu: cepTelefonu,
      sabitTelefon: sabitTelefon,
      dahiliTelefon: dahiliTelefon,
      rol,
      durum: 1,
      methodName: "yoneticiGuncelle",
      serviceName: moduleName,
    };

    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  atamaDetay(yoneticiID) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      yoneticiID,
      serviceName: moduleName,
      methodName: "atamaDetay",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  gorevAtamaSil(gorevAtamaID) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      gorevAtamaID,
      serviceName: moduleName,
      methodName: "gorevAtamaSil",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticiListe(durum, baslangic, limit, yetkiTuru) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      durum,
      baslangic,
      limit,
      yetkiTuru,
      methodName: "listele",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticiListe2(sozlesmeKategoriID, durum, baslangic, limit) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      durum,
      baslangic,
      limit,
      methodName: "listele",
      serviceName: moduleName,
    };
    if (sozlesmeKategoriID !== "0" || sozlesmeKategoriID !== "-1")
      param.sozlesmeKategoriID = sozlesmeKategoriID;
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticiCount(durum) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      durum,
      methodName: "yoneticiSayisi",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  getAccount() {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      methodName: "getAccount",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticiSifreGuncelle(yoneticiAdi, yeniSifre) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      yoneticiAdi: yoneticiAdi,
      yeniSifre: yeniSifre,
      methodName: "yoneticiSifreGuncelle",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  // yoneticinin kendi şifresini güncellediği metot
  sifreGuncelle(eskiSifre, yeniSifre, yeniSifreTekrar) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      eskiSifre: eskiSifre,
      yeniSifre: yeniSifre,
      yeniSifreTekrar: yeniSifreTekrar,
      methodName: "sifreGuncelle",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticiAktifYap(yoneticiID) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      yonetimID: yoneticiID,
      durum: 1,
      methodName: "aktifPasifEt",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticiPasifYap(yoneticiID) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      yonetimID: yoneticiID,
      durum: 0,
      methodName: "aktifPasifEt",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  musterideGorevliPersoneller(musteriID) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      musteriID,
      serviceName: moduleName,
      methodName: "yoneticiMusterileri",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  yoneticiSearch(params) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      param: params,
      serviceName: moduleName,
      methodName: "musterininYoneticileriListele",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  getYoneticiList(baslangic, limit, durum, yetkiTuru) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      baslangic,
      limit,
      durum,
      yetkiTuru,
      methodName: "yoneticiListesi",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  sifremiUnuttum() {},
};
export default yoneticiService;
