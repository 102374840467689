const Ozluk = resolve => {
    require.ensure(['../views/apps/ozluk/Ozluk'], () => {
        resolve(require('../views/apps/ozluk/Ozluk'))
    })
};
const OzlukTanimla = resolve => {
    require.ensure(['../views/apps/ozluk/OzlukTanimla'], () => {
        resolve(require('../views/apps/ozluk/OzlukTanimla'))
    })
};
const OzlukListesi = resolve => {
    require.ensure(['../views/apps/ozluk/OzlukListesi'], () => {
        resolve(require('../views/apps/ozluk/OzlukListesi'))
    })
};
const OzlukGuncelle = resolve => {
    require.ensure(['../views/apps/ozluk/OzlukGuncelle'], () => {
        resolve(require('../views/apps/ozluk/OzlukGuncelle'))
    })
};


import layouts from '../layout'
import Vue from 'vue'

Vue.component('personelozluk-tanimla', OzlukTanimla);
Vue.component('personelozluk-listesi', OzlukListesi);
Vue.component('personelozluk-guncelle', OzlukGuncelle);


export default {
    path: '/ozluk',
    name: 'ozluk',
    component: Ozluk,
    meta: {
        layout: layouts.navLeft
    }

}