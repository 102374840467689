const Galeri = resolve => {
    require.ensure(['../views/apps/galeri/Galeri'], () => {
        resolve(require('../views/apps/galeri/Galeri'))
    })
};
const GaleriTanimla = resolve => {
    require.ensure(['../views/apps/galeri/GaleriTanimla'], () => {
        resolve(require('../views/apps/galeri/GaleriTanimla'))
    })
};
const GaleriListele = resolve => {
    require.ensure(['../views/apps/galeri/GaleriListe'], () => {
        resolve(require('../views/apps/galeri/GaleriListe'))
    })
};

const GaleriGuncelle = resolve => {
    require.ensure(['../views/apps/galeri/GaleriGuncelle'], () => {
        resolve(require('../views/apps/galeri/GaleriGuncelle'))
    })
};

import Vue from "vue"
import layouts from '../layout'
Vue.component("gallery", Galeri)
Vue.component("addgallery", GaleriTanimla)
Vue.component("gallerylist", GaleriListele)
Vue.component("updategallery", GaleriGuncelle)

export default{
    path: "gallery",
    name: "galeri",
    component: Galeri,
    meta: {
        layout: layouts.navLeft
    },
}