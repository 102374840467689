const Faaliyet = resolve => {
    require.ensure(['../views/apps/faaliyet/Faaliyet'], () => {
        resolve(require('../views/apps/faaliyet/Faaliyet'))
    })
};
const FaaliyetTanimla = resolve => {
    require.ensure(['../views/apps/faaliyet/FaaliyetTanimla'], () => {
        resolve(require('../views/apps/faaliyet/FaaliyetTanimla'))
    })
};
const FaaliyetListesi = resolve => {
    require.ensure(['../views/apps/faaliyet/FaaliyetListesi'], () => {
        resolve(require('../views/apps/faaliyet/FaaliyetListesi'))
    })
};
const FaaliyetGuncelle = resolve => {
    require.ensure(['../views/apps/faaliyet/FaaliyetGuncelle'], () => {
        resolve(require('../views/apps/faaliyet/FaaliyetGuncelle'))
    })
};
const FaaliyetDetay = resolve => {
    require.ensure(['../views/apps/faaliyet/FaaliyetDetay'], () => {
        resolve(require('../views/apps/faaliyet/FaaliyetDetay'))
    })
};


import layouts from '../layout'
import Vue from 'vue'

Vue.component('faaliyetlistesi', FaaliyetListesi);
Vue.component('faaliyettanimla', FaaliyetTanimla);
Vue.component('faaliyetguncelle', FaaliyetGuncelle);
Vue.component('faaliyetdetay', FaaliyetDetay);


export default {
    path: '/faaliyet',
    name: 'faaliyet',
    component: Faaliyet,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [
        {
            path: 'liste',
            name: 'tatilListesi',
            component: FaaliyetListesi,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                searchable: true,
                tags: ['app']
            }
        },
    ],

}