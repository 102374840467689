<template>
    <div class="login-page">
        <el-row style="height: 100%;margin: 0">
            <el-col :lg="8" :md="24" :sm="24" :xs="24" style="height: 100%">
                <div class="box grow scrollable only-y align-vertical side-box box-right login1" style="height: 100%">
                    <div class="align-vertical-middle wrapper text-center">
                        <form v-if="!secure" @submit.prevent="login">
                            <img class="image-logo" src="../../../assets/images/avatar.jpg"
                                style=" border-radius: 50%; margin-bottom: 20px;width: 120px" alt="logo-left" />
                            <div style="width: 100%; display: flex; justify-content: center">
                                <float-label class="styled">
                                    <input type="username" name="username"
                                        :placeholder="$t('src.views.authentication.login.kullaniciAdi')" v-model="username">
                                </float-label>
                            </div>
                            <div style="width: 100%; display: flex; justify-content: center">
                                <float-label v-if="!sifreUnuttum" class="styled animated fadeInRight">
                                    <input type="password" name="password"
                                        :placeholder="$t('src.views.authentication.login.sifre')" v-model="password">
                                </float-label>
                            </div>
                            <div style="width: 100%; display: flex; justify-content: center">
                                <float-label v-if="sifreUnuttum" label="TC NO" class="styled">
                                    <input v-mask="'###########'" :placeholder="$t('src.views.authentication.login.tcNo')"
                                        v-model="tcNo">
                                </float-label>
                            </div>
                            <!-- <div v-if="!sifreUnuttum" class="flex" style="justify-content: center">
                                <div class="box grow animated fadeInRight">
                                    <el-button type="text" v-on:click="sifreUnuttum = true">
                                        {{$t('src.views.authentication.login.sifremiUnuttum')}}
                                    </el-button>
                                </div>
                            </div> -->
                            <div class="flex text-center center pt-30 pb-10" id="login-btn">
                                <el-button v-loading="loading" v-if="!sifreUnuttum" plain size="small" native-type="submit"
                                    class="login-btn pulse animated themed animated fadeInRight">
                                    {{ $t('src.views.authentication.login.login') }}
                                </el-button>
                                <el-button v-if="sifreUnuttum" plain size="small" @click="sifremiUnuttum"
                                    class="login-btn pulse animated themed animated fadeInRight">
                                    {{ $t('src.views.authentication.login.sifreYenile') }}
                                </el-button>
                            </div>
                            <div style="margin-top: 10px">
                                <el-button v-if="sifreUnuttum" v-on:click="sifreUnuttum = false"
                                    class="buttonPage left animated fadeInRight delay-1s"
                                    style="float: left; cursor: pointer" icon="mdi mdi-chevron-double-left" type="text"
                                    size="small">
                                </el-button>
                            </div>
                        </form>


                        <form v-if="secure" @submit.prevent="sifremiUnuttum">
                            <img class="image-logo" src="../../../assets/images/security.png"
                                style="margin-bottom: 20px;width: 120px" alt="logo-left" />
                            <p class="font-weight-600 font-size-12 pt-10 pb-10">Protecting your information is our priority.
                                <br> Please confirm your account by entering the security code sent to your phone.
                            </p>
                            <div v-if="visPinCode" style="display: flex; flex-direction: row; justify-content: center">
                                <v-otp-input ref="otpInput" input-classes="otp-input" separator="-" :num-inputs="6"
                                    :should-auto-focus="true" :is-input-num="true" @on-complete="handleOnComplete" />
                            </div>
                            <div v-if="visPinCode" class="base-timer" style="display: flex; justify-content: center">
                                <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <g class="base-timer__circle">
                                        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                                        <path :stroke-dasharray="circleDasharray" class="base-timer__path-remaining"
                                            :class="remainingPathColor" d="M 50, 50
                                        m -45, 0
                                        a 45,45 0 1,0 90,0
                                        a 45,45 0 1,0 -90,0 "></path>
                                    </g>
                                </svg>
                                <span class="base-timer__label">{{ formattedTimeLeft }}</span>
                            </div>
                            <div v-if="!visPinCode" class="flex text-center center pt-30 pb-10">
                                <el-button plain size="small" @click="koduTekrarGonder"
                                    class="login-btn pulse animated themed animated fadeInRight">
                                    Send Code Again
                                </el-button>
                            </div>
                        </form>
                    </div>
                </div>
            </el-col>
            <el-col :lg="16" :md="16" :sm="24" :xs="24" style="height: 100%" class="right-box">
                <div id="loginImg" class="box grow scrollable only-y align-vertical side-box box-left loginImg"
                    style="height: 100%;">
                    <div class="align-vertical-middle wrapper text-center">
                        <a id="bg-logo-button" :href="musteriLink" :title="musteriAd" target="_blank">
                            <img src="../../../assets/images/1-BG-GRUP.png" alt="" width="400">
                        </a>
                    </div>


                    <!-- FOOTER BAŞLANGIC -->
                    <div class="footer flex align-center justify-space-between above">
                        <div class="footer-ust-img">
                            <div class="hakanbt-logo">
                                <div class="hakanbt">
                                    <a id="hakanbiltek" href="https://www.hakanbt.com.tr" title="Hakan Bilgi Teknolojileri"
                                        target="_blank">
                                        <img src="@/assets/images/hakanbt.png" alt="Hakan Bilgi Teknolojileri">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div style="padding-bottom: 10px">
                            <span class="hbtIsim" style="color: #000">
                                Bu Proje <b style="color: #1d99d6">Hakan Bilgi Teknolojileri</b> Tarafından Üretilmiştir.
                                Telif Hakkı Fikir Ve Sanat Eserleri Kanunu Kapsamında Korunmaktadır.
                            </span>
                        </div>
                    </div>
                    <!-- FOOTER BİTİŞ -->

                </div>
            </el-col>
        </el-row>
    </div>
</template>


<script>
import yonetimService from '../../../WSProvider/YoneticiService'
import notification from '../../../notification'
import JQuery from 'jquery';
import jwt_decode from "jwt-decode"
import md5 from 'md5';


let $ = JQuery;

let base64 = require('base64_js');
const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 30;
const ALERT_THRESHOLD = 15;

const COLOR_CODES = {
    info: {
        color: "green"
    },
    warning: {
        color: "orange",
        threshold: WARNING_THRESHOLD
    },
    alert: {
        color: "red",
        threshold: ALERT_THRESHOLD
    }
};
const TIME_LIMIT = 60;


export default {
    name: 'Login',
    data() {
        return {
            loading: false,

            sifreUnuttum: false,
            username: '',
            password: '',
            tcNo: '',
            visPinCode: true,
            secure: false,
            secureCount: 1,
            userData: [],
            timePassed: 0,
            timerInterval: null,
            musteriLink: "https://www.bggrupinsaat.com.tr/",
            musteriAd: "BG GRUP"
        }
    },
    computed: {
        circleDasharray() {
            return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
        },
        formattedTimeLeft() {
            let seconds = this.timeLeft;
            if (seconds < 10) {
                seconds = `0${seconds}`;
            }
            return `${seconds}`;
        },

        timeLeft() {
            return TIME_LIMIT - this.timePassed;
        },
        timeFraction() {
            const rawTimeFraction = this.timeLeft / TIME_LIMIT;
            return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
        },

        remainingPathColor() {
            const { alert, warning, info } = COLOR_CODES;

            if (this.timeLeft <= alert.threshold) {
                return alert.color;
            } else if (this.timeLeft <= warning.threshold) {
                return warning.color;
            } else {
                return info.color;
            }
        }
    },
    watch: {
        timeLeft(newValue) {
            if (newValue === 0) {
                this.onTimesUp();
            }
        }
    },
    methods: {
        onTimesUp() {
            this.visPinCode = false;
            clearInterval(this.timerInterval);
        },

        startTimer() {
            this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
        },

        login() {
            this.loading = true;
            //const sifre = md5(this.password)

            yonetimService.girisYap(this.username, this.password).then((response) => {
                if (response.status === 200) {
                    var token = jwt_decode(response.userToken)
                    //console.log("userToken")
                    //console.log(token)
                    this.$store.commit("setYetkiTuru", token.yetkiTuru)
                    this.$store.commit("setSozlesmeKategoriID", token.sozlesmeKategoriID)
                    this.$store.commit("setUserName", token.yoneticiAdi)
                    this.$store.commit("setYetkiListesi", token.yetkiListesi)
                    this.$store.commit("setYoneticiID", token.yoneticiID)
                    this.$store.commit("setAdSoyad", token.yoneticiAdSoyad)

                    localStorage.setItem("userDataBGSurec", response.userToken)
                    this.$store.commit("setLogin")
                    this.$store.commit('changeNavVisible', true);
                    if (token.yetkiTuru == '3') {
                        this.$router.push({ path: "/gorevlerim" })
                    }/*
                        else if (token.yetkiTuru == '2'){
                            this.$router.push({path: "/admin"})
                        }*/
                    else
                        this.$router.push({ path: "/" });
                }
                this.loading = false;
            }).catch(err => {
                if (err.responseJSON) {
                    let error = err.responseJSON
                    if (error.status == 401) {
                        notification.Status(150, this);
                    }
                    else notification.Status("errorMsg", this, error.errMsg)
                } else {
                    //notification.Status(503, this)
                }
                this.loading = false;
            })
        },

        sifremiUnuttum() {
            yonetimService.sifremiUnuttum(this.username, this.tcNo).then((response) => {
                if (response.status === 200) {
                    notification.Status('success', this, 'Your new password has been sent to your e-mail address.');
                } else {
                    notification.Status(response.status, this)
                }
            });
        },
        koduTekrarGonder() {
            if (this.$store.getters.getSecure(this.userData.yonetimId) < 3) {
                this.$store.commit('secure', this.userData.yonetimId);
                this.dogrulama();
            } else if (new Date().getTime() - this.$store.getters.getSecureSonGiris(this.userData.yonetimId) >= 3600000) {
                this.dogrulama();
            } else {
                notification.Status('warning', this, 'Your account has been temporarily blocked.');
            }


        },
        dogrulama() {
            yonetimService.dogrulamaTekrar(this.username, this.password).then((response) => {
                if (response.status === 200) {
                    this.visPinCode = true;
                    clearInterval(this.timerInterval);
                    this.timePassed = 0;
                    this.timerInterval = null;
                    this.startTimer();
                } else notification.Status(response.status, this)
            })
        },
        handleOnComplete(value) {
            let self = this;
            yonetimService.dogrulamaKontrol(this.username, this.password, value).then((response) => {
                if (response.status === 200) {
                    var userDataBGSurec = base64.encode(encodeURI(JSON.stringify(response.data)));
                    var userName = base64.encode(encodeURI(JSON.stringify(response.data)));
                    localStorage.setItem("userDataBGSurec", userDataBGSurec);
                    localStorage.setItem("userName", userName);
                    self.$store.commit('setLogin');
                    self.$router.push('/');
                    this.$store.commit('changeNavVisible', true);
                    this.$store.commit('resSecure', response.data.yonetimId);
                } else notification.Status(response.status, this)
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';
@import '../../../assets/scss/_mixins';


.footer {
    width: 100%;
    height: 70px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #e3eaf0;
    margin-top: 2px;
    font-size: 14px;
    border-top-left-radius: 20px;
    display: flex;
    flex-direction: column;

    &.above {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0;
    }

    a {
        font-weight: bold;
        text-decoration: none;
        color: $text-color-accent;
    }


    .footer-ust-img {
        position: relative;
        height: 90px;
        top: -34px;
    }

    .hakanbt-logo:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: -40px;
        left: 37px;
        border-style: solid;
        border-width: 0 30px 35px 30px;
        border-color: transparent transparent #e3eaf0 transparent;
    }

    .hakanbt-logo:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: -30px;
        left: 50px;
        border-style: solid;
        border-width: 0px 17px 21px 17px;
        border-color: transparent transparent #0577bf transparent;
    }

    .hakanbt-logo {
        position: absolute;
        right: 40px;
        bottom: -15px;
        height: 35%;
        width: 100%;
    }
}
</style>

<style lang="scss">
@import '../../../assets/scss/_variables';

#login-btn {
    .el-loading-spinner {
        svg {
            width: 25px;
            height: 25px;
            position: relative;
            top: 18px !important
        }
    }
}

.layout-container .container .main {
    background: #fff;
}

.right-box {
    display: block;
}


.login-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 !important;

    .side-box {
        .wrapper {
            width: 100%;
            box-sizing: border-box;
            padding: 20px;
        }
    }

    .box-left {
        background-image: url('../../../assets/images/hbt-bg.jpg');
        background-size: cover;
        background-position: 50% 50%;

        .wrapper {
            .image-logo {
                /*width: 90%;*/
                /*max-width: 100px;*/
                margin-top: 20px;
            }

        }

        #bg-logo-button {
            display: flex;
            justify-self: center !important;
            align-items: center !important;
            position: relative;
            justify-content: center;
            object-fit: cover;

            img {
                // Image genişliğini standart 500px yüksekliği 500e orantılı şekilde alıyoruz.
                position: relative;
                bottom: 50px;
                cursor: pointer;
            }
        }
    }



    .box-right {
        background: white;
        color: var(--background-color);
    }

    .form-box {
        width: 100%;
        max-width: 340px;
        padding: 30px;
        box-sizing: border-box;
        margin: 30px 150px;
        //background: transparent;

        a {
            font-size: 14px;
            color: var(--color-accent);
            text-decoration: none;
            font-weight: 500;
        }

        .image-logo {
            width: 80px;
            margin: 0px auto;
            margin-bottom: 70px;
            display: block;
        }

        .login-btn {
            background: #ffffff;
            color: #4a596a;
            border-color: #4a596a;
            border-width: 2px;
            font-weight: bold;
            border-radius: 0;

            &:hover {
                background: #ffffff;
                border-color: #4a596a;
                color: #4a596a;
            }
        }
    }
}

@media (max-width: 1200px) {
    .login-page {
        .box-left {
            .wrapper {
                .h-big {
                    font-size: 50px;
                }
            }
        }
    }

    .right-box {
        display: none;
    }
}

@media (max-width: 900px) {
    .login-page {
        .box-left {
            .wrapper {
                .h-big {
                    font-size: 30px;
                }
            }
        }
    }

    .right-box {
        display: none;
    }
}

@media (max-width: 768px) {
    .login-page {
        display: block;
        overflow: auto;

        .side-box {
            display: block;
        }
    }

    .loginImg {
        position: absolute;
        z-index: 100;
        height: 65%;
        transition: all 0.8s ease;
    }

    .login1 {
        transition: 1s;
        z-index: 1;
        display: block !important;
        position: relative;
        transform: translateY(0);
    }

    .login2 {
        position: absolute;
        width: 100%;
        top: 0;
        height: 500px;
    }

    .yukariCek {
        transform: translateY(-100%);
    }

    .right-box {
        display: none;
    }

}

@media (min-width: 768px) {
    .login1 {
        position: relative;
        z-index: 90;
        right: 0;
    }

    .login2 {
        position: relative;
        z-index: 90;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 30%;
    }

    .loginImg {
        position: absolute;
        left: 33%;
        height: 100%;
        width: 67%;
        z-index: 100;
        transition: all 0.8s ease;
    }


}

@media (max-width: 1024px) {
    .right-box {
        display: none;
    }
}

.el-progress-circle {
    width: 30px !important;
    height: 30px !important;
}
</style>

<style lang="scss">
.otp-input {
    width: 25px;
    height: 25px;
    padding: 3px;
    margin: 0 5px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;

    &.error {
        border: 1px solid red !important;
    }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>

<style scoped lang="scss">
.base-timer {
    position: relative;
    width: 100%;
    height: 60px;
    margin-top: 20px;

    &__svg {
        transform: scaleX(-1);
    }

    &__circle {
        fill: none;
        stroke: none;
    }

    &__path-elapsed {
        stroke-width: 7px;
        stroke: grey;
    }

    &__path-remaining {
        stroke-width: 7px;
        stroke-linecap: round;
        transform: rotate(90deg);
        transform-origin: center;
        transition: 1s linear all;
        fill-rule: nonzero;
        stroke: currentColor;

        &.green {
            color: rgb(65, 184, 131);
        }

        &.orange {
            color: orange;
        }

        &.red {
            color: red;
        }
    }

    &__label {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
    }
}
</style>




