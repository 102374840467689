const celik = resolve => {
    require.ensure(['../views/apps/celik/Celik'], () => {
        resolve(require('../views/apps/celik/Celik'))
    })
};

const BGCelikMusteriTanimla = resolve => {
    require.ensure(['../views/apps/celik/BGCelikMusteriTanimla'], () => {
        resolve(require('../views/apps/celik/BGCelikMusteriTanimla'))
    })
};

const BGCelikMusteriGuncelle = resolve => {
    require.ensure(['../views/apps/celik/BGCelikMusteriGuncelle'], () => {
        resolve(require('../views/apps/celik/BGCelikMusteriGuncelle'))
    })
};

const BGCelikMusteriListesi = resolve => {
    require.ensure(['../views/apps/celik/BGCelikMusteriListesi'], () => {
        resolve(require('../views/apps/celik/BGCelikMusteriListesi'))
    })
};
const SiparisTanimla = resolve => {
    require.ensure(['../views/apps/celik/SiparisTanimla'], () => {
        resolve(require('../views/apps/celik/SiparisTanimla.vue'))
    })
};
const SiparisListesi = resolve => {
    require.ensure(['../views/apps/celik/SiparisListesi'], () => {
        resolve(require('../views/apps/celik/SiparisListesi.vue'))
    })
};
const SiparisGuncelle = resolve => {
    require.ensure(['../views/apps/celik/SiparisGuncelle'], () => {
        resolve(require('../views/apps/celik/SiparisGuncelle.vue'))
    })
};


import layouts from '../layout'
import Vue from 'vue'

Vue.component('bgcelikmusteri-tanimla', BGCelikMusteriTanimla);
Vue.component('bgcelikmusteri-guncelle', BGCelikMusteriGuncelle);
Vue.component('bgcelikmusteri-listesi', BGCelikMusteriListesi);
Vue.component('siparistanimla', SiparisTanimla);
Vue.component('siparislistesi', SiparisListesi);
Vue.component('siparisguncelle', SiparisGuncelle);


export default {
    path: '/celik',
    name: 'celik',
    component: celik,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [
        {
            path: 'liste',
            name: 'BGCelikMusteriListesi',
            component: BGCelikMusteriListesi,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                searchable: true,
                tags: ['app']
            }
        },
    ],

}