const Firma = resolve => {
    require.ensure(['../views/apps/firma/Firma'], () => {
        resolve(require('../views/apps/firma/Firma'))
    })
};
const FirmaTanimla = resolve => {
    require.ensure(['../views/apps/firma/FirmaTanimla'], () => {
        resolve(require('../views/apps/firma/FirmaTanimla'))
    })
};
const FirmaDetay = resolve => {
    require.ensure(['../views/apps/firma/FirmaDetay'], () => {
        resolve(require('../views/apps/firma/FirmaDetay'))
    })
};
const PersonelTanimla = resolve => {
    require.ensure(['../views/apps/firma/PersonelTanimla'], () => {
        resolve(require('../views/apps/firma/PersonelTanimla'))
    })
};
const PersonelListesi = resolve => {
    require.ensure(['../views/apps/firma/PersonelListesi'], () => {
        resolve(require('../views/apps/firma/PersonelListesi'))
    })
};
const PersonelDuzenle = resolve => {
    require.ensure(['../views/apps/firma/PersonelDuzenle'], () => {
        resolve(require('../views/apps/firma/PersonelDuzenle'))
    })
};



import Vue from 'vue'
import layouts from '../layout'
Vue.component('firmakayit', FirmaTanimla);
Vue.component('firma', FirmaDetay);
Vue.component('personeltanimla', PersonelTanimla);
Vue.component('personelguncelle', PersonelDuzenle);
Vue.component('personellistesi', PersonelListesi);


export default {
    path: '/firma',
    name: 'firma',
    component: Firma,
    meta: {
        layout: layouts.navLeft
    }
}
