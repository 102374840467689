<template>
  <div class="musteri-rapor-page page-table only-y mainDiv mb-30">
    <div class="card-base baslikBg animated fadeInUp">Müşteri Rapor</div>
    <div
      class="page-header card-base card-shadow--medium animated fadeInUp"
      style="min-height: 200px"
      v-loading="loading"
      :element-loading-text="
        loading ? $t('src.views.apps.rapor.musteriRaporLoading') : ''
      "
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-row style="display: flex" v-if="isDesktop">
        <el-col
          :lg="7"
          :md="7"
          :sm="7"
          :xs="7"
          style="justify-content: flex-start"
        >
          <el-select
            v-model="secilenMusteri"
            style="width: 100%"
            id="musteriSelect"
            clearable
            @change="musteriSecimi"
            placeholder="Müşteri Seçiniz"
            filterable
            :filter-methos="filterMethod"
          >
            <el-option
              v-for="(item, index) in musterilerList"
              :key="index"
              :value="item.musteriID"
              :label="item.musteriAdi"
            ></el-option>
          </el-select>
        </el-col>
        <el-col
          :lg="10"
          :md="10"
          :sm="10"
          :xs="10"
          style="justify-content: flex-start"
        >
        </el-col>
        <el-col
          :lg="7"
          :md="7"
          :sm="7"
          :xs="7"
          style="
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
          "
        >
          <el-select
            v-model="secilenProje"
            style="width: 100%"
            id="projeSelect"
            clearable
            @change="projeSecimi"
            placeholder="Proje Seçiniz"
            filterable
            :filter-methos="filterMethod"
          >
            <el-option
              v-for="(item, index) in projelerList"
              :key="index"
              :value="item.projeID"
              :label="item.projeBaslik"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
        v-if="!isDesktop"
      >
        <el-col :lg="13" :md="13" :sm="24" :xs="24">
          <el-select
            v-model="secilenMusteri"
            style="width: 100%"
            id="musteriSelect"
            clearable
            @change="musteriSecimi"
            placeholder="Müşteri Seçiniz"
          >
            <el-option
              v-for="(item, index) in musterilerList"
              :key="index"
              :value="item.musteriID"
              :label="item.musteriAdi"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <br v-if="!isDesktop" />
      <el-row
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
        v-if="!isDesktop"
      >
        <el-col :lg="13" :md="13" :sm="24" :xs="24">
          <el-select
            v-model="secilenProje"
            style="width: 100%"
            id="projeSelect"
            clearable
            @change="projeSecimi"
            placeholder="Proje Seçiniz"
          >
            <el-option
              v-for="(item, index) in projelerList"
              :key="index"
              :value="item.projeID"
              :label="item.projeBaslik"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row
        v-if="
          secilenMusteri != '' && this.projeList != '' && secilenProje != ''
        "
      >
        <!--
        <br>
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
        <ul class="islemler2">
            <li class="yesil">
                <i class="mdi mdi-circle-slice-8"></i> % 100
            </li>
            <li class="kahverengi">
                <i class="mdi mdi-circle-slice-8"></i> % 99 - 75
            </li>
            <li class="sari">
                <i class="mdi mdi-circle-slice-8"></i> % 74 - 50
            </li>
            <li class="turuncu">
                <i class="mdi mdi-circle-slice-8"></i> % 49 - 24
            </li>
            <li class="pembe">
                <i class="mdi mdi-circle-slice-8"></i> % 24 - 0
            </li>
        </ul>
        </el-col>
        <br v-if="!isDesktop">
        <br v-if="!isDesktop">
        <br v-if="!isDesktop">
        -->
        <div style="display: flex; align-items: center" v-if="isDesktop">
          <el-col
            :lg="16"
            :md="16"
            :sm="8"
            :xs="8"
            style="display: flex; justify-content: flex-start"
            v-if="secilenMusteri != '' && this.projeList != ''"
          >
            <div class="display-end">
              <ul class="islemler">
                <li>
                  <i
                    class="mdi mdi-circle"
                    style="color: rgba(98, 207, 35, 0.68)"
                  ></i>
                  <span>Biten sözleşmeler</span>
                </li>
                <li>
                  <i
                    class="mdi mdi-circle"
                    style="color: rgba(37, 139, 201, 0.71)"
                  ></i
                  ><span>Devam eden sözleşmeler</span>
                </li>
                <li>
                  <i
                    class="mdi mdi-circle"
                    style="color: rgb(237, 234, 155)"
                  ></i>
                  <span>Başlamamış olan sözleşmeler </span>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col
            :lg="8"
            :md="8"
            :sm="8"
            :xs="8"
            style="display: flex; justify-content: flex-end"
            v-if="secilenMusteri != '' && this.projeList != ''"
          >
            <div class="display-end">
              <ul class="islemler">
                <el-button
                  type="text"
                  v-on:click="getMusteriRapor(secilenMusteri)"
                  style="font-family: 'Poppins', sans-serif; font-size: 14px"
                >
                  <span style="color: #4a596a">
                    Raporu indirmek için tıklayınız.
                  </span>
                </el-button>
              </ul>
              &nbsp;
              <el-tooltip :content="'Müşteri Rapor İndir'" :open-delay="500">
                <el-button
                  type="text"
                  style="color: #ee1010"
                  v-on:click="getMusteriRapor(secilenMusteri)"
                  class="pdf-button"
                >
                  <i class="mdi mdi-download mdi-24px"></i>
                </el-button>
              </el-tooltip>
            </div>
          </el-col>
        </div>
        <div v-if="!isDesktop">
          <br />
          <el-col
            :lg="16"
            :md="16"
            :sm="24"
            :xs="24"
            style="display: flex; justify-content: flex-start"
            v-if="secilenMusteri != '' && this.projeList != ''"
          >
            <div class="display-end" style="display: flex; align-items: center">
              <ul class="islemler2">
                <li>
                  <i class="mdi mdi-circle" style="color: #cde990"></i>
                  <span>Biten sözleşmeler</span>
                </li>
                <li>
                  <i class="mdi mdi-circle" style="color: #79b8d180"></i>
                  <span>Devam eden sözleşmeler</span>
                </li>
                <li>
                  <i class="mdi mdi-circle" style="color: #ffe7a0e6"></i>
                  <span>Başlamamış olan sözleşmeler </span>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col
            :lg="8"
            :md="8"
            :sm="24"
            :xs="24"
            style="display: flex; justify-content: flex-start"
            v-if="secilenMusteri != '' && this.projeList != ''"
          >
            <div class="display-end-sol">
              <el-button
                type="text"
                style="color: #ee1010"
                v-on:click="getMusteriRapor(secilenMusteri)"
                class="pdf-button"
              >
                <i class="mdi mdi-download mdi-24px"></i>
              </el-button>
              <ul class="islemler">
                <el-button
                  type="text"
                  v-on:click="getMusteriRapor(secilenMusteri)"
                  style="font-family: 'Poppins', sans-serif; font-size: 14px"
                >
                  <span style="color: #4a596a"
                    >Raporu indirmek için tıklayınız.</span
                  >
                </el-button>
              </ul>
            </div>
          </el-col>
        </div>
        <br v-if="!isDesktop" />
      </el-row>
      <!-- Projelere ait liste. Eğer bir liste var ise bu tablo görüntülenecek -->
      <!-- Her proje için ayrı tablo yapılacak. Her proje tablosunda o projeye ait sözleşmeler ve sözleşmelerin hangi ayda ne kadar işi oldugunu gosteren tablolar olacak -->
      <el-row
        v-if="
          secilenMusteri != '' && secilenProje != '' && this.projeList != ''
        "
        id="musterirapor"
      >
        <el-row style="padding: 0 7px 0 7px">
          <!-- #F93862 -->
          <el-row
            :gutter="15"
            style="
              border: 1px solid #c7e3f6;
              padding: 15px 0 10px 0;
              background: #7ba7c1 !important;
              border-top-right-radius: 10px;
              border-top-left-radius: 10px;
            "
          >
            <!-- Projenin yuzde kaçı bitti -->
            <!--
                <el-col :lg="1" :md="1" :sm="1" :xs="1" class="text-center">
                    <br v-if="isDesktop">
                    <el-checkbox v-model="projeList.selected" @change="toggleProjeSecimi"></el-checkbox>
                </el-col> -->
            <el-col :lg="10" :md="10" :sm="22" :xs="22">
              <p class="m-0 ml-5">Proje Süreç İlerleme Durumu :</p>
              <template
                v-if="
                  parseFloat(projeList.baslamaSureYuzde) -
                    parseFloat(projeList.bitmeYuzdesi) >
                  5
                "
              >
                <br v-if="!isDesktop" />
              </template>
              <div v-if="sozlesmeler && sozlesmeler.length > 0">
                <el-progress
                  :stroke-width="isDesktop ? 20 : 15"
                  :status="
                    projeForm.sozlesmeTamamlanmaYuzde == '100'
                      ? 'success'
                      : projeForm.sozlesmeTamamlanmaYuzde > 100
                      ? 'exception'
                      : 'exception'
                  "
                  :color="
                    projeForm.sozlesmeTamamlanmaYuzde == '100'
                      ? 'rgba(33, 219, 62, 0.75)'
                      : projeForm.sozlesmeTamamlanmaYuzde > 100
                      ? 'rgb(255 0 0)'
                      : 'rgba(33, 219, 62, 0.75)'
                  "
                  :percentage="parseInt(projeForm.sozlesmeTamamlanmaYuzde)"
                ></el-progress>
              </div>
              <div v-else>
                &emsp;
                <label style="color: #e67e22"
                  >Projenin Sözleşmeleri Eklenmemiş.</label
                >
              </div>
            </el-col>
            <el-col
              :lg="1"
              :md="1"
              :sm="1"
              :xs="1"
              class="text-center"
              v-if="!isDesktop"
            >
              &nbsp;
            </el-col>
            <el-col :lg="10" :md="10" :sm="22" :xs="22" class="mt-10">
              <div class="row" v-if="isDesktop">
                <div class="col-6" style="text-align: center">
                  Başlama Tarihi:
                  <b
                    ><span>{{
                      new Date(projeList.sozlesmeBaslama).toLocaleDateString()
                    }}</span></b
                  >
                </div>
                <div class="col-6" style="text-align: center">
                  Bitiş Tarihi:
                  <b
                    ><span>{{
                      new Date(projeList.sozlesmeBitis).toLocaleDateString()
                    }}</span></b
                  >
                </div>
              </div>
              <div class="row" v-else style="justify-content: start">
                <div class="ml-5">
                  Başlama:
                  {{ new Date(projeList.sozlesmeBaslama).toLocaleDateString() }}
                </div>
                <div class="ml-10">
                  Bitiş:
                  {{ new Date(projeList.sozlesmeBitis).toLocaleDateString() }}
                </div>
              </div>
              <el-progress
                :stroke-width="isDesktop ? 20 : 15"
                :status="
                  projeForm.yuzdeDeger > '100'
                    ? 'success'
                    : projeForm.yuzdeDeger > 100
                    ? 'exception'
                    : 'exception'
                "
                :color="
                  projeForm.yuzdeDeger == '100'
                    ? 'rgba(33, 219, 62, 0.75)'
                    : projeForm.yuzdeDeger > 100
                    ? 'rgb(255 0 0)'
                    : 'rgba(33, 219, 62, 0.75)'
                "
                :percentage="
                  projeForm.yuzdeDeger < 0
                    ? 0
                    : projeForm.yuzdeDeger > 100
                    ? 100
                    : parseInt(projeForm.yuzdeDeger) || 0
                "
              ></el-progress>
            </el-col>
            <el-col :lg="1" v-if="isDesktop">
              <div class="cizgi"></div>
            </el-col>
            <el-col :lg="3" style="padding-top: 3px">
              <el-row v-if="isDesktop">
                <el-col :lg="12">Toplam Gün:</el-col>
                <el-col :lg="12"
                  ><b>{{ projeForm.toplamGun }}</b></el-col
                >
                <el-col :lg="12">Kalan Gün:</el-col>
                <el-col :lg="12"
                  ><b>{{ projeForm.kalanGun }}</b></el-col
                >
              </el-row>
              <el-row v-if="!isDesktop" class="mobile-proje-area">
                <el-divider />
                <el-col :lg="24">
                  <p class="m-0">Toplam Gün: {{ projeForm.toplamGun }}</p>
                  <p class="m-0">Kalan Gün: {{ projeForm.kalanGun }}</p>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-row>
        <div>
          <el-table
            :data="sortedSozlesmeler"
            border
            :summary-method="calculateColumnTotal"
            show-summary
            v-if="sozlesmeler && sozlesmeler.length > 0"
          >
            <el-table-column
              prop="sozlesmeAdi"
              width="250"
              :fixed="isDesktop ? 'left' : null"
            >
              <template slot="header">
                <div class="pl-5">Sözleşmeler</div>
              </template>
              <template slot-scope="prop">
                <div class="pl-5" style="line-height: 25px">
                  <span
                    v-if="prop.row.sozlesmedegisti == true"
                    class="mdi mdi-city"
                    style="color: #006cae"
                  ></span>
                  {{ prop.row.sozlesmeBaslik }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Pursantaj Oranları"
              width="160"
              prop="yuzdeDeger"
              align="center"
            >
              <template slot-scope="prop">
                <div>% {{ prop.row.yuzdeDeger }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="isSuresi"
              width="100"
              :fixed="isDesktop ? 'left' : null"
              align="center"
            >
              <template slot="header">
                <div class="pl-5">Toplam Gün</div>
              </template>
              <template slot-scope="prop">
                <div class="pl-5">
                  {{ prop.row.isSuresi }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="gecikenGun"
              width="110"
              :fixed="isDesktop ? 'left' : null"
              align="center"
            >
              <template slot="header">
                <div class="pl-5">Geciken Gün</div>
              </template>
              <template slot-scope="prop">
                <el-tooltip
                  v-if="
                    prop.row.gecikenGun !== null && prop.row.gecikenGun != 0
                  "
                  :open-delay="400"
                  class="item"
                  effect="dark"
                  :content="getTrimmedText(prop.row.aciklama)"
                  placement="right"
                >
                  <el-button
                    size="small"
                    @click="open(prop.row)"
                    style="
                      background-color: red;
                      color: white;
                      width: 100%;
                      display: block;
                    "
                    >{{ prop.row.gecikenGun }}
                  </el-button>
                </el-tooltip>

                <!--
                <el-tooltip :open-delay="300" class="item" effect="red" content="gecikme sebebi" placement="top">
                    <div  class="pl-5" v-if="prop.row.gecikenGun !== null" style="background-color: red; color:white"></div>
                </el-tooltip>


            <div class="pl-5" v-if="prop.row.gecikenGun !== null" style="background-color: red; color:white">
                <span>{{ prop.row.gecikenGun }}</span>
                <span></span>
                </div>-->
              </template>
            </el-table-column>
            <el-table-column
              v-for="(item, index) in tarihler2"
              :key="index"
              :prop="item.prop"
              align="center"
              width="auto"
              style="font-size: 12px !important"
            >
              <template slot="header">
                <div class="new-line-text">
                  <!--                  v-if="shouldDisplayYear(index)"-->
                  <div
                    class="musteri-rapor-table-year"
                    :style="{
                      backgroundColor:
                        item.year == 2023
                          ? '#FFB996'
                          : item.year == 2024
                          ? '#6DA4AA'
                          : item.year == 2025
                          ? '#7FC7D9'
                          : '#EEF5FF',
                    }"
                  >
                    {{ item.year }}
                  </div>
                  <div class="musteri-rapor-table-month">
                    {{ item.month }}
                  </div>
                </div>
              </template>
              <template slot-scope="prop">
                <el-tooltip
                  :content="
                    String(
                      prop.row.sozlesmeAylari.find(
                        (e) =>
                          e.monthIndex == item.monthIndex && e.year == item.year
                      )
                        ? prop.row.sozlesmeAylari.find(
                            (e) => e.monthIndex == item.monthIndex
                          ).days
                        : ''
                    )
                  "
                  :open-delay="300"
                  placement="left"
                >
                  <div
                    @click="sozlesmeDetay(prop.row)"
                    style="width: 100%; height: 100%; cursor: pointer"
                  >
                    <div :style="dateStyle(prop.row, item)"></div>
                  </div>
                </el-tooltip>
                <!-- <div class="gunSayisi" style="position: relative; z-index: 2;">
                    {{ prop.row.sozlesmeAylari.find(e => e.monthIndex == item.monthIndex && e.year ==
                        item.year) ? prop.row.sozlesmeAylari.find(e => e.monthIndex == item.monthIndex).days :
                        '' }}
                </div> -->
              </template>
            </el-table-column>
          </el-table>
          <!--
          <el-dialog
              title="Warning"
              :visible="selectedRow !== null"
              width="30%"
              center
              @close="closeCenterDialog"
              :modal-append-to-body="false">
              <span>It should be noted that the content will not be aligned in center by default</span>
              <span slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="closeCenterDialog">Tamam</el-button>
              </span>
          </el-dialog>-->
        </div>
      </el-row>
      <!-- Halihazırda bir proje yok ise ya da bir müşteri seçimi yapılmamış ise burası görüntülenecek -->
      <div v-if="secilenMusteri == '' && projeList.length == 0" class="noItem">
        Proje listesi görüntülemek için henüz bir müşteri seçimi yapılmamış!
      </div>
      <div v-if="secilenMusteri != '' && projeList.length == 0" class="noItem">
        Müşteriye ait bir proje bulunmamaktadır.
      </div>
      <div
        v-if="
          secilenMusteri != '' && projeList.length != 0 && secilenProje == ''
        "
        class="noItem"
      >
        Proje görüntülemek için henüz bir proje seçimi yapılmamış!
      </div>
    </div>
    <el-dialog
      class="szModal"
      title="Sözleşme Detay"
      :visible.sync="dialogSozlesme"
      width="50%"
      top="17%"
    >
      <el-table :data="[sozlesmeDetayModal]" style="width: 100%" border stripe>
        <el-table-column label="Personel" prop="adSoyad"></el-table-column>
        <el-table-column
          label="Başlama Süresi"
          prop="baslamaSuresi"
        ></el-table-column>
        <el-table-column label="İş Süresi" prop="isSuresi"></el-table-column>
        <el-table-column
          label="Geciken Gün"
          prop="gecikenGun"
        ></el-table-column>
        <el-table-column
          label="Başlama Tarihi"
          prop="baslamaTarihi"
        ></el-table-column>
        <el-table-column
          label="Bitiş Tarihi"
          prop="tamamlanmaTarihi"
        ></el-table-column>
      </el-table>
    </el-dialog>
    <!-- <el-dialog :custom-class="'user-dialog'" :visible="dialogSozlesme" width="60%"
        @close="$emit('update:dialogvisible', false); visiblePassword = false">
        <div class="avatar-box">
            <br><br>
            <img :src="userdata.resim ? this.imageUrl + userdata.resim + '' : imagePlaceholder" alt="user avatar">
        </div>
        <el-tabs v-model="activeName" class="yoneticiDetay">
            <el-tab-pane :label='$t("src.views.apps.yonetici.gecikmeDialog.title")' name="first">
                <div class="flex center demo-box">
                    <div class="right-box box grow">
                        <dl class="horizontal">
                            <dt><i class="mdi mdi-account"></i>{{ $t("src.views.apps.yonetici.gecikmeDialog.personel")
                            }}
                            </dt>
                            <dd> : {{ userdata.sozlesme.adSoyad }}</dd>
                            <el-divider></el-divider>
                            <dt><i class="mdi mdi-account"></i>{{ $t("src.views.apps.yonetici.gecikmeDialog.musteri") }}
                            </dt>
                            <dd> : {{ userdata.musteriAdi }}</dd>
                            <el-divider></el-divider>
                            <dt><i class="mdi mdi-domain"></i>{{ $t("src.views.apps.yonetici.gecikmeDialog.proje") }}
                            </dt>
                            <dd> : {{ userdata.projeAdi }}</dd>
                            <el-divider></el-divider>
                            <dt><i class="mdi mdi-calendar"></i>{{ $t("src.views.apps.yonetici.gecikmeDialog.baslama")
                            }}
                            </dt>
                            <dd> : {{ formatDate(userdata.sozlesme.baslamaTarihi) }}</dd>
                            <el-divider></el-divider>
                            <dt><i class="mdi mdi-calendar"></i>{{ $t("src.views.apps.yonetici.gecikmeDialog.bitis") }}
                            </dt>
                            <dd> : {{ formatDate(userdata.sozlesme.tamamlanmaTarihi) }}</dd>
                            <el-divider></el-divider>
                            <dt><i class="mdi mdi-calendar"></i>{{
                                $t("src.views.apps.yonetici.gecikmeDialog.tamamlanma") }}
                            </dt>
                            <dd> : {{ formatDate(userdata.sozlesme.isTamamlanmaTarihi) }}</dd>
                            <el-divider></el-divider>
                            <dt><i class="mdi mdi-clock"></i>{{ $t("src.views.apps.yonetici.gecikmeDialog.gecikme") }}
                            </dt>
                            <dd> : {{ userdata.sozlesme.gecikenGun }}</dd>
                            <el-divider></el-divider>
                            <dt><i class="mdi mdi-comment"></i>{{ $t("src.views.apps.yonetici.gecikmeDialog.aciklama")
                            }}
                            </dt>
                            <dd> : {{ userdata.sozlesme.aciklama }}</dd>
                        </dl>
                    </div>
                </div>

            </el-tab-pane>

        </el-tabs>
        <el-dialog :title='$t("src.views.apps.sayfa.liste.uyari")' :visible.sync="yetkiDialogVisible" append-to-body
            width="30%" center>
            <span>'{{ selection.aciklama }}' {{ $t("src.views.apps.genel.kaydetChanges") }}</span>
            <span slot="footer" class="dialog-footer">
                <el-button icon="el-icon-check" type="success" size="mini"
                    @click="changeStatus(selection), yetkiDialogVisible = false"></el-button>
                <el-button type="danger" size="mini" icon="el-icon-close"
                    @click="yetkiDialogVisible = false"></el-button>
            </span>
        </el-dialog>
    </el-dialog> -->
    <gecikme-dialog
      v-if="dialogVisibleYonetici"
      :dialogvisible.sync="dialogVisibleYonetici"
      :userdata="userDataBGSurec"
    ></gecikme-dialog>
  </div>
</template>

<script>
import notification from "../../../notification";
import functions from "../../../functions";
import wsProvider from "../../../WSProvider/provider/WSProvider";
import musteriService from "../../../WSProvider/MusteriService";
import projeService from "../../../WSProvider/ProjeService";
import GecikmeDialog from "@/components/GecikmeDialog";

var moment = require("moment");

export default {
  name: "MusteriRapor",
  components: {
    GecikmeDialog,
  },
  data() {
    return {
      sozlesmeDetayModal: [],
      dialogSozlesme: false,
      displayedYears: new Set(),
      secim: false,
      loading: false,
      isDesktop: false,
      centerDialogVisible: false,
      dialogVisibleYonetici: false,
      selectedRow: null,
      tarihlerSon: [],
      secilenMusteri: "",
      secilenProje: "",
      musterilerList: [],
      musteriProjeleriList: [],
      projelerList: [],
      sozlesmeler: [],
      tarihler2: [],
      isFirstColumn: true,
      selectedProjects: [],
      sizes: 10,
      selectIcon: "1",
      page: 0,
      postPage: 0,
      total: 4,
      yoneticiID: "",
      yetkiTuru: "",
      projeler: [],
      projeList: [],
      tarihler: [],
      musteriForm: {
        musteriID: "",
        projeID: "",
        projeBaslik: "",
      },
      projeForm: {
        kategori: "",
        musteriAdi: "",
        projeBaslik: "",
        musteriID: "",
        baslamaTarihi: "",
        bitisTarihi: "",
        sozlesmeBaslamaTarihi: "",
        sozlesmeBitisTarihi: "",
        toplamGun: "",
        kalanGun: "",
        sozlesmeYuzde: "",
        sozlesmeTamamlanmaYuzde: "",
      },
      //PDF DEĞİŞKENLERİ
      pdfdialogOpenLoading: false,
      pdfdialogVisibleYonetici: false,
      pdfsiparisDetayUrun: [],
      pdfsiparisDialogDetay: "",
      pdfLoading: false,
      isLoading: false,
    };
  },
  mounted() {
    this.secilenMusteri = this.$store.getters.getMusteriDetayData;
    this.secilenProje = this.$store.getters.getProjeDetayData.projeBaslik;
    this.projeList = this.$store.getters.getProjeDetayData;
    if (this.$store.getters.getProjeDetayData.projeID != undefined)
      this.getProjeDetay(this.$store.getters.getProjeDetayData.projeID);
    this.getMusterilerinListesi();
    window.addEventListener("resize", this.checkDevice);
  },

  created() {
    this.checkDevice();
    this.yoneticiID = this.$store.getters.getYoneticiID;
    this.yetkiTuru = this.$store.getters.getYetkiTuru;
  },

  beforeDestroy() {
    this.$store.commit("changeMusteriDetayData", "");
    // this.$store.commit("changeProjeDetayData", "")
    this.$store.commit("changeProjeBaslikDetayData", "");
    window.removeEventListener("resize", this.checkDevice);
  },

  computed: {
    sortedSozlesmeler() {
      const sortedData = this.sozlesmeler
        .slice()
        .sort((a, b) => a.sozlesmeKategoriID - b.sozlesmeKategoriID);
      const result = [];
      let lastKategoriID = null;
      for (const item of sortedData) {
        if (item.sozlesmeKategoriID !== lastKategoriID) {
          item.sozlesmedegisti = true;
          lastKategoriID = item.sozlesmeKategoriID;
        } else {
          item.sozlesmedegisti = false;
        }
        result.push(item);
        // console.log(item);
      }

      return result;
    },

    storeData() {
      return this.$store.getters.getMusteriDetayData;
    },

    formattedData() {
      const formatted = [];
      let currentYear = "";
      for (const item of this.tarihler2) {
        if (item.year !== currentYear) {
          currentYear = item.year;
          formatted.push({ year: currentYear, months: [] });
        }
        const currentYearData = formatted.find(
          (data) => data.year === currentYear
        );
        currentYearData.months.push(item);
      }
      //console.log(formatted);
      return formatted;
    },
  },

  watch: {
    storeData(val) {
      this.musteriForm.musteriID = this.$store.getters.getMusteriDetayData;
      this.secilenMusteri = this.$store.getters.getMusteriDetayData;
      this.musteriForm.projeID = this.$store.getters.getProjeDetayData;
      this.secilenProje = this.$store.getters.getProjeDetayData;
    },
  },
  methods: {
    sozlesmeDetay(prop) {
      this.sozlesmeDetayModal = prop;
      this.dialogSozlesme = true;
      //console.log(this.sozlesmeDetayModal);
    },
    openCenterDialog(row) {
      this.selectedRow = row;
    },
    closeCenterDialog() {
      this.selectedRow = null;
    },
    open(sozlesme) {
      console.log("özleşme", sozlesme);
      const musteri = this.musterilerList.find(
        (e) => e.musteriID == this.secilenMusteri
      );
      const musteriAdi = musteri.musteriAdi;
      const projeAdi =
        musteri.projeler.length === 1
          ? musteri.projeler[0].projeBaslik
          : this.projeList.projeBaslik;
      this.userDataBGSurec = {
        sozlesme: sozlesme,
        musteriAdi: musteriAdi,
        projeAdi: projeAdi,
      };
      this.dialogVisibleYonetici = true;
      /*
      const gorevli = sozlesme.adSoyad
      const aciklama = sozlesme.aciklama
      const baslangicTarihi = moment(sozlesme.baslamaTarihi).format("DD.MM.YYYY");
      const bitisTarihi = moment(sozlesme.tamamlanmaTarihi).format("DD.MM.YYYY");
      const tamamlanmaTarihi = sozlesme.isTamamlanmaTarihi
      const gecikme = sozlesme.gecikenGun
      const tamamlanmaMetni = tamamlanmaTarihi === null || tamamlanmaTarihi == "" || tamamlanmaTarihi == undefined ? "Sözleşme Henüz Bitmemiş." : moment(tamamlanmaTarihi).format("DD.MM.YYYY");
      const aciklamaMetni = aciklama === null || aciklama == "" || aciklama == undefined ? "Açıklama Girilmemiş." : aciklama;
      const gorevliMetni = gorevli === null || gorevli == "" ? "Görevli Girilmemiş." : gorevli;

      const content = `
          <div class="alert-content">
              <div><span class="label1"><b>Müşteri :</b></span> ${musteriAdi}</div>

              <div><span class="label2"><b>Proje :</b></span> ${projeAdi}</div>

              <div><span class="label3"><b>Başlama Tarihi :</b></span> ${baslangicTarihi}</div>

              <div><span class="label4"><b>Bitiş Tarihi :</b></span> ${bitisTarihi}</div>

              <div><span class="label5"><b>Tamamlanma Tarihi :</b></span> ${tamamlanmaMetni}</div>

              <div><span class="label6"><b>Gecikme :</b></span> ${gecikme} Gün</div>

              <div><span class="label7"><b>Açıklama :</b></span> ${aciklamaMetni}</div>
          </div>
      `;

      this.$alert(content, gorevliMetni, {
          confirmButtonText: 'Tamam',
          dangerouslyUseHTMLString: true,
      });*/
    },
    getTrimmedText(text) {
      if (text.length > 20) {
        return text.substring(0, 20) + "...";
      } else if (text == "" || text == null || text == undefined) {
        return "Açıklama Girilmemiş";
      }

      return text;
    },
    calculateColumnTotal(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, columnIndex) => {
        if (columnIndex === 0) {
          sums[columnIndex] = "Toplam";
          return;
        }

        const values = data.map((item) => Number(item[column.property]));

        if (!values.every((value) => isNaN(value))) {
          const total = values.reduce((prev, curr) => prev + curr, 0);
          sums[columnIndex] = total.toFixed(0); // Virgülden son
        } else {
          const sumsArray = Array(12).fill(0);

          data.forEach((item) => {
            item["sozlesmeAylari"].forEach((e) => {
              const year = e.year;
              const monthIndex = e.monthIndex;

              if (!sumsArray[year]) {
                sumsArray[year] = Array(12).fill(0);
              }

              sumsArray[year][monthIndex] += parseInt(e.days);
            });
          });

          const nonZeroSums = [];
          sumsArray.forEach((yearData) => {
            if (Array.isArray(yearData)) {
              const nonZeroMonths = yearData
                .slice(1)
                .filter((value) => value !== 0);
              nonZeroMonths.forEach((value) => nonZeroSums.push(value));
            }
          });
          sums[columnIndex] = nonZeroSums[columnIndex - 4];
        }
      });

      return sums;
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },
    //proje seçmeli
    async getMusteriRapor(musteriID) {
      try {
        const token = localStorage.getItem("userDataBGSurec");
        const formData = new FormData();
        formData.append("token", token);
        formData.append("musteriID", musteriID);
        formData.append("serviceName", "musteri");
        formData.append("methodName", "musteriRapor");

        //const projeIDler = this.projelerList.filter(proje => proje.selected).map(proje => proje.projeID);
        //formData.append("projeIDler", JSON.stringify(projeIDler));
        /*
        const projeIDler = this.projelerList.filter(proje => proje.selected).map(proje => proje.projeID);
        projeIDler.forEach(projeID => {
            formData.append("projeIDler[]", projeID);
        });*/

        const projeID = this.$store.getters.getProjeDetayData
          ? this.$store.getters.getProjeDetayData.projeID
          : this.secilenProje;
        formData.append("projeIDler[]", projeID);

        //const response = await musteriService.musteriRapor(musteriID);
        //const fetchResponse = await fetch("https://eticaret.hakanbt.com.tr/bgsurec-api/webservis.php", {
        //const fetchResponse = await fetch("https://flowpro.bggrupinsaat.com.tr/bgsurec-api/webservis.php", {
        //const fetchResponse = await fetch("http://172.18.1.6/bgsurec-api/webservis.php", {

        //Önceki metod 2023.12.01 09:50
        //const fetchResponse = await fetch("http://localhost/bgsurec-api/webservis.php", {
        //method: "POST",
        //body: formData
        //});
        //if (!fetchResponse.ok) {
        //throw new Error(`HTTP error! status: ${fetchResponse.status}`);
        //}

        //const blob = await fetchResponse.blob();
        //const url = window.URL.createObjectURL(blob);
        //const link = document.createElement("a");
        //link.href = url;
        //link.setAttribute("download", "musteriRapor.pdf");
        //document.body.appendChild(link);
        //link.click();

        const xhr = new XMLHttpRequest();
        xhr.open("POST", wsProvider.apiPath + "webservis.php");

        xhr.responseType = "blob";
        xhr.onload = function () {
          const url = window.URL.createObjectURL(xhr.response);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "musteriRapor.pdf");
          document.body.appendChild(link);
          link.click();
        };

        xhr.onerror = function () {
          console.log("İstek sırasında bir hatayla karşılaşıldı");
        };
        xhr.send(formData);
      } catch (err) {
        console.log(err);
      }
    },
    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;
    },
    getProjeDetay(event) {
      try {
        this.loading = true;
        projeService
          .projeDetay(event)
          .then((response) => {
            if (response.status == 200) {
              localStorage.setItem("userDataBGSurec", response.token);
              this.projeList = response.data;
              this.sozlesmeler = response.data.sozlesmeler;
              this.tarihler = functions.daysInMonths();
              const yuzdeDegerToplami = this.sozlesmeler
                .filter(
                  (sozlesme) =>
                    sozlesme.isDurumu == 20 || sozlesme.isDurumu == 30
                )
                .reduce(
                  (toplam, sozlesme) =>
                    toplam + parseFloat(sozlesme.yuzdeDeger),
                  0
                );
              const yuzdeDegerTumToplam = this.sozlesmeler.reduce(
                (toplam, sozlesme) => toplam + parseFloat(sozlesme.yuzdeDeger),
                0
              );
              this.projeForm.sozlesmeTamamlanmaYuzde = parseFloat(
                (yuzdeDegerToplami * 100) / yuzdeDegerTumToplam
              ).toFixed(1);
              this.projeForm.musteriID = response.data.musteriID;
              this.projeForm.musteriAdi = response.data.musteriAdi;
              this.projeForm.projeBaslik = response.data.projeBaslik;
              this.projeForm.baslamaTarihi = response.data.baslangicTarihi;
              this.projeForm.tamamlanmaTarihi = response.data.bitisTarihi;
              this.projeForm.sozlesmeBaslamaTarihi =
                response.data.sozlesmeBaslama;
              this.projeForm.sozlesmeBitisTarihi = response.data.sozlesmeBitis;
              const baslamaTarihi = new Date(
                this.projeForm.sozlesmeBaslamaTarihi
              );
              const bitisTarihi = new Date(this.projeForm.sozlesmeBitisTarihi);
              const simdikiTarih = new Date();
              const oneDay = 24 * 60 * 60 * 1000; // Bir günün milisaniye cinsinden değeri
              const toplamGun = Math.round(
                Math.abs((bitisTarihi - baslamaTarihi) / oneDay)
              );
              this.projeForm.toplamGun = toplamGun;
              if (baslamaTarihi > simdikiTarih || bitisTarihi < simdikiTarih) {
                this.projeForm.kalanGun = 0;
              } else {
                const kalanGun = Math.round(
                  Math.abs((bitisTarihi - simdikiTarih) / oneDay)
                );
                this.projeForm.kalanGun = kalanGun;
              }
              const toplamSure = bitisTarihi - baslamaTarihi;
              const gecenSure = simdikiTarih - baslamaTarihi;
              const yuzdeDeger = (gecenSure / toplamSure) * 100;
              this.projeForm.yuzdeDeger = yuzdeDeger.toFixed(1); // Yüzdeyi iki
              // console.log("proje detay")
              // console.log(this.projeForm)
              this.sozlesmeler.forEach((proje) => {
                proje.toplamProjeSuresi = 0;
                proje.baslamaGecenSure = 0;
                proje.baslamaSureYuzde = 0;
                proje.kalanSure = 0;
                proje.toplamProjeSuresi = getDays(
                  proje.baslamaTarihi,
                  proje.tamamlanmaTarihi
                );
                projeBasladiMi(
                  moment().format("YYYY-MM-DD"),
                  proje.baslamaTarihi
                )
                  ? (proje.baslamaGecenSure = getDays(
                      proje.baslamaTarihi,
                      moment().format("YYYY-MM-DD")
                    ))
                  : (proje.baslamaGecenSure = 0);
                proje.kalanSure =
                  proje.toplamProjeSuresi - proje.baslamaGecenSure;
                if (proje.kalanSure < 0) {
                  proje.kalanSure = 0;
                }
                proje.baslamaSureYuzde = parseFloat(
                  (parseInt(proje.baslamaGecenSure) * 100) /
                    parseInt(proje.toplamProjeSuresi)
                ).toFixed(1);
                if (proje.baslamaSureYuzde > 100) {
                  proje.baslamaSureYuzde = 100;
                }
                proje.toplamSozlesme = 0;
                proje.bitenSozlesme = 0;
                proje.toplamSozlesme += parseFloat(proje.yuzdeDeger);
                proje.bitenSozlesme += sozlesmeBittiMi(
                  moment().format("YYYY-MM-DD"),
                  proje.tamamlanmaTarihi
                )
                  ? parseFloat(proje.yuzdeDeger)
                  : 0;
                proje.bitmeYuzdesi = parseFloat(
                  (proje.bitenSozlesme * 100) / proje.toplamSozlesme
                ).toFixed(1);
                proje.toplamYuzde = parseFloat(
                  (proje.yuzdeDeger * 100) / proje.toplamSozlesme
                ).toFixed(1);
                proje.toplamCalisilanGun = 0;
                proje.sozlesmeAylari = getMonthsBetween(
                  proje.baslamaTarihi,
                  proje.tamamlanmaTarihi
                );
                proje.sozlesmeAylari.forEach((ay) => {
                  proje.toplamCalisilanGun += parseInt(ay.days);
                });
                proje.sozlesmeAylari.forEach((ay, index) => {
                  var daysInMonth = this.tarihler.find(
                    (e) => e.monthIndex == ay.monthIndex
                  );
                  ay.dayPercentage =
                    proje.sozlesmeAylari.length == 1
                      ? parseFloat(
                          (parseInt(ay.days) * 100) / daysInMonth.days
                        ).toFixed(1)
                      : index > 0 && index < proje.sozlesmeAylari.length - 1
                      ? 100
                      : (
                          parseFloat(
                            (parseInt(ay.days) * 100) / daysInMonth.days
                          ) + 3
                        ).toFixed(1) + 5;
                });
                proje.bitti = checkDateRange(
                  proje.baslamaTarihi,
                  proje.tamamlanmaTarihi
                );
                proje.sozlesmeAylari.forEach((ay) => {
                  // Ay nesnesinin tüm özelliklerini tarihler2 dizisine ekliyoruz
                  if (
                    !this.tarihler2.some(
                      (item) => item.month === ay.month && item.year === ay.year
                    )
                  ) {
                    this.tarihler2.push(ay);
                  }
                });
                // tarihler2 dizisini yazdırarak sonuçları kontrol edebiliriz
              });
              this.tarihler2.sort((a, b) => {
                if (a.year !== b.year) {
                  return a.year - b.year; // İlk olarak yıllara göre sıralama
                } else {
                  return a.monthIndex - b.monthIndex; // Yıllar aynıysa aylara göre sıralama
                }
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        this.loading = false;
      }
    },
    getMusterilerinListesi() {
      try {
        this.loading = true;
        musteriService
          .musterilerinProjeleri2(this.selectIcon, this.postPage, "")
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.total = parseInt(response.data.musteriSayisi);
              this.musterilerList = response.data;

              if (this.yetkiTuru == 1) {
                this.musterilerList = response.data;
              }
              if (this.yetkiTuru == 2) {
                this.musterilerList = response.data.filter((musteri) => {
                  return musteri.atanmislar.some((yonetici) => {
                    return yonetici.yoneticiID === this.yoneticiID;
                  });
                });
              }

              this.total = parseInt(response.count);
              this.tarihler = functions.daysInMonths3();
              // this.musterilerList.forEach(musteri => {
              //     if (musteri.projeler.length > 0) {
              //         musteri.projeler.forEach(proje => {
              //             proje.tarihler = functions.daysInMonths3();
              //             proje.toplamProjeSuresi = 0
              //             proje.baslamaGecenSure = 0
              //             proje.baslamaSureYuzde = 0
              //             proje.kalanSure = 0

              //             proje.toplamProjeSuresi = getDays(proje.baslangicTarihi, proje.bitisTarihi);
              //             projeBasladiMi(moment().format("YYYY-MM-DD"), proje.baslangicTarihi) ? proje.baslamaGecenSure = getDays(proje.baslangicTarihi, moment().format("YYYY-MM-DD")) : proje.baslamaGecenSure = 0
              //             proje.kalanSure = proje.toplamProjeSuresi - proje.baslamaGecenSure

              //             if (proje.kalanSure < 0) {
              //                 proje.kalanSure = 0
              //             }

              //             proje.baslamaSureYuzde = parseFloat(parseInt(proje.baslamaGecenSure) * 100 / parseInt(proje.toplamProjeSuresi)).toFixed(1)
              //             if (proje.baslamaSureYuzde > 100) {
              //                 proje.baslamaSureYuzde = 100
              //             }

              //             proje.toplamSozlesme = 0
              //             proje.bitenSozlesme = 0

              //             proje.sozlesmeler.forEach(sozlesme => {
              //                 proje.toplamSozlesme += parseFloat(sozlesme.yuzdeDeger)
              //                 proje.bitenSozlesme += sozlesmeBittiMi(moment().format("YYYY-MM-DD"), sozlesme.isTeslimTarihi) ? parseFloat(sozlesme.yuzdeDeger) : 0
              //             })
              //             proje.bitmeYuzdesi = parseFloat(proje.bitenSozlesme * 100 / proje.toplamSozlesme).toFixed(1)

              //             proje.sozlesmeler.forEach(s => {
              //                 s.toplamYuzde = parseFloat((s.yuzdeDeger) * 100 / proje.toplamSozlesme).toFixed(1)
              //                 s.toplamCalisilanGun = 0
              //                 s.sozlesmeAylari = getMonthsBetween(s.baslamaTarihi, s.isTeslimTarihi)

              //                 s.sozlesmeAylari.forEach(ay => {
              //                     s.toplamCalisilanGun += parseInt(ay.days)
              //                 })

              //                 s.sozlesmeAylari.forEach((ay, index) => {
              //                     var daysInMonth = proje.tarihler.find(e => e.monthIndex == ay.monthIndex)
              //                     ay.dayPercentage = s.sozlesmeAylari.length == 1 ? parseFloat(parseInt(ay.days) * 100 / daysInMonth.days).toFixed(1) : index > 0 && index < s.sozlesmeAylari.length - 1 ? 100 : parseFloat(parseInt(ay.days) * 100 / daysInMonth.days).toFixed(1)

              //                     if (daysInMonth) {
              //                         daysInMonth.count++
              //                     }

              //                 })

              //                 // Bugunun tarihi aralık olarak secilen gün ile karsılaştırılıyor ve bulunulan gün başlama tarihinden önce ise bitti = 0, tarihlerin arasında ise bitti = 1, bitiş tarihini geçmiş ise bitti = 2 (bitti değişkeni o sözleşmenin durumunu temsil ediyor.)
              //                 s.bitti = checkDateRange(s.baslamaTarihi, s.isTeslimTarihi) // durum = 0 başlamadı, durum = 1 başlandı, durum = 2 bitti

              //             })

              //             if (this.secilenProje == "" || this.secilenProje == undefined || this.secilenProje == null) {
              //                 console.log("bu")
              //                 console.log(proje)
              //                 proje.tarihlerSon = proje.tarihler.filter(e => e.count > 0)
              //                 this.tarihlerSon = proje.tarihlerSon
              //             }

              //             else {

              //                 console.log("şu")
              //                 console.log(proje.sozlesmeler);
              //                 proje.sozlesmeler.forEach(s => {

              //                     s.toplamYuzde = parseFloat((s.yuzdeDeger) * 100 / proje.toplamSozlesme).toFixed(1)
              //                     s.toplamCalisilanGun = 0
              //                     s.sozlesmeAylari = getMonthsBetween(s.baslamaTarihi, s.isTeslimTarihi)

              //                     s.sozlesmeAylari.forEach(ay => {
              //                         s.toplamCalisilanGun += parseInt(ay.days)
              //                     })

              //                     s.sozlesmeAylari.forEach((ay, index) => {
              //                         debugger;
              //                         var daysInMonth = proje.tarihler.find(e => e.monthIndex == ay.monthIndex)
              //                         ay.dayPercentage = s.sozlesmeAylari.length == 1 ? parseFloat(parseInt(ay.days) * 100 / daysInMonth.days).toFixed(1) : index > 0 && index < s.sozlesmeAylari.length - 1 ? 100 : parseFloat(parseInt(ay.days) * 100 / daysInMonth.days).toFixed(1)

              //                         if (daysInMonth) {
              //                             console.log
              //                             daysInMonth.count++
              //                         }

              //                     })

              //                     // Bugunun tarihi aralık olarak secilen gün ile karsılaştırılıyor ve bulunulan gün başlama tarihinden önce ise bitti = 0, tarihlerin arasında ise bitti = 1, bitiş tarihini geçmiş ise bitti = 2 (bitti değişkeni o sözleşmenin durumunu temsil ediyor.)
              //                     s.bitti = checkDateRange(s.baslamaTarihi, s.isTeslimTarihi) // durum = 0 başlamadı, durum = 1 başlandı, durum = 2 bitti

              //                 })

              //             }

              //         })

              //         console.log("***");
              //         console.log(this.projeList);
              //         console.log(this.secilenProje);
              //         console.log("tarihler Son");
              //         console.log(this.tarihlerSon);

              //     }
              // })
            }
            /*
          if(this.secilenMusteri){
             var musteri = this.musterilerList.find(e => e.musteriID == this.secilenMusteri)
             musteri ? this.projeList = musteri.projeler : ""
          }*/
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.musterilerList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },
    format(percentage) {
      return percentage === 100 ? "Full" : `${percentage}%`;
    },
    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    },
    /*
    musteriSecimi(event) {
        this.displayedYears.clear();
        var item = this.musterilerList.find((el) => el.musteriID == event)
        console.log(item)
        this.projelerList = item ? item.projeler : []
        this.secilenProje = ""
        this.tarihler2 = [];
        this.secilenProje = this.projelerList[0].projeID; // İlk projeyi seç
        this.getProjeDetay(this.secilenProje);

    },*/
    musteriSecimi(event) {
      this.displayedYears.clear();
      var item = this.musterilerList.find((el) => el.musteriID == event);
      if (item !== null && this.yetkiTuru == 2) {
        var atanmisProjeIDler = item.atanmislar.map((atama) => atama.projeID);
        this.projelerList = item.projeler.filter((proje) =>
          atanmisProjeIDler.includes(proje.projeID)
        );
        if (this.projelerList.length === 0) {
          this.projelerList = [];
        }
      } else {
        this.projelerList = item ? item.projeler : [];
      }
      this.secilenProje = "";
      this.tarihler2 = [];
      this.secilenProje = this.projelerList[0].projeID;

      this.getProjeDetay(this.secilenProje);
    },
    projeSecimi(event) {
      this.displayedYears.clear();
      this.sozlesmeler = [];
      this.tarihler2 = [];

      this.getProjeDetay(event);
    },
    shouldDisplayYear(index) {
      const year = this.tarihler2[index].year;
      if (!this.displayedYears.has(year)) {
        this.displayedYears.add(year);
        return true;
      }
    },
    dateStyle(prop, date) {
      const startDate = new Date(prop.baslamaTarihi);
      const startDay = startDate.getDate(); // Başlangıç
      let gun = startDay * 3.2;
      if (
        prop.sozlesmeAylari.find(
          (e) => e.monthIndex == date.monthIndex && e.year == date.year
        )
      ) {
        let konum = "";

        if (prop.sozlesmeAylari.length == 1)
          if (startDay > 1) {
            konum = "center";
          } else {
            konum = "left"; // sozlesmenin tarih kapsamı tek bir ay için geçerli ise sola yaslanmalı
          }
        else if (prop.sozlesmeAylari.length > 1) {
          var index = prop.sozlesmeAylari.findIndex(
            (e) => e.monthIndex == date.monthIndex
          ); // eger birden fazla ay içeriyorsa ilk ay sağ tarafa konumlanmalı diğerleri sol kalmalı
          if (index == 0) konum = "right";
          else konum = "left";
        }

        const backgroundColor =
          prop.isDurumu == 10
            ? "rgb(37 139 201 / 71%)"
            : prop.isDurumu == 20
            ? "rgb(98 207 35 / 68%)"
            : prop.isDurumu == 30
            ? "rgb(98 207 35 / 68%)"
            : "rgb(237 234 155)"; // mavi kırmızı yeşil 2 bitti 1 devam ediyor 0 başlamadı  //mavi kırmızı yeşil 2 bitti 1 devam ediyor 0 başlamadı  98C7E5  f9e0e7 C2E797
        return {
          position: "absolute",
          left: konum == "left" ? 0 : konum == "center" ? gun + "%" : "",
          right: konum == "right" ? 0 : "",
          top: 0,
          zIndex: 1,
          height: "100%",
          background: backgroundColor,
          display: prop.sozlesmeAylari.find(
            (e) => e.monthIndex == date.monthIndex
          )
            ? "flex"
            : "none",
          justifyContent: "center",
          width: prop.sozlesmeAylari.find(
            (e) => e.monthIndex == date.monthIndex
          )
            ? `${
                prop.sozlesmeAylari.find((e) => e.monthIndex == date.monthIndex)
                  .dayPercentage
              }% !important`
            : "0",
        };
      }
    },
  },
  updated() {
    if (this.isFirstColumn) {
      this.isFirstColumn = false;
    }
  },
};

//baslamaTarihi isTeslimTarihi
// verilen baslangıc ve bitis tarihleri arasında hangi aylar var başlangıc ve bitişte dahil. Ve o aylar başlangıc ve bitiş aralıgında kacar gün dahil edilmiş
function getMonthsBetween(start, end) {
  moment.locale("tr");
  const results = [];

  for (
    let m = moment(start);
    m.isSameOrBefore(end, "month");
    m.add(1, "months")
  ) {
    const monthStart = moment(m).startOf("month");
    const monthEnd = moment(m).endOf("month");

    let daysInMonth = monthEnd.diff(monthStart, "days") + 1;

    if (m.isSame(moment(start).startOf("month"), "month")) {
      daysInMonth -= moment(start).date();
    }
    if (m.isSame(moment(end).endOf("month"), "month")) {
      daysInMonth -= monthEnd.date() - moment(end).date();
    }

    results.push({
      year: monthStart.format("YYYY"), // Yıl bilgisini ekledik
      month: monthStart.format("MMMM"),
      monthIndex: parseInt(monthStart.month()) + 1,
      days: daysInMonth,
    });
  }

  return results;
}

//bugunun tarihi sözleşmedeki baslangıc ve bitiş tarihi ile karsılastırılır. Eğer sozlesme başlamadıysa durum 0, baslamıs ise yani bulundugumuz günün tarihi baslangıc ve bitis tarihleri arasında ise durum 1, bitiş tarihini geçmiş ise durum 2 yani bitmiş olarak değerlendirilecek.
function checkDateRange(start, end) {
  const today = moment();
  if (today.isBefore(start, "day")) {
    return 0;
  } else if (today.isBetween(start, end, "day")) {
    return 1;
  } else {
    return 2;
  }
}

function getDays(tarih1, tarih2) {
  // Tarihleri Moment objelerine dönüştür ve gün farkını hesapla
  const tarih1Moment = moment(tarih1);
  const tarih2Moment = moment(tarih2);
  const gunFarki = tarih2Moment.diff(tarih1Moment, "days");

  // Sonucu döndür
  return gunFarki;
}

function sozlesmeBittiMi(tarih1, tarih2) {
  // Tarihleri Moment objelerine dönüştür
  const momentTarih1 = moment(tarih1);
  const momentTarih2 = moment(tarih2);

  // Tarih1 tarih2'den sonra mı?
  return momentTarih1.isAfter(momentTarih2);
}

function projeBasladiMi(bugun, baslangicTarihi) {
  const momentBugun = moment(bugun);
  const momentBaslangic = moment(baslangicTarihi);

  return momentBugun.isAfter(momentBaslangic);
}
</script>

<style lang="scss">
@media screen and (min-width: 500px) {
  .musteri-rapor-page {
    .el-progress-bar__outer {
      width: 96% !important;
    }
  }
}
</style>
<style scoped>
.musteri-rapor-table-year {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
}

.musteri-rapor-table-month {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
}

.mobile-proje-area .el-divider--horizontal {
  margin: 11px 0;
}

.mobile-proje-area p {
  display: inline-block;
}

.mobile-proje-area .el-col {
  justify-content: space-between;
  display: flex;
}

.cizgi {
  width: 2px;
  display: block;
  height: 44px;
  background-color: #006cae29;
  justify-content: center;
  margin: 0 15px;
}
</style>
<style lang="scss">
#musteriSelect {
  height: 34px !important;
}

#projeSelect {
  height: 34px !important;
}

.noItem {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px !important;
}

#musterirapor {
  .el-table__cell {
    .cell {
      padding: 0 !important;
    }
  }

  .el-progress-bar__outer {
    background: rgb(255, 255, 255) !important;
  }

  thead th {
    background: #edfcff !important;
    color: #00385a !important;
  }
}

.szModal {
  thead th {
    background-color: #e5e5e5 !important;
  }
}

.el-message-box__title {
  font-size: large;
  color: brown;
  font-weight: bold;
}

.el-message-box {
  width: 450px;
}

.label1 {
  padding-right: 123.5px;
}

.label2 {
  padding-right: 140px;
}

.label3 {
  padding-right: 71px;
}

.label4 {
  padding-right: 105.5px;
}

.label5 {
  padding-right: 32.5px;
}

.label6 {
  padding-right: 112.5px;
}

.label7 {
  padding-right: 109px;
}
</style>
