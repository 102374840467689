<template>
  <div class="box-logo flex align-center" :class="{'nav-collapsed':collapseNav, [mode]:true}">
    <div class="app-name" @click="goto('/')">
      <img src="@/assets/images/1-BG-GRUP.png" alt="">
    </div>
    <button class="collapse-nav" @click="collapseNavToggle">
      <i class="mdi mdi-menu"></i>
    </button>
  </div>
</template>


<script>
import JQuery from 'jquery';

let $ = JQuery;
export default {
  name: 'Logo',
  props: ['collapseNav', 'mode'],
  data() {
    return {}
  },
  methods: {
    collapseNavToggle() {
      this.$emit('collapse-nav-toggle')
    },
    goto(index, indexPath) {
      var tabList = [];
      this.$store.commit('changeTabList', tabList);
      $(".horizontal-nav.pos-top").removeClass("shadowBox");
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/_variables';
@import '../assets/scss/_mixins';

.box-logo {
  height: 60px;
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  @include text-bordered-shadow();


  .image-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
  }

  .app-name {
    cursor: pointer;
    margin-left: 1px;

    img {
      position: relative;
      top: 0px;
      height: 80px;
      margin-left: 1px;
    }
  }

  .collapse-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -30px;
    height: 60px;
    width: 30px;
    opacity: 1;
    cursor: pointer;
    border: 0;
    color: $background-color;
    background-color: transparent;
    outline: none;
    transition: all 500ms;

    i {
      font-size: 32px;
    }
  }


  &.nav-collapsed {
    .app-name {
      display: none;
    }
  }

  &.horizontal {
    height: 100%;

    .collapse-nav {
      display: none;
    }
  }
}


@media (max-width: 768px) {
  .box-logo {
    .collapse-nav {
      display: none;
    }

    &.horizontal {
      padding: 0 10px;

      .app-name {
        display: none;
      }
    }
  }

}
</style>

