const Proje = resolve => {
    require.ensure(['../views/apps/proje/Proje'], () => {
        resolve(require('../views/apps/proje/Proje'))
    })
};
const ProjeListesi = resolve => {
    require.ensure(['../views/apps/proje/ProjeListesi'], () => {
        resolve(require('../views/apps/proje/ProjeListesi'))
    })
};
const ProjeTanimla = resolve => {
    require.ensure(['../views/apps/proje/ProjeTanimla'], () => {
        resolve(require('../views/apps/proje/ProjeTanimla'))
    })
};

const ProjeGuncelle = resolve => {
    require.ensure(['../views/apps/proje/ProjeGuncelle'], () => {
        resolve(require('../views/apps/proje/ProjeGuncelle'))
    })
};

/*
const MusteriListesi = resolve => {
    require.ensure(['../views/apps/musteri/MusteriListesi'], () => {
        resolve(require('../views/apps/musteri/MusteriListesi'))
    })
};*/



import layouts from '../layout'
import Vue from 'vue'
Vue.component('projelistesi', ProjeListesi);
Vue.component('projetanimla', ProjeTanimla);
Vue.component('projeguncelle', ProjeGuncelle)
//Vue.component('musterilistesi', MusteriListesi);

export default {
    path: '/proje',
    name: 'proje',
    component: Proje,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [
        {
            path: 'liste',
            name: 'projeListesi',
            component: ProjeListesi,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                searchable: true,
                tags: ['app']
            }
        },
       /* {
            path: 'liste',
            name: 'musteriListesi',
            component: MusteriListesi,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                searchable: true,
                tags: ['app']
            }
        }*/
    ],

}
