const Atama = resolve => {
    require.ensure(['../views/apps/atama/Atama'], () => {
        resolve(require('../views/apps/atama/Atama'))
    })
};
const AtamaTanimla = resolve => {
    require.ensure(['../views/apps/atama/AtamaTanimla'], () => {
        resolve(require('../views/apps/atama/AtamaTanimla'))
    })
};
const AtamaListesi = resolve => {
    require.ensure(['../views/apps/atama/AtamaListesi'], () => {
        resolve(require('../views/apps/atama/AtamaListesi'))
    })
};


import layouts from '../layout'
import Vue from 'vue'
Vue.component('yenigorev-tanimla', AtamaTanimla);
Vue.component('gorevlistesi', AtamaListesi);


export default {
    path: '/atama',
    name: 'atama',
    component: Atama,
    meta: {
        layout: layouts.navLeft
    }

}