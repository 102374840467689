<template>
  <el-dialog
    :custom-class="'user-dialog'"
    :visible="dialogvisible"
    width="40%"
    @close="$emit('update:dialogvisible', false)"
  >
    <div class="avatar-box">
      <p
        style="
          text-align: center;
          padding-top: 110px;
          color: white;
          font-size: 25px;
        "
      >
        {{ userdata.personelAdi }}
      </p>
    </div>
    <div
      :class="isDesktop ? 'right-box box grow' : ''"
      class="degerlendirme-detay-modal"
    >
      <dl :class="isDesktop ? 'horizontal' : 'horizontal2'">
        <dt><i v-if="isDesktop" class="mdi mdi-account"></i>Değerlendiren :</dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.degerlendirenAdi }}
        </dd>
        <el-divider></el-divider>
        <dt><i v-if="isDesktop" class="mdi mdi-star"></i>Değerlendirme :</dt>
        <dd :class="isDesktop ? '' : 'tel'">
          <el-rate :value="parseInt(userdata.yildiz)" disabled></el-rate>
        </dd>
        <el-divider></el-divider>
        <dt>
          <i v-if="isDesktop" class="mdi mdi-office-building"></i>Müşteri :
        </dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.musteriAdi }}
        </dd>
        <el-divider></el-divider>
        <dt><i v-if="isDesktop" class="mdi mdi-file-check"></i>Proje :</dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.projeBaslik }}
        </dd>
        <el-divider></el-divider>
        <dt>
          <i v-if="isDesktop" class="mdi mdi-format-list-bulleted"></i>Sözleşme
          :
        </dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.sozlesmeBaslik }}
        </dd>
        <el-divider></el-divider>
        <dt><i v-if="isDesktop" class="mdi mdi-text"></i>Açıklama :</dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.aciklama }}
        </dd>
        <el-divider></el-divider>
        <div class="dialog-footer-personel">
          <el-tooltip
            v-if="yetkiTuru == '1'"
            content="İncelendi Olarak İşaretle"
            :open-delay="500"
            placement="bottom"
          >
            <el-button
              @click="changeDegerlendirmeDurum"
              type="success"
              icon="mdi mdi-check mdi-24px"
              class="center-btn"
              >İncelendi olarak işaretle</el-button
            >
          </el-tooltip>
        </div>
      </dl>
    </div>
  </el-dialog>
</template>

<script>
import yoneticiService from "../WSProvider/YoneticiService";
import moment from "moment";
import kapsamService from "@/WSProvider/KapsamService";
import notification from "@/notification";
import EventBus from "@/components/event-bus";

export default {
  name: "PersonelDegerlendirme",
  props: ["userdata", "dialogvisible"],
  data() {
    return {
      isDesktop: false,
      activeName: "first",
    };
  },
  created() {
    this.checkDevice();
  },
  computed: {
    yetkiTuru() {
      return this.$store.state.yetkiTuru;
    },
  },
  mounted() {
    window.addEventListener("resize", this.checkDevice);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
  methods: {
    changeDegerlendirmeDurum() {
      this.$confirm(
        "Değerlendirme durumu değiştirmek istediğinize emin misiniz?",
        this.$t("src.views.apps.genel.uyari"),
        {
          confirmButtonText: this.$t("src.views.apps.genel.yes"),
          cancelButtonText: this.$t("src.views.apps.genel.no"),
          type: "warning",
        }
      ).then(() => {
        try {
          kapsamService
            .personelDegerlendirmeDurumDegistir(
              this.userdata.personelDegerlendirmeID,
              2
            )
            .then((response) => {
              if (response.status == 200) {
                notification.Status(
                  "success",
                  this,
                  "Değerlendirme durumu başarıyla güncellendi"
                );
                EventBus.$emit("personelDegerlendirmeList", true);
                this.$emit("update:dialogvisible", false);
              }
              localStorage.setItem("userDataBGSurec", response.token);
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
            });
        } catch (e) {
          // notification.Status("success", this, "An error occurred while fetching the customer number.");
        }
      });
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_variables";

.degerlendirme-detay-modal dl button i {
  color: green;
}

.degerlendirme-detay-modal .el-divider--horizontal {
  margin: 6px 0;
}

dl i {
  color: #2c3437;
  margin: 0 10px;
}

dl.horizontal dt {
  text-align: left;
  width: 36%;
}

dl.horizontal2 dt {
  text-align: center;
  width: 100%;
}

dl dd.tel {
  text-align: center;
}

.form-box .el-form div {
  margin: 20px 0;
}

.form-box .el-form i {
  font-size: 20px;
}

.el-dialog.user-dialog {
  margin-top: 10vh !important;

  .el-dialog__header {
    padding: 0;

    .el-dialog__headerbtn {
      z-index: 1;
      background: rgba(0, 0, 0, 0.05);
      min-width: 18px;
      min-height: 18px;
      border-radius: 4px;

      .el-dialog__close {
        color: $background-color;
      }
    }
  }

  .el-dialog__body {
    padding: 0;
  }

  .form-box {
    padding: 5px;
    box-sizing: border-box;

    & > * {
      margin: 10px 0;
    }
  }
}
</style>

<style lang="scss">
.dialog-footer-personel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  padding: 0 10px 10px 10px;

  .center-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 0;

    i {
      margin-right: 5px;
      margin-bottom: 0;
      padding-bottom: 0;
      color: white;
    }
  }
}
</style>
