<template>
  <el-dialog
    :custom-class="'user-dialog'"
    :visible="dialogvisible"
    width="40%"
    @close="$emit('update:dialogvisible', false)"
  >
    <div class="avatar-box">
      <p
        style="
          text-align: center;
          padding-top: 110px;
          color: white;
          font-size: 25px;
        "
      >
        {{ userdata.konu }}
      </p>
    </div>
    <div
      :class="isDesktop ? 'right-box box grow' : ''"
      class="tutanak-detay-modal"
    >
      <dl :class="isDesktop ? 'horizontal' : 'horizontal2'">
        <dt>
          <i v-if="isDesktop" class="mdi mdi-account-circle"></i>Müşteri :
        </dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.musteriAdi }}
        </dd>
        <el-divider></el-divider>
        <dt><i v-if="isDesktop" class="mdi mdi-domain"></i>Proje :</dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.projeBaslik }}
        </dd>
        <el-divider></el-divider>
        <dt>
          <i v-if="isDesktop" class="mdi mdi-format-list-bulleted"></i>Sözleşme
          Adı :
        </dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.sozlesmeAdi }}
        </dd>
        <el-divider></el-divider>
        <dt><i v-if="isDesktop" class="mdi mdi-account"></i>Yetkili :</dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.yetkiliAdSoyad }}
        </dd>
        <el-divider></el-divider>
        <dt><i v-if="isDesktop" class="mdi mdi-text"></i>İçerik :</dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.icerik }}
        </dd>
        <el-divider></el-divider>
        <dt>
          <i v-if="isDesktop" class="mdi mdi-plus-circle-outline"></i>Oluşturan
          :
        </dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.olusturan }}
        </dd>
        <el-divider></el-divider>
        <dt>
          <i v-if="isDesktop" class="mdi mdi-account-settings"></i>İletişim Türü
          :
        </dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }}
          {{
            userdata.iletisimTuru == "1"
              ? "Telefon"
              : userdata.iletisimTuru == "2"
              ? "E-Posta"
              : userdata.iletisimTuru == "3"
              ? "Toplantı"
              : "Yüz Yüze"
          }}
        </dd>
        <el-divider></el-divider>
        <dt>
          <i v-if="isDesktop" class="mdi mdi-account-group"></i>Katılımcılar :
        </dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.katilimcilar }}
        </dd>
        <el-divider></el-divider>
        <dt><i v-if="isDesktop" class="mdi mdi-map-marker"></i>Yer :</dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.yer }}
        </dd>
        <el-divider></el-divider>
        <dt>
          <i v-if="isDesktop" class="mdi mdi-ticket-confirmation"></i>Sayı :
        </dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.sayi }}
        </dd>
        <el-divider></el-divider>
        <dt>
          <i v-if="isDesktop" class="mdi mdi-calendar-range"></i>Tutanak Tarihi
          :
        </dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }} {{ userdata.olusumTarihi.slice(0, 16) }}
        </dd>
        <el-divider></el-divider>
        <!-- <dt><i v-if="isDesktop" class="mdi mdi-download"></i>Dosya :</dt>
        <dd :class="isDesktop ? '' : 'tel'">
          {{ isDesktop ? ":" : "" }}
          <el-button @click="download()" class="ml-5" type="primary" size="mini"
            >Dosyayı İndir</el-button
          >
        </dd>
        <el-divider></el-divider> -->
      </dl>
    </div>
  </el-dialog>
</template>

<script>
import moment from "moment";
import personelService from "@/WSProvider/PersonelService";
import tutanakService from "@/WSProvider/TutanakService";

export default {
  name: "TutanakDetay",
  props: ["userdata", "dialogvisible"],
  data() {
    return {
      isDesktop: false,
      activeName: "first",
    };
  },
  created() {
    this.checkDevice();
  },
  mounted() {
    window.addEventListener("resize", this.checkDevice);
    console.log("userdata", this.userdata);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },

  // TODO : Dosya indirme işlemi icin backend tarafında dosya verisi gelmiyor
  methods: {
    download() {
      window.open(tutanakService.dosyaPath + this.userdata.dosya, "_blank");
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_variables";

.tutanak-detay-modal .el-divider--horizontal {
  margin: 6px 0;
}

dl i {
  color: #2c3437;
  margin: 0 10px;
}

dl.horizontal dt {
  text-align: left;
  width: 36%;
}

dl.horizontal2 dt {
  text-align: center;
  width: 100%;
}

dl dd.tel {
  text-align: center;
}

.form-box .el-form div {
  margin: 20px 0;
}

.form-box .el-form i {
  font-size: 20px;
}

.el-dialog.user-dialog {
  margin-top: 10vh !important;

  .el-dialog__header {
    padding: 0;

    .el-dialog__headerbtn {
      z-index: 1;
      background: rgba(0, 0, 0, 0.05);
      min-width: 18px;
      min-height: 18px;
      border-radius: 4px;

      .el-dialog__close {
        color: $background-color;
      }
    }
  }

  .el-dialog__body {
    padding: 0;
  }

  .form-box {
    padding: 5px;
    box-sizing: border-box;

    & > * {
      margin: 10px 0;
    }
  }
}
</style>
