const Degerlendirme = (resolve) => {
  require.ensure(["../views/apps/degerlendirme/Degerlendirme"], () => {
    resolve(require("../views/apps/degerlendirme/Degerlendirme"));
  });
};
const DegerlendirmeListesi = (resolve) => {
  require.ensure(["../views/apps/degerlendirme/DegerlendirmeListesi"], () => {
    resolve(require("../views/apps/degerlendirme/DegerlendirmeListesi"));
  });
};

import layouts from "../layout";
import Vue from "vue";
Vue.component("performans", DegerlendirmeListesi);

export default {
  path: "/performans",
  name: "performans",
  component: DegerlendirmeListesi,
  meta: {
    auth: true,
    layout: layouts.navLeft,
  },
};
