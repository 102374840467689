import WsProvider from "./provider/WSProvider";

var serviceName = "webservis";
var moduleName = "tutanak";

var tutanakService = {
  imagePath: WsProvider.imagePath,
  imagePathBuyuk: WsProvider.imagePathBuyuk,
  imagePathOrta: WsProvider.imagePathOrta,
  imagePathKucuk: WsProvider.imagePathKucuk,
  belgePath: WsProvider.documentPath,
  path: WsProvider.path,

  tutanakListesiAra(
    projeID,
    durum,
    baslangic,
    limit,
    sayi,
    konu,
    icerik,
    yetkiliAdSoyad,
    olusturan,
    yer,
    tarih
  ) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      durum: durum,
      baslangic: baslangic,
      limit,
      sayi,
      projeID: projeID || "",
      konu,
      icerik,
      yetkiliAdSoyad,
      olusturan,
      yer,
      olusumTarihi: tarih,
      serviceName: moduleName,
      methodName: "tutanakArama",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  tutanakListesi(durum, baslangic, limit) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      durum: durum,
      baslangic: baslangic,
      limit,
      serviceName: moduleName,
      methodName: "tutanakListesi",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  tutanakDurumChange(tutanakID, durum) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      tutanakID: tutanakID,
      durum: durum,
      serviceName: moduleName,
      methodName: "durumDegistir",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  tutanakTanimla(
    musteriID,
    bildiri,
    iletisimTuru,
    durum,
    projeID,
    konu,
    kisaAciklama,
    icerik,
    sayi,
    yer,
    katilimcilar,
    yetkiliAdSoyad,
    sozlesmeID,
    yetkililer
  ) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");

    var param = {
      token: userDataBGSurec,
      musteriID: musteriID,
      bildiri: bildiri ? 1 : 0,
      iletisimTuru: iletisimTuru,
      durum: durum,
      projeID: projeID,
      konu: konu,
      icerik: icerik,
      sayi: sayi,
      yer: yer,
      kisaAciklama: kisaAciklama,
      katilimcilar: bildiri ? JSON.stringify(katilimcilar) : "",
      yetkililer: bildiri ? JSON.stringify(yetkililer) : "",
      yetkiliAdSoyad: yetkiliAdSoyad,
      sozlesmeID: sozlesmeID,
      methodName: "tutanakTanimla",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
};

export default tutanakService;
