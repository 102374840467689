<template>
  <div class="layout-container flex" :class="{
		'column':navPos === 'top' || navPos === 'bottom',
		'boxed':boxed,
		'footer-above':footer === 'above',
		'content-only':!navPos
	}"
  >
    <vertical-nav
        :position="navPos"
        :collapse-nav="collapseNav"
        :open-sidebar.sync="openSidebar"
        @collapse-nav-toggle="collapseNav = !collapseNav"
        @push-page="closeSidebar"
        v-if="navPos === 'left'"/>
    <div class="container flex column box grow">
      <div class="hidden-md-and-up header" v-if="toolbar === 'top'">
        <Toolbar @toggle-sidebar="openSidebar = !openSidebar" :menu-burger="navPos"/>
      </div>
      <horizontal-nav v-if="$store.state.navVisible && navPos === 'top'" :position="navPos" @push-page="closeSidebar"/>
      <div class="hidden-md-and-up header" v-if="toolbar === 'bottom'">
        <Toolbar @toggle-sidebar="openSidebar = !openSidebar" :menu-burger="navPos"/>
      </div>
      <div class="main box grow flex">
        <Tabs v-if="$store.state.tabList.length !== 0"></Tabs>
        <router-view v-if="$store.state.tabList.length === 0" class="view box grow"/>
      </div>
      <horizontal-nav v-if="$store.state.navVisible && navPos === 'bottom'" :position="navPos" @push-page="closeSidebar"
                      style="margin-bottom:0;"/>
      <Footer v-if="footer === 'below'" :position="footer"/>
    </div>
    <vertical-nav
        :position="navPos"
        :collapse-nav="collapseNav"
        :open-sidebar.sync="openSidebar"
        @collapse-nav-toggle="collapseNav = !collapseNav"
        @push-page="closeSidebar"
        v-if="navPos === 'right'"/>
    <Footer v-if="footer === 'above'" :position="footer"/>
  </div>
</template>


<script>
import {detect} from 'detect-browser'
import EventBus from '@/components/event-bus'

import notification from './notification'

const browser = detect()
// @ is an alias to /src
import HorizontalNav from '@/core/horizontal-nav.vue'
import VerticalNav from '@/core/vertical-nav.vue'
import Toolbar from '@/core/toolbar.vue'
import Tabs from '@/core/tabs.vue'
import Footer from '@/core/footer.vue'
import jwt_decode from "jwt-decode"
import WSProvider from "@/WSProvider/provider/WSProvider";
import tatilService from "@/WSProvider/TatilService";

export default {
  name: 'App',
  data() {
    return {
      collapseNav: false,
      openSidebar: false,
      innerWidth: 0
    }
  },
  computed: {
    navPos() {
      if (this.innerWidth <= 768 && (this.$store.getters.navPos === 'top' || this.$store.getters.navPos === 'bottom')) {
        return 'left'
      }
      return this.$store.getters.navPos
    },
    toolbar() {
      return this.$store.getters.toolbar
    },
    footer() {
      return this.$store.getters.footer
    },
    boxed() {
      return this.$store.getters.boxed
    },
    roundedCorners() {
      return this.$store.getters.roundedCorners
    },
    viewAnimation() {
      return this.$store.getters.viewAnimation || 'none'
    },
    isLogged() {
      return this.$store.getters.isLogged
    },
    splashScreen() {
      return this.$store.getters.splashScreen
    }
  },
  methods: {
    resizeOpenNav() {
      this.innerWidth = window.innerWidth
      if (window.innerWidth <= 768) {
        this.collapseNav = false
      }
    },
    closeSidebar() {
      this.openSidebar = false
    }
  },
  components: {
    HorizontalNav,
    VerticalNav,
    Toolbar,
    Tabs,
    Footer,
  },
  created() {
    let self = this;

    EventBus.$on("sayfaKapat", function (bool, sayfa) {
      if (bool) {
        let tabs = self.$store.getters.getTabList;
        var obj = tabs.find(data => data.name === sayfa);
        if (obj !== undefined) {
          tabs.forEach((tab, index) => {
            if (tab.name === sayfa) {
              self.$store.commit('changeTabListRemove', index);
              if (tabs[index]) {
                self.$store.commit('changeActiveTabs', tabs[index].name);
              } else if (tabs[index - 1]) {
                self.$store.commit('changeActiveTabs', tabs[index - 1].name);
              }
            }
          });
        }
        self.$store.commit('changeTabsClose', true)
      }
    })

    EventBus.$on("userLogout", function (bool) {
      if (bool) {
        var tabList = [];
        self.$store.commit('changeTabList', tabList);
        self.$store.commit('changeNavVisible', false);

        //if(localStorage.getItem("userDataBGSurec")) window.localStorage.removeItem("userDataBGSurec");

        notification.Status('warning', this, 'Oturum süreniz dolmuştur. Giriş ekranına yönlendiriliyorsunuz.');

        self.$store.commit('setLogout');
        self.$router.push("/login");
        localStorage.removeItem("userDataBGSurec")

      }
    })


    if (browser.name)
      document.getElementsByTagName("html")[0].classList.add(browser.name)
  },
  mounted() {
    this.resizeOpenNav();
    window.addEventListener('resize', this.resizeOpenNav);
    tatilService.resmiTatilListele(0, 0, '').then((response) => {
      localStorage.setItem("userDataBGSurec", response.token)
      if (response.status == 200) {
        this.$store.commit('setTatilList', response.data)
      }
    })

    // window.addEventListener("keyup", function(e) {
    //     if(e.keyCode === 17){
    //         alert('CTRL');
    //     }
    // }.bind(this));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeOpenNav);
  }
}
</script>

<style lang="scss">
@import './assets/scss/_variables';
@import './assets/scss/_mixins';

::-webkit-scrollbar {
  width: 15px !important;
}

::-webkit-scrollbar-track {
  background: #d1e5ff !important;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#8bbddb, #006cae) !important;
  border-radius: 10px !important;
}

.el-form-item__error {
  margin: 3px 0 0 0;
  position: relative;
}


.display-end {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
}

.display-end-sol {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
}

.display-center {
  display: flex;
  justify-content: center !important;
}

.item-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important
}

.rotate-icon {
  i {
    transition: transform 0.5s ease-in-out;
  }

  &:hover {
    i {
      transform: rotate(360deg);
    }
  }
}

.yanmenu-title {
  // Liste yanında ekleme kısmı başlık için
  height: 50px;
  background: $text-color-accent;
  color: white;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 5px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  font-size: 18px !important
}

.dialogComponents {
  .el-dialog {
    border-radius: 8px !important;
    overflow: hidden !important;
  }

  .el-dialog__header {
    padding: 10 !important;
    margin: 0 !important;
    background: $text-color-accent;
    color: white;
    font-size: 18px !important;
    font-weight: 600;
    text-align: center !important;
  }
}

textarea::placeholder {
  font-family: Poppins !important;
  font-size: 14px !important
}

.el-form-item__error {
  // Ekrana yazdırılan form hata yazılarının renkleri
  color: $text-color-error !important;
}

.Password__strength-meter--fill {
  // yonetici tanımla - guncelle şifre güçlülüğü ölcme rengi hata renkleri ile eşleştiriliyor. Orjinal rengi turuncu
  background: $text-color-error !important;;
}

.new-line-text {
  // Yazıyı satıra sıgdırırken yazının asagı satıra yarıda kesilip atılmasını önlemek için
  white-space: pre-wrap !important;
  word-break: keep-all !important;
}

.danger-color {
  color: $text-color-accent !important
}

.el-tree-node__label {
  font-weight: 500;
}

.layout-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background: $background-color;

  .container {
    overflow: hidden;

    .header {
      height: 60px;
      box-sizing: border-box;
      padding-bottom: 15px;
      padding-top: 20px;
      /*padding-left: 30px;*/
      /*padding-right: 30px;*/
    }

    .main {
      position: relative;
      overflow: hidden;
      padding: 0 30px;
    }

    .view {
      padding: 20px;
      padding-bottom: 10px;
      padding-top: 0px;
      box-sizing: border-box;
      transition: all .4s cubic-bezier(.55, 0, .1, 1);
      backface-visibility: hidden;
      /*-webkit-perspective: 1000px;*/

    }

    .fade-top-in-out-enter {
      opacity: 0;
      transform: translate(0, 30px);
    }

    .fade-top-in-out-leave-active {
      opacity: 0;
      transform: translate(0, 30px);
    }

    .fade-top-enter {
      opacity: 0;
      transform: translate(0, 30px);
    }

    .fade-top-leave-active {
      opacity: 0;
      transform: translate(0, -30px);
    }

    .fade-bottom-in-out-enter {
      opacity: 0;
      transform: translate(0, -30px);
    }

    .fade-bottom-in-out-leave-active {
      opacity: 0;
      transform: translate(0, -30px);
    }

    .fade-bottom-enter {
      opacity: 0;
      transform: translate(0, -30px);
    }

    .fade-bottom-leave-active {
      opacity: 0;
      transform: translate(0, 30px);
    }

    .fade-left-enter {
      opacity: 0;
      transform: translate(30px, 0);
    }

    .fade-left-leave-active {
      opacity: 0;
      transform: translate(-30px, 0);
    }

    .fade-right-enter {
      opacity: 0;
      transform: translate(-30px, 0);
    }

    .fade-right-leave-active {
      opacity: 0;
      transform: translate(30px, 0);
    }

    .fade-enter {
      opacity: 0;
    }

    .fade-leave-active {
      opacity: 0;
    }


    .main-out-border {
      &--top-left, &--top-right {
        background: linear-gradient($background-color, rgba(230, 235, 241, 0));
        height: 16px;
        position: absolute;
        width: 8px;
        z-index: 1;
        top: 4px;
      }

      &--bottom-left, &--bottom-right {
        background: linear-gradient(rgba(230, 235, 241, 0), $background-color);
        height: 16px;
        position: absolute;
        width: 8px;
        z-index: 1;
        bottom: 4px;
      }

      &--top-left, &--bottom-left {
        left: 42px;

        &::after {
          content: "";
          height: 5px;
          position: absolute;
          right: -4px;
          top: -4px;
          width: 12px;
          box-shadow: 8px 0px 0px 0px $background-color inset;
          border-top-left-radius: 5px;
        }
      }

      &--top-right, &--bottom-right {
        right: 42px;

        &::after {
          content: "";
          height: 5px;
          left: -4px;
          position: absolute;
          top: -4px;
          width: 12px;
          box-shadow: -8px 0px 0px 0px $background-color inset;
          border-top-right-radius: 5px;
        }
      }

      &--bottom-left:after {
        border-radius: 0;
        border-bottom-left-radius: 5px;
      }

      &--bottom-right:after {
        border-radius: 0;
        border-bottom-right-radius: 5px;
      }

      &--bottom-left, &--bottom-right {
        &::after {
          top: initial;
          bottom: -4px;
        }
      }
    }

  }

  &.boxed {
    max-width: 1300px;
    margin: 0 auto;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }

  &.footer-above {
    padding-bottom: 40px;
    position: relative;
  }

  &.content-only {
    .container {
      .main-out-border--top-left, .main-out-border--top-right,
      .main-out-border--bottom-left, .main-out-border--bottom-right {
        display: none;
      }
    }
  }
}

html:not(.ie) {
  .layout-container {
    .container {
      /*max-width: 1920px;*/
    }
  }
}

@media (min-width: 1920px) {
  .layout-container:not(.boxed) {
    &.column {
      .container {
        /*margin: 0 auto;*/
      }
    }
  }
}

@media (max-width: 768px) {
  .layout-container {
    .container {
      /*width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;*/

      .header {
        height: 50px;
        background: #e3eaf0;
        box-shadow: 0px -20px 10px 20px rgba(0, 0, 0, 0.25);
        margin: 0;
        margin-bottom: 5px;

        .toggle-sidebar {
          box-shadow: none !important;
        }

        .search-box {
          & > .el-autocomplete {
            box-shadow: none !important;
          }
        }
      }

      .main {
        padding: 0 5px;
      }


      .view {
        //padding: 5px;
        max-width: 100%;
        padding: 15px;
      }

      .main-out-border--top-left, .main-out-border--top-right,
      .main-out-border--bottom-left, .main-out-border--bottom-right {
        display: none;
      }
    }
  }
}


.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}


/*Taylan CSS*/
html, body, div, template {
  font-size: 14px !important;
}

.layout-container .container .main {
  position: relative;
  overflow: hidden;
  padding: 0 30px;
  background: #f3f7fa;
}

.flip-card-back {
  position: absolute;
  -webkit-transform: rotateY(-180deg) translateZ(1px);
  transform: rotateY(-180deg) translateZ(1px);
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  height: auto;
  background: #fff;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.el-table .cell {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  //line-height: 35px !important;
  padding-left: 10px;
  padding-right: 10px;
}

.el-tree-node.is-current > .el-tree-node__content {
  background: #2c3437 !important;
  color: #fff;
}

.baskaTree .el-tree-node.is-current > .el-tree-node__content {
  background: #ffffff !important;
  color: #7f7878;
}

.ck-editor__editable {
  min-height: 200px;
}

.icTabs .el-tabs:not(.el-tabs--border-card) .el-tabs__item:not(.is-active) {
  color: #4a596a !important;
}

.baslikBg {
  background: #006cae;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px
}

.resimler .el-icon-zoom-in:before {
  font-family: 'Material Design Icons';
  content: "\f796";
}

.el-row:first-child {
  margin-top: 10px;
}

.el-divider__text.is-left {
  font-weight: bold;
}

.el-form-item__label {
  line-height: 30px !important;
}

.el-form-item__content {
  line-height: 30px;
  position: relative;
  font-size: 14px;
}

.main-navigation-menu.el-menu--horizontal {
  /*white-space: nowrap;*/
  overflow-y: hidden !important;
  align-items: center;
  height: 100%;
}

#transfer .el-form-item__content {
  line-height: 30px;
  position: relative;
  font-size: 14px;
}

.yanForm .card-base {
  margin-top: 15px;
  padding: 15px 15px 0 15px;
}

.yanForm .card-base:first-child {
  margin-top: 0;
}

.el-input .el-input--suffix .el-input__inner {
  width: 100px;
}

.searchClass {
  -webkit-appearance: none;
  background-color: #FFFFFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.searchClass:focus {
  border-color: #005aca;
}

.el-tabs__header.is-left {
  position: absolute;
  left: -7px;
}

.el-button label {
  cursor: pointer;
}

.language #tab-0 {
  margin-top: 30px;
  animation: 1s ease-out 0.3s fadeInRight;
}

.language #tab-1 {
  margin-top: 5px;
  animation: 1.5s ease-out 0.3s fadeInRight;
}

.language #tab-2 {
  margin-top: 5px;
  animation: 2s ease-out 0.3s fadeInRight;
}

.listPage .language #tab-tr {
  margin-top: 30px;
  animation: 1s ease-out 0.3s fadeInRight;
}

.listPage .language #tab-en {
  margin-top: 5px;
  animation: 1.5s ease-out 0.3s fadeInRight;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: unset !important;
}

.language .el-tabs__item {
  background: rgba(93, 93, 93, 0.23);
  border-radius: 5px 0 0 5px;
  position: relative;
  right: -8px;
  transition: all 1s;
}

.language .el-tabs__item i {
  transition: transform 1s;
}

.language .el-tabs__item:hover {
  & i {
    transform: rotateY(360deg);
  }
}

.language .el-tabs__item.is-active {
  background: #546278;
  color: #fff;
  position: relative;
  right: 0;
}

/*Tabs*/
.el-tabs__nav-wrap {
  overflow: hidden;
  /*margin-bottom: 3px;*/
  position: relative;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background: linear-gradient(to bottom, #fff 80%, #f2f7fa);
  border-bottom: none;
  border-radius: 0 0 0 0;
}

.el-tabs__header {
  padding: 0;
  position: relative;
  /*top: -3px;*/
  top: 0;
  margin: 0 0 15px;
}

.el-menu-item i {
  padding: 5px 0;
}

.el-tabs__item:first-child {
  padding: 0 20px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  border-bottom-left-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #303133;
  position: relative;
  left: 1px;
}

.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
  border-radius: 4px 4px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.el-tabs:not(.el-tabs--border-card) .el-tabs__item:not(.is-active) {
  color: #fff;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 0;
  color: #303133;
}

.shadowBox {
  box-shadow: 0 0 10px #000000b8;
}

.successIconClass:before {
  content: "\e720";
  position: absolute;
  right: 2px;
  top: 2px;
  width: 30px;
  height: 30px;
  background: white;
  z-index: 100;
  color: #00c000;
  font-family: element-icons !important;
  font-weight: bold;
  font-size: 17px;
  border-radius: 50px;
}

/*Tabs*/
.language .el-tabs__active-bar.is-left {
  display: none;
}

.language .el-tabs__nav-wrap.is-left::after {
  display: none;
}

.language .el-tabs__item.is-left {
  text-align: right;
  margin-bottom: 5px;
}

.language .tr.el-tabs__item.is-left {
  text-align: right;
  background: #ec205f;
  border-radius: 2px;
}

.language .el-tabs__header.is-left {
  margin-right: 0 !important;
}

.language .el-tabs__item {
  padding: 0 10px;
}


.el-table__expanded-cell {
  background-color: #f5f5f5;
}

.layout-container .container .main {
  background: #f3f7fa;
}

//.el-tabs__nav-scroll {
//  margin-right: 480px;
//}

.main-navigation-menu.el-menu--horizontal ul li i {
  color: #4e5867;
}


.listPage .el-tabs__nav {
  margin-top: 50px;
}

.daireclass {
  background-color: #64b1e2;
  height: 15px;
  width: 15px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}

.daireclassDash {
  background-color: #81AA29;
  height: 50px;
  width: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}

.daireclassDash i {
  font-size: 28px;
  font-style: normal;
  color: white;
  position: relative;
  left: -2px;
  top: 5px
}

.daireclass1 {
  background-color: #616367;
  height: 15px;
  width: 15px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}

.daireclass2 {
  background-color: #f7ba2a;
  height: 15px;
  width: 15px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}

.daireclass3 {
  background-color: #ec205f;
  height: 15px;
  width: 15px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}

.daireclass4 {
  background-color: #2c3437;
  height: 15px;
  width: 15px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}

.el-button--info {
  color: #FFFFFF;
  background-color: #64b1e2;
  border-color: #64b1e2;
}

.el-button--info:hover {
  color: #FFFFFF;
  background-color: #64b1e2;
  border-color: #64b1e2;
}

.buttonEdit {
  color: #2c3437;
  margin: 0px 5px 0px 0px !important;
  position: relative;
  bottom: 5px;
}

.buttonEdit:hover {
  color: #81AA29;
}

.buttonUndo {
  color: #ec205f;
  margin: 0 !important;
}

.buttonUndo:hover {
  color: rgba(236, 32, 95, 0.64);
}

.buttonDel {
  color: #ec205f;
  margin: 0 !important;
}

.buttonDel:hover {
  color: rgba(236, 32, 95, 0.64);
}

.buttonDetay {
  color: rgb(100, 177, 226);
  margin: 0 !important;
}

.buttonDetay:hover {
  color: rgb(100, 177, 226);
}

.buttonGoruntule {
  color: #606266;
  margin: 0 !important;
}

.buttonGoruntule:hover {
  color: #606266;
}

.buttonAktif {
  color: #2c3437;
  margin: 0 !important;
}

.buttonAktif:hover {
  color: #2c3437;
}

.buttonInfo {
  position: relative;
  bottom: 6px;
  margin-right: 5px;
}

.durdurBtn {
  background: #99abb4;
  border: 1px solid #99abb4;
  color: white;
}

.durdurBtn:hover {
  border: 1px solid #A2B3BC;
  color: white;
  background: #A2B3BC;
}

.mainDiv {
  width: 100%;

  .label-switch-box {
    display: block;
    clear: both;
    width: 100%;
    text-align: right;
    margin-bottom: 20px;
  }

  .col-p {
    margin: -5px 0;
    padding: 0 15px;
    box-sizing: border-box;
  }

  .select-wide {
    width: 100%;
  }
}

.el-button {
  font-size: 16px;
}

.el-form-item {
  font-weight: bold;
}

.mdi-information-outline::before {
  content: "\F2FD";
  color: #26c6da;
}

.onayBtn {
  margin-left: 17px;
  margin-bottom: 10px;
  margin-right: 15px;
}

.el-collapse-item {
  margin-bottom: 10px;
  border-radius: 5px 5px 0 0;
}

.el-collapse {
  border-top: unset;
  border-bottom: unset;
}

.el-collapse-item__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 15px;
  cursor: move;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  line-height: 48px;
  border-radius: 5px 5px 0 0;
  background-color: #FFFFFF;
  /*cursor: pointer;*/
  color: #303133;
  border-bottom: 1px solid #EBEEF5;
  font-size: 13px;
  font-weight: bold;
  -webkit-transition: border-bottom-color .3s;
  transition: border-bottom-color .3s;
  outline: none;
}

.el-tabs__content {
  /*padding: 0px 0px 0 38px;*/
  overflow: hidden;
  position: relative;
}

.el-tabs:not(.el-tabs--border-card) .el-tabs__item:not(.is-active) {
  color: #fff;
}

.yoneticiDetay.el-tabs:not(.el-tabs--border-card) .el-tabs__item:not(.is-active) {
  color: currentColor;
}

.urunBaglanti.el-tabs:not(.el-tabs--border-card) .el-tabs__item:not(.is-active) {
  color: currentColor;
}

.el-collapse-item .el-row {
  position: relative;
  top: 15px;
}

.el-collapse-item__wrap {
  border-radius: 0 0 5px 5px;
}

.el-radio .is-checked .el-radio__label img {
  box-shadow: 5px 5px 10px 0px black;
}

.el-collapse-item__header.is-active {
  border-bottom-color: #f0f0f0;
}

.wizard__arrow {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid #aebac4;
  top: 85px;
  border-top-right-radius: 5px;
  background-color: #f1f1f1 !important;
  border-left: none;
  border-bottom: none;
  transform: rotate(-45deg);
  z-index: 2;
  -webkit-transition: left 0.3s;
  -o-transition: left 0.3s;
  transition: left 0.3s;
}

.wizard__body__step {
  padding: 16px;
  background: #f1f1f1;
}

.wizardd .language .el-tabs__header.is-left {
  margin-right: 32px;
  position: absolute;
  left: -40px;
}

.el-tabs__new-tab {
  float: right;
  display: none;
  border: 1px solid #d3dce6;
  height: 18px;
  width: 18px;
  line-height: 18px;
  margin: 12px 0 9px 10px;
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  color: #d3dce6;
  cursor: pointer;
  -webkit-transition: all .15s;
  transition: all .15s;
}

/*Ürün Tanımlama*/
.el-transfer-panel__item .el-checkbox__input {
  position: absolute;
  top: 8px;
  display: none;
}

.el-transfer-panel__list.is-filterable {
  padding-top: 0;
}

.el-transfer__buttons {
  display: table;
  vertical-align: middle;
  padding: 0 5px;
  /*width: 18%;*/
  text-align: center;
}

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px;
}

.el-transfer-panel {
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  overflow: hidden;
  background: #FFFFFF;
  display: block;
  vertical-align: middle;
  width: 200px;
  height: 300px;
  max-height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.el-transfer-panel.first-child {
  margin-left: 20px;
}

.el-transfer__buttons .el-button--primary {
  margin: 5px;
  padding: 5px;
  display: table-cell;
  vertical-align: middle;
  text-align: center
}

.limitAsim {
  color: #ec205f !important;
}

/*Ürün Tanımlama*/
/*Map*/
.svg-turkiye-haritasi {
  max-width: 1140px;
  margin: 0 auto;
  text-align: center;
}

.svg-turkiye-haritasi svg {
  width: 100%;
  height: auto;
}

.il-isimleri {
  position: absolute;
  z-index: 2;
}

.il-isimleri div {
  font-family: 'Poppins';
  display: inline-block;
  font-size: 18px !important;
  background: #909399;
  color: #ffffff;
  padding: 8px 16px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#svg-turkiye-haritasi path {
  cursor: pointer;
  fill: #1A2226;
}

#svg-turkiye-haritasi path:hover {
  fill: #2c3437;
}

#guney-kibris {
  pointer-events: none;
}

/*Map*/
.el-carousel__mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: invert(1) !important;
  background-color: #FFFFFF;
  opacity: 0.24;
  -webkit-transition: .2s;
  transition: .2s;
}

.el-carousel__indicators--outside {
  bottom: 26px;
  text-align: center;
  position: static;
  -webkit-transform: none;
  transform: none;
  display: none !important;
}

@keyframes fadeInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 768px) {
  .main-navigation-menu .el-submenu__title i, .main-navigation-menu .el-menu-item:not(.is-active) i, .main-navigation-menu a i {
    color: #4c5868;
    padding: 5px 0;
  }
  .main-navigation-menu .el-submenu__title, .main-navigation-menu .el-menu-item:not(.is-active), .main-navigation-menu a {
    color: #909399;
  }
  .main-navigation-menu:not(.el-menu--collapse) .el-submenu.is-opened::after {
    display: none;
  }
  .listPage .el-tabs--left {
    float: left;
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .yanForm {
    margin-top: 10px;
  }
  .el-transfer-panel {
    border: 1px solid #EBEEF5;
    border-radius: 4px;
    overflow: hidden;
    background: #FFFFFF;
    display: inline-block;
    vertical-align: middle;
    width: 250px;
    height: 400px;
    max-height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }
  .el-transfer__buttons {
    transform: rotate(90deg);
    display: table;
    vertical-align: text-bottom;
    padding: 0 100px;
  }

}

.el-tree-node__expand-icon {
  color: #2c3437;
}

.el-tree-node__expand-icon.is-leaf {
  color: #00000059;
  cursor: default;
}

/*Taylan CSS*/

.language .el-tabs__header.is-left {
  position: relative;
  left: -1px;
}

.baglanti .el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  display: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  line-height: 146px;
  vertical-align: top;
}

.urunDurum .el-form-item__label {
  text-align: left !important;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 170px !important;
}

.baglantiDialog .el-dialog__body {
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  display: contents !important;
}

.baglantiBtn {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 150px;
  height: 160px;
  cursor: pointer;
  line-height: 146px;
  margin: 0 8px 8px 0;
}

.baglanti {
  .el-upload-list--picture-card .el-upload-list__item {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 150px !important;
    height: 160px !important;
    margin: 0 8px 8px 0;
    display: inline-block;
  }

  .imgNameDiv {
    margin: 0 10px;
    text-align: center;
    font-size: 10px !important;
    vertical-align: middle;
  }
}

.baglantiBtn i {
  font-size: 28px;
  color: #8c939d;
}

.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 100%;
  height: 100%;
  min-height: 111px !important;
}

.el-table th, .el-table td {
  padding: 0 !important;
  min-width: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}

.yoneticiDetay .el-tabs__nav-scroll {
  margin-left: 20px;
}

.urunBaglanti .el-tabs__nav-scroll {
  margin-left: 20px;
}

.urunBaglanti .el-tabs__nav-wrap {
  overflow: hidden;
  background: cadetblue;
  margin-bottom: 3px;
  height: 50px;
  position: relative;
}

.box1, .box2, .box3, .box4, .box5 {
  background: transparent;
  position: relative;
  vertical-align: middle;
  color: #2c3437;
  display: block;
  height: 30px;
  line-height: 20px;
  transition: 0.5s;
  padding: 0 15px;
  cursor: pointer;
  border: 1px solid #2c3437;
  -webkit-transition: 0.5s;
  margin-bottom: 15px;
}

/* first button start*/
.box1:hover {
  border: 1px solid rgba(00, 160, 80, 0);
  color: #ffffff;
}

.box1::before, .box1::after {
  width: 100%;
  height: 100%;
  z-index: 3;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  -webkit-transform: scale(0);
  transition: 0.5s;
}

.bar1::before {
  border-bottom: 2px solid #ffffff;
  border-left: 2px solid white;
  -webkit-transform-origin: 100% 0%; // odakle pocinje transformacija
}

.bar1::after {
  border-top: 2px solid white;
  border-right: 2px solid white;
  -webkit-transform-origin: 0% 100%;
}


.box1:hover::after, .box1:hover::before {
  -webkit-transform: scale(1);
}

.el-submenu .mdi, .el-menu-item .mdi {
  justify-content: center;
  margin: 0;
}

.main-navigation-menu:not(.el-menu--collapse):not(.el-menu--horizontal) .el-menu-item:hover::before, .main-navigation-menu:not(.el-menu--collapse):not(.el-menu--horizontal) .el-submenu:hover::before {
  width: 0;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

.yaziOrtala {
  text-align: center !important;
}

.cop {
  color: #ec205f;
  position: relative;
  float: right;
  right: 15px;
  bottom: 10px;
  font-size: 20px;
}
</style>

<style lang="scss">

.fatihButon {
  display: block;
  background-color: #1e75ba;
  width: 90px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: 3px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
  outline: none;
  border: none;

  span.yazi,
  .icon {
    display: block;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
  }

  span.yazi {
    width: 70%;
    line-height: inherit;
    font-size: 14px;
    left: 0;
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);

    &:after {
      content: '';
      background-color: #17588c;
      width: 1px;
      height: 70%;
      position: absolute;
      top: 15%;
      right: 0;
      opacity: .5;
    }
  }

  .icon {
    width: 30%;
    right: 0;
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);

    i {
      font-size: 20px;
      vertical-align: middle;
      transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
    }
  }

  &:hover {
    opacity: 0.9;

    span.yazi {
      left: -70%;
      opacity: 0;
    }

    .icon {
      width: 100%;
    }
  }

  &:active {
    opacity: 1;
  }

  &.kucuk {
    height: 30px;
    line-height: 30px;

    .icon {
      i {
        font-size: 18px;
      }
    }

    span.yazi {
      font-size: 14px;
    }
  }

  &.disCizgi {
    border: 1px solid #1e75ba;
    background-color: transparent;
    color: #1e75ba;
  }
}


.islemler {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: right;

  li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
    position: relative;
    text-align: center;

    &:not(:last-of-type) {
      margin-right: 10px;
      padding-right: 10px;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 1px;
        /*background: linear-gradient(to bottom,transparent,#969696,transparent);*/
        background: #969696;
        opacity: .8;
      }
    }

    i {
      font-size: 16px;
      margin-right: 3px;
      width: 22px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &.duzenle {
      color: #f7ba2a;
    }

    &.musteri {
      color: #3887BE;
    }

    &.incele {
      color: #13ce66;
    }

    &.sil {
      color: #ec205f;
    }

    &.detay {
      color: #006cae;
    }

    &.goruntule {
      color: #606266;
    }

    &.bildir {
      color: #ec205f;
    }

    &.aciklama {
      color: #1087d6;
    }

    &.aktif {
      color: #006cae;
    }

    &.yesil {
      color: rgba(132, 205, 94, 0.75);
    }

    &.yesil2 {
      color: rgb(73, 170, 21);
    }

    &.kahverengi {
      color: rgba(238, 177, 94, 0.75)
    }

    &.sari {
      color: rgba(255, 208, 88, 0.75);
    }

    &.turuncu {
      color: rgba(255, 139, 63, 0.75);
    }

    &.pembe {
      color: rgba(251, 134, 131, 0.75);
    }
  }
}

.islemler2 {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: left;

  li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
    position: relative;
    text-align: center;

    &:not(:last-of-type) {
      margin-right: 10px;
      padding-right: 10px;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 1px;
        /*background: linear-gradient(to bottom,transparent,#969696,transparent);*/
        background: #969696;
        opacity: .8;
      }
    }

    i {
      font-size: 16px;
      margin-right: 3px;
      width: 22px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &.duzenle {
      color: #f7ba2a;
    }

    &.incele {
      color: #13ce66;
    }

    &.sil {
      color: #ec205f;
    }

    &.detay {
      color: #64b1e2;
    }

    &.goruntule {
      color: #606266;
    }

    &.bildir {
      color: #ec205f;
    }

    &.aciklama {
      color: #1087d6;
    }

    &.aktif {
      color: #006cae;
    }

    &.yesil {
      color: #84cd5e;
    }

    &.yesil2 {
      color: rgb(121, 206, 76);
    }

    &.kahverengi {
      color: #eeb15e;
    }

    &.sari {
      color: #ffd058;
    }

    &.turuncu {
      color: #ff8b3f;
    }

    &.pembe {
      color: #fb8683;
    }
  }
}


.baslikBg {
  position: relative;

  .tanimlamaButon {
    position: absolute;
    right: 10px;
    top: 50%;

    transform: translateY(-50%);

    button {

      color: #ffffff;

      i {
        font-weight: bold !important;
      }
    }
  }
}

/* KARANLIK MOD */


.el-radio-button--small {
  .el-radio-button__inner {
    padding: 5px 10px;
  }
}

.el-radio-button__inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

body.karanlik {
  color: #f9f9f9;

  .el-menu-item {
    border-right-color: #555555;

    span {
      color: #f9f9f9;
    }

    i {
      color: #d9d9d9 !important;
    }

    &:hover {
      background-color: #555555 !important;
    }
  }

  .peity {
    polyline {
      stroke: #f9f9f9;
    }

    polygon {
      fill: #f9f9f9;
    }

    rect {
      fill: #f9f9f9;
    }
  }

  .widget {
    .widget-title {
      color: #dddddd !important;
    }

    h1,
    h2 {
      color: #ffffff !important;
    }

    .widget-icon-box {
      background: rgba(100, 100, 100, .6) !important;

      i {
        color: #f9f9f9 !important;
      }
    }
  }

  .layout-container .container .header {
    background: #333333;
  }

  .vertical-nav .sidebar {
    background: #555555;

    .main-navigation-menu .el-submenu__title, .main-navigation-menu .el-menu-item:not(.is-active) {
      color: #f9f9f9;

      i {
        color: #f9f9f9;
      }
    }

    .main-navigation-menu:not(.el-menu--collapse) .el-submenu.is-opened::after {
      background: #f9f9f9;
    }
  }

  .toolbar .toggle-sidebar {
    background: #333333;
    color: #ffffff;
  }

  .el-table th.is-leaf, .el-table td {
    border-bottom-color: #969696;
  }

  .el-date-editor {
    .el-range__icon {
      color: #f9f9f9;
    }

    .el-range-input {
      background: #555555;
      color: #f9f9f9;
    }
  }

  .el-date-table th {
    color: #f9f9f9;
  }

  .el-date-table td span {
    color: #d9d9d9;
  }

  .el-date-table td.today span {
    color: #ffffff;
  }

  .el-date-table td.in-range div {
    background: #969696;
  }

  .el-date-table td.start-date span, .el-date-table td.end-date span {
    background: #444444;
  }

  .el-loading-mask {
    background: #696969;

    .el-loading-spinner .path {
      stroke: #f9f9f9;
    }
  }

  .el-picker-panel__icon-btn {
    color: #f9f9f9;
  }

  .el-table .cell {
    color: #f9f9f9;
  }

  .el-button--primary {
    background: #696969;
    border-color: #696969;
  }

  .toolbar {
    .avatar {
      border-color: #969696;
    }

    .username {
      text-shadow: none;
    }

    .fullscreen-button {
      color: #f9f9f9;
      text-shadow: none;

      &:hover {
        color: #ffffff;
      }
    }

    .ayarlarButon {
      color: #f9f9f9;
      text-shadow: none;

      &:hover {
        color: #ffffff;
      }
    }
  }

  .el-dialog {
    background: #555555;

    div, span {
      color: #f9f9f9;
    }
  }

  .el-radio-button__inner {
    background: #696969;
    border-color: #969696 !important;

    i, span {
      color: #f9f9f9 !important;
    }
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #444444 !important;
    border-color: #a9a9a9 !important;
    color: #f9f9f9 !important;

    i, span {
      color: #f9f9f9 !important;
    }
  }

  .el-pagination.is-background .btn-prev:disabled, .el-pagination.is-background .btn-next:disabled {
    border-color: #696969;
    background: #555555;
  }

  .el-pagination__jump {
    color: #f9f9f9;
  }

  .el-select-dropdown {
    border-color: #696969;
    background: #555555;

    .el-select-dropdown__item {
      color: #f9f9f9;

      &.hover, &:hover {
        background: #696969;
        color: #f9f9f9;
      }
    }

    .popper__arrow::after {
      border-bottom-color: #555555;
    }
  }

  .el-table__empty-block {
    background: #555555;

    .el-table__empty-text {
      color: #f9f9f9;
    }
  }

  .el-dialog.user-dialog .avatar-box img {
    border-radius: 50%;

  }

  .el-dialog.user-dialog .avatar-box {
    border-radius: 50%;
    overflow: hidden !important;

  }

  .account-tab .card-outline {
    border-color: #f9f9f9;

    .title {
      background: #696969;
      color: #f9f9f9;
    }
  }

  .box1, .box2, .box3, .box4, .box5 {
    border-color: #f9f9f9;
    color: #f9f9f9;

    &:hover {
      background: #696969;
    }
  }

  .el-progress-bar__outer {
    background: #696969;

    .el-progress-bar__inner {
      background: #f9f9f9;
    }
  }

  .el-progress__text,
  .el-progress__text i {
    color: #f9f9f9;
  }

  .icon-box {
    .mdi {
      color: #f9f9f9 !important;
    }
  }

  .daireclassDash {
    background: #696969;
  }

  table.styled thead {
    background-color: #595959 !important;

    tr th {
      color: #cccccc;
    }
  }

  .ps-container {
    .el-button {
      color: #f9f9f9 !important;
    }
  }

  .box-logo .app-name img {
    filter: brightness(0) invert(1);
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #444444;
  }

  .el-table {

    .cell {
      .el-icon-picture {
        color: #f9f9f9 !important;
      }

      .el-tag {
        border-color: #a9a9a9;
        background: #a9a9a9aa;
        color: #f9f9f9;
      }
    }

    th > .cell {
      background: #444444;
      color: #f9f9f9;
    }

    tr {
      background: #555555;
    }

  }

  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #666666;
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: #888888;
  }

  .el-table--border th, .el-table--border td {
    border-color: #a9a9a9;
    color: #f9f9f9;
  }

  .el-form-item__content,
  .el-form-item__label {
    color: #f9f9f9;
  }

  .el-checkbox {
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #969696;
      border-color: #969696;
    }

    .el-checkbox__label {
      color: #f9f9f9;
    }

    &.is-bordered.is-checked {
      border-color: #969696;
    }
  }

  .el-collapse-item__header {
    background: #333333;
    color: #f9f9f9;
    border-bottom-color: #696969;
  }

  .el-collapse-item__wrap {
    background: #333333;
    color: #f9f9f9;
    border-bottom-color: #696969;
  }

  .el-textarea__inner,
  .el-input__inner {
    border-color: #696969;
    background: #555555;
    color: #f9f9f9;
  }

  .el-picker-panel__footer,
  .el-picker-panel {
    background: #555555;
    color: #f9f9f9;
    border-color: #696969;
  }

  .ck.ck-toolbar {
    background: #555555;
  }

  .ck.ck-button:not(.ck-disabled):active, a.ck.ck-button:not(.ck-disabled):active,
  .ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover,
  .ck.ck-toolbar > .ck-toolbar__items > *:hover {
    background: #696969 !important;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    background: #696969;

    &:focus {
      border-color: #c4c4c4;
    }
  }

  .el-upload--picture-card {
    background: #696969;
    border-color: #969696;

    &:hover {
      border-color: #cccccc;
    }

    i {
      color: #dddddd;
    }
  }

  .el-tabs--card > .el-tabs__header {
    background: #757575;
    border-bottom-color: #333333;

    .el-tabs__item {
      background: #757575;
      border-bottom-color: #444444;
      color: #f9f9f9;
      border-left: #969696;

      &:focus.is-active.is-focus:not(:active) {
        box-shadow: 0 0 2px 2px rgba(255, 255, 255, .4) inset;
      }

      &.is-active {
        background: #444444;
      }
    }
  }


  /* ÖNEMLİ */
  .card-base {
    background: #333333;
  }

  .baslikBg {
    background: #757575;
  }

  .bg-white {
    background: #333333 !important;
  }

  .scrollable::-webkit-scrollbar-thumb {
    background: #888888;

    &:hover {
      background: #969696;
    }
  }


  .footer {
    background: #555555;

    .hakanbt-logo {
      &:before {
        border-color: transparent transparent #555555 transparent;
      }

      &:after {
        border-color: transparent transparent #ffffff transparent;
      }

      .hakanbt {
        filter: brightness(0) invert(1);
      }
    }

    div > .hbtIsim {
      color: #ffffff !important;
    }
  }

  .layout-container {
    background-color: #444444;

    .container .main {
      background-color: #444444;
    }
  }

  .el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title {
    background: #333333;
    color: #f9f9f9;

    &:hover {
      background: #555555 !important;
      background-color: #555555 !important;
      color: #f9f9f9;
    }
  }

  .horizontal-nav {
    background: #333333;

    .main-navigation-menu.el-menu--horizontal > .el-submenu {
      border-right-color: #555555;

      .el-submenu__title {
        color: #f9f9f9;

        i {
          color: #d9d9d9;
        }
      }

      div.el-submenu__title {
        background: #333333;
        color: #f9f9f9;

        &:hover {
          background: #555555 !important;
          background-color: #555555 !important;
          color: #f9f9f9;
        }
      }

    }

  }

  .main-navigation-submenu .el-menu {
    background: #333333 !important;

    .el-menu-item {
      background: #333333;
      color: #f9f9f9;

      &:hover {
        background: #555555 !important;
        background-color: #555555 !important;
      }
    }
  }


  .el-dropdown-menu {
    background: #333333;
    border-color: #555555;

    .el-dropdown-menu__item {
      color: #f9f9f9;

      &:hover {
        background: #555555;
        color: #f9f9f9;
      }

    }

    .el-dropdown-menu__item--divided {
      border-top-color: #696969;

      &:before {
        background: #696969;
      }
    }

    .popper__arrow {
      border-top-color: #696969;

      &:after {
        border-bottom-color: #696969;
      }
    }
  }

  .el-drawer {
    background: #444444;

    .el-drawer__header {
      color: #f9f9f9;
    }
  }

  .Password__strength-meter {
    background: #969696;

    &:after, &:before {
      border-color: #cccccc;
    }
  }


  .fatihButon {
    background-color: #f9f9f9;
    color: #444444;

    span.yazi {
      &:after {
        background-color: #969696;
      }
    }

    &.disCizgi {
      border: 1px solid #f9f9f9;
      background-color: transparent;
      color: #f9f9f9;
    }
  }
}

.el-drawer {
  .el-drawer__header {
    font-size: 20px !important;
  }
}

.el-dropdown-menu {
  .el-dropdown-menu__item--divided:before {
    height: 1px;
  }
}

.formPadding {
  padding: 50px 30px
}

@media (max-width: 480px) {

  .layout-container .container .main {
    padding: 0 5px !important;
  }
  .formPadding {
    padding: 25px 15px
  }
  .el-drawer.ltr, .el-drawer.rtl {
    width: 75% !important;
  }
}

.mdi-64px.mdi-set, .mdi-64px.mdi:before {
  font-size: 64px;
}

</style>