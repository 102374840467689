const Personel = resolve => {
    require.ensure(['../views/apps/personel/Personel'], () => {
        resolve(require('../views/apps/personel/Personel'))
    })
};
const Gorevlerim = resolve => {
    require.ensure(['../views/apps/personel/PersonelEkrani'], () => {
        resolve(require('../views/apps/personel/PersonelEkrani'))
    })
};



import layouts from '../layout'
import Vue from 'vue'
Vue.component('personel', Gorevlerim);


export default {
    path: '/personel',
    name: 'personel',
    component: Personel,
    meta: {
        layout: layouts.navLeft
    },
    children: [{
        path: '/gorevlerim',
        name: 'personel',
        component: Gorevlerim,
        meta: {
            auth: true,
            layout: layouts.navLeft,
            searchable: true,
            tags: ['app']
        }
    }],
}
