const Tatil = resolve => {
    require.ensure(['../views/apps/tatil/Tatil'], () => {
        resolve(require('../views/apps/tatil/Tatil'))
    })
};
const TatilTanimla = resolve => {
    require.ensure(['../views/apps/tatil/TatilTanimla'], () => {
        resolve(require('../views/apps/tatil/TatilTanimla'))
    })
};
const TatilListesi = resolve => {
    require.ensure(['../views/apps/tatil/TatilListesi'], () => {
        resolve(require('../views/apps/tatil/TatilListesi'))
    })
};
const TatilGuncelle = resolve => {
    require.ensure(['../views/apps/tatil/TatilGuncelle'], () => {
        resolve(require('../views/apps/tatil/TatilGuncelle'))
    })
};


import layouts from '../layout'
import Vue from 'vue'
Vue.component('tatillistesi', TatilListesi);
Vue.component('tatiltanimla', TatilTanimla);
Vue.component('tatilguncelle', TatilGuncelle);


export default {
    path: '/tatil',
    name: 'tatil',
    component: Tatil,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [{
        path: 'liste',
        name: 'tatilListesi',
        component: TatilListesi,
        meta: {
            auth: true,
            layout: layouts.navLeft,
            searchable: true,
            tags: ['app']
        }
    }],

}