const Rapor = resolve => {
    require.ensure(['../views/apps/rapor/Rapor'], () => {
        resolve(require('../views/apps/rapor/Rapor'))
    })
};

const GenelRapor = resolve => {
    require.ensure(['../views/apps/rapor/GenelRapor'], () => {
        resolve(require('../views/apps/rapor/GenelRapor'))
    })
};
const ProjeRapor = resolve => {
    require.ensure(['../views/apps/rapor/ProjeRapor'], () => {
        resolve(require('../views/apps/rapor/ProjeRapor'))
    })
};
const SurecRapor = resolve => {
    require.ensure(['../views/apps/rapor/SurecRapor'], () => {
        resolve(require('../views/apps/rapor/SurecRapor'))
    })
};
const MusteriRapor = resolve => {
    require.ensure(['../views/apps/rapor/MusteriRapor'], () => {
        resolve(require('../views/apps/rapor/MusteriRapor'))
    })
};
const ProjeListesi = resolve => {
    require.ensure(['../views/apps/proje/ProjeListesi'], () => {
        resolve(require('../views/apps/proje/ProjeListesi'))
    })
};

import Vue from 'vue'
Vue.component('genelrapor', GenelRapor);
Vue.component('projerapor', ProjeRapor);
Vue.component('surecrapor', SurecRapor);
Vue.component('musterirapor', MusteriRapor);
Vue.component('projelistesi', ProjeListesi);

import layouts from '../layout'

export default {
    path: '/rapor',
    name: 'rapor',
    component: Rapor,
    meta: {
        layout: layouts.navLeft
    },
}
