import JQuery from "jquery";
import store from "../../store";
let $ = JQuery;

//const url = new URL("http://192.168.1.108/bgsurec-api/"); // Enes Cemcir
// const url = new URL("http://89.252.181.10/bgsurec-api/"); //10. sunucu
// const url = new URL('http:localhost/bgsurec-api/');
// const url = new URL('http://192.168.1.251/bgsurec-api/'); // yeni sunucu
// const url = new URL("http://192.168.1.106/bgsurec-api/")
const url = new URL("https://flowpro.bggrupinsaat.com.tr/bgsurec-api/");
// const url = data.POST_URL
//const url = new URL("http://localhost/bgsurec-api/");

var WSProvider = {
  apiPath: url,
  path: url.origin + "/bgsurec-api/",
  documentPath: url + "../../userFiles/upload/",
  imagePath: url + "../../userFiles/upload/", // Resimler bazı projelerde buyuk - orta ve kucuk olarak ölceklendiriliyor. Küçük projelerde tek boyut olabiliyor.
  imagePathBuyuk: url + "../../userFiles/upload/buyuk/",
  imagePathOrta: url + "../../userFiles/upload/orta/",
  imagePathKucuk: url + "../../userFiles/upload/kucuk/",
  ozlukPath: url + "belge/personel/ozluk/",
  ozlukResim: url + "belge/personel/resim/",
  personelResim: url + "belge/personel/resim/",

  ajaxPost: function (serviceName, jsonParams) {
    var form_data = new FormData(); // Gelen değerleri form data objesine cevirme. Daha cok array gönderebilmek için yazıldı! Önceden arrayler stringe cevrilip gönderiliyormuş :))
    for (var key in jsonParams) {
      if (Array.isArray(jsonParams[key])) {
        // Gelen deger array mi?
        if (jsonParams[key].length > 0) {
          // Gelen array dolu mu?
          for (var i = 0; i < jsonParams[key].length; i++) {
            form_data.append(key + "[]", jsonParams[key][i]); // Formdata'ya array olan elemanları ekleme
          }
        } else {
          form_data.append(key + "[]", "");
        }
      }
      // ------------- updateList KISMI BAŞLANGIÇ
      else if (
        typeof jsonParams[key] === "object" &&
        !Array.isArray(jsonParams[key]) &&
        jsonParams[key] !== null &&
        key == "updateList"
      ) {
        // gelen değer obje mi ?
        for (var k in jsonParams[key]) {
          // obje ise keylerde ve valularda gez
          // gelen objenin içindeki array DEĞİLSE ise onu formatla gönderirken updateList: {durum : 1} şekilde geldiyse gönderirken bu updateList[durum] : 1 şeklinde olmalı
          // gelen objenin içindeki array İSE onu formatla gönderirken updateList: {durum : [1, 2 , 3]} şekilde geldiyse gönderirken bu updateList[durum][] : 1, updateList[durum][] : 2, updateList[durum][] : 3, şeklinde olmalı
          if (Array.isArray(jsonParams[key][k])) {
            // Gelen deger array mi?
            if (jsonParams[key][k].length > 0) {
              // Gelen array dolu mu?
              for (var i = 0; i < jsonParams[key][k].length; i++) {
                form_data.append(
                  key + "[" + k + "]" + "[]",
                  jsonParams[key][k][i]
                ); // Formdata'ya array olan elemanları ekleme
              }
            } else {
              form_data.append(key + "[" + k + "]" + "[]", "");
            }
          } else form_data.append(key + "[" + k + "]", jsonParams[key][k]);
        }
      }
      // ------------- updateList KISMI BİTİŞ
      else form_data.append(key, jsonParams[key]);
    }

    var service = new Promise(function (resolve, reject) {
      return $.ajax({
        type: "POST",
        url: url + serviceName + ".php",
        datatype: "json",
        processData: false, // Important!
        contentType: false,
        data: form_data,
        success: function (result) {
          if (result) {
            const storedYetkiListesi = store.getters.getYetkiListesi;
            if (Array.isArray(result.yoneticiYetkileri)) {
              const mappedYetkiListesi = result.yoneticiYetkileri.map(
                (item) => item.yetkiKodu
              );

              if (!areArraysIdentical(storedYetkiListesi, mappedYetkiListesi)) {
                store.commit("setYetkiListesi", mappedYetkiListesi);
              }
            }

            resolve(result);
          } else {
            resolve();
          }
        },
        error: function (request) {
          if (request) {
            reject(request);
          } else {
            reject();
          }
        },
      });
    });
    return service;
  },
};

function areArraysIdentical(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
}

export default WSProvider;
