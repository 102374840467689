
<template>
	<div class="kayipBg flex align-center justify-center">
		<div class="disDivAyar">
			<div class="ortayaCek">
				<div class="yaziYaz">
					404 - Not Found
				</div>
			</div>
			<a href="./" class="anaSayfaGit">
				<i class="mdi mdi-home"></i> Anasayfa
			</a>
		</div>
	</div>
</template>

<script>
	import JQuery from 'jquery'
	let $ = JQuery;
	export default {
		name: 'NotFound',

		mounted() {
			setTimeout(function () {
				$('.horizontal-nav').hide()
				$('.main.box.grow.flex').css({'padding':0})
			},50)
		}
	}
</script>

<style lang="scss" scoped>

	.kayipBg {
		background-image: url("../../../src/assets/images/login3.jpg");
		background-position: center;
		background-repeat: no-repeat;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background: #333333;
			opacity: .2;
		}

		.disDivAyar {
			position: relative;
			z-index: 99;
			width: 100%;
			text-align: center;
			.ortayaCek {
				width: 100%;
				text-align: center;
				display: flex;
				justify-content: center;
				.yaziYaz {
					color: #ffffff;
					width: 15ch;
					animation: typing 2s steps(15), blink .5s step-end infinite alternate;
					white-space: nowrap;
					overflow: hidden;
					border-right: 3px solid;
					font-family: monospace;
					font-size: 70px !important;
				}
			}
			.anaSayfaGit {
				text-decoration: none;
				background: transparent;
				border: 3px solid #ffffff;
				color: #ffffff;
				padding: 15px 40px;
				text-align: center;
				font-size: 36px;
				margin-top: 15px;
				display: inline-block;
			}
		}



	}


	@keyframes typing {
		from {
			width: 0
		}
	}

	@keyframes blink {
		50% {
			border-color: transparent
		}
	}

</style>



