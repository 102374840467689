<template>
  <div>
    <!--Yönetim Menüsü-->
    <div
      v-if="
        (yetkiTuru === '1' || yetkiTuru === '2' || yetkiTuru === '3') &&
        !menuSecim
      "
      class="web"
    >
      <el-menu
        :default-active.sync="activeLink"
        :mode="mode"
        @select="goto"
        :collapse="isCollapse"
        :unique-opened="true"
        background-color="transparent"
        class="main-navigation-menu"
        :class="{ 'nav-collapsed': isCollapse }"
      >
        <el-menu-item
          index="/firma/detay"
          v-if="yetkiTuru === '1' && !menuSecim"
          popper-class="main-navigation-submenu"
        >
          <i class="mdi mdi-city"></i><span slot="title">Firma</span>
        </el-menu-item>
        <el-submenu
          v-if="yetkiTuru === '1' || yetkiTuru === '2'"
          index="personel"
          popper-class="main-navigation-submenu"
        >
          <template slot="title">
            <i class="mdi mdi-account-box"></i><span>Personel</span>
          </template>
          <el-menu-item index="/personel/tanimla">
            <span slot="title">Personel Tanımla</span>
          </el-menu-item>
          <el-menu-item index="/personel/liste">
            <span slot="title">Personel Listesi</span>
          </el-menu-item>
          <el-menu-item index="/yetki/tanimla" v-if="yetkiTuru == 1">
            <span slot="title">Yetki Tanımla</span>
          </el-menu-item>
          <el-submenu index="4-4">
            <template slot="title">Özlük</template>
            <el-menu-item>
              <span slot="title">Personel Özlük Tanımla</span>
            </el-menu-item>
            <el-menu-item>
              <span slot="title">Personel Özlük Listesi</span>
            </el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-menu-item
          v-if="yetkiTuru == 1"
          index="/surec/liste"
          popper-class="main-navigation-submenu"
        >
          <i class="mdi mdi-arrow-up-box"> </i
          ><span slot="title">Akış Şeması</span>
        </el-menu-item>
        <!--
        <el-submenu v-if="(yetkiTuru === '1') && !menuSecim"  index="yetki" popper-class="main-navigation-submenu">
            <template slot="title">
                <i class="mdi mdi-playlist-plus"></i><span>Yetki</span>   <i class="mdi mdi-toggle-switch"></i><span>Yetki</span>
            </template>
            <el-menu-item index="/yetki/tanimla">
                <span slot="title">Yetki Tanımla</span>
            </el-menu-item>
        </el-submenu>
-->
        <el-submenu
          v-if="yetkiTuru === '1' && !menuSecim"
          index="atama"
          popper-class="main-navigation-submenu"
        >
          <template slot="title">
            <i class="mdi mdi-clipboard-account"></i><span>Görev Atama</span>
            <!-- <i class="mdi mdi-toggle-switch"></i><span>Yetki</span>  -->
          </template>
          <el-menu-item index="/yenigorev/tanimla">
            <span slot="title">Yeni Görev Tanımla</span>
          </el-menu-item>
          <!-- <el-menu-item index="/gorev/liste">
            <span slot="title">Görev Listesi</span>
          </el-menu-item> -->
        </el-submenu>
        <el-submenu
          v-if="
            yetkiTuru === '1' ||
            (yetkiTuru === '2' &&
              (yetkiListesi.includes('YK-resmiTatilC100') ||
                yetkiListesi.includes('YK-resmiTatilR200')))
          "
          index="tatil"
          popper-class="main-navigation-submenu"
        >
          <template slot="title">
            <i class="mdi mdi-calendar-blank"></i><span>Resmi Tatil</span>
          </template>
          <el-menu-item
            index="/tatil/tanimla"
            v-if="
              yetkiTuru == '1' ||
              (yetkiTuru == '2' && yetkiListesi.includes('YK-resmiTatilC100'))
            "
          >
            <span slot="title">Tatil Tanımla</span>
          </el-menu-item>
          <el-menu-item
            index="/tatil/liste"
            v-if="
              yetkiTuru == '1' ||
              (yetkiTuru == '2' && yetkiListesi.includes('YK-resmiTatilR200'))
            "
          >
            <span slot="title">Tatil Listesi</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="/kapsam" v-if="yetkiTuru == '2'">
          <i class="mdi mdi-clipboard-text"></i
          ><span slot="title">Görevler</span>
        </el-menu-item>
        <el-menu-item
          index="/gecikme/liste"
          v-if="yetkiTuru === '1' || yetkiTuru === '2'"
          popper-class="main-navigation-submenu"
        >
          <i class="mdi mdi-clock"></i><span slot="title">Gecikmeler</span>
        </el-menu-item>
        <el-menu-item
          index="/degerlendirme/liste"
          v-if="yetkiTuru === '1' || yetkiTuru === '2'"
          popper-class="main-navigation-submenu"
        >
          <i class="mdi mdi-star"></i><span slot="title">Performans</span>
        </el-menu-item>
        <el-submenu index="tutanak" popper-class="main-navigation-submenu">
          <template slot="title">
            <i class="mdi mdi-file-document-outline"></i><span>Tutanak</span>
          </template>
          <el-menu-item index="/tutanak/tanimla">
            <span slot="title">Tutanak Tanımla</span>
          </el-menu-item>
          <el-menu-item index="/tutanak/liste">
            <span slot="title">Tutanak Listesi</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu
          index="faaliyet"
          popper-class="main-navigation-submenu"
          v-if="yetkiTuru == '1' || yetkiTuru == '2' || yetkiTuru == '3'"
        >
          <template slot="title">
            <i class="mdi mdi-file-document-outline"></i
            ><span>Günlük Faaliyet</span>
          </template>
          <el-menu-item index="/faaliyet/tanimla">
            <span slot="title">Faaliyet Tanımla</span>
          </el-menu-item>
          <el-menu-item index="/faaliyet/liste">
            <span slot="title">Faaliyet Listesi</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <!-- FLOWPRO Menüsü -->
    <div
      v-if="(yetkiTuru === '1' || yetkiTuru === '2') && menuSecim"
      class="otomasyon"
    >
      <el-menu
        :default-active.sync="activeLink"
        :mode="mode"
        @select="goto"
        :collapse="isCollapse"
        :unique-opened="true"
        background-color="transparent"
        class="main-navigation-menu"
        :class="{ 'nav-collapsed': isCollapse }"
      >
        <el-submenu
          v-if="
            yetkiTuru === '1' ||
            (yetkiTuru === '2' &&
              (yetkiListesi.includes('YK-musteriC100') ||
                yetkiListesi.includes('YK-musteriR100')))
          "
          index="musteri"
          popper-class="main-navigation-submenu"
        >
          <template slot="title">
            <i class="mdi mdi-account"></i><span>Müşteri</span>
          </template>
          <el-menu-item
            index="/musteri/tanimla"
            v-if="
              yetkiTuru == 1 ||
              (yetkiTuru == 2 && yetkiListesi.includes('YK-musteriC100'))
            "
          >
            <span slot="title">Müşteri Tanımla</span>
          </el-menu-item>
          <el-menu-item
            index="/musteri/liste"
            v-if="
              yetkiTuru == 1 ||
              (yetkiTuru == 2 && yetkiListesi.includes('YK-musteriR100'))
            "
          >
            <span slot="title">Müşteri Listesi</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu
          v-if="
            yetkiTuru === '1' ||
            (yetkiTuru === '2' && yetkiListesi.includes('YK-projeC100')) ||
            yetkiListesi.includes('YK-projeR200')
          "
          index="proje"
          popper-class="main-navigation-submenu"
        >
          <template slot="title">
            <i class="mdi mdi-domain"></i><span slot="title">Proje</span>
          </template>
          <el-menu-item
            index="/proje/tanimla"
            v-if="
              yetkiTuru == 1 ||
              (yetkiTuru == 2 && yetkiListesi.includes('YK-projeC100'))
            "
          >
            <span slot="title">Proje Tanımla</span>
          </el-menu-item>
          <el-menu-item
            index="/proje/liste"
            v-if="
              yetkiTuru == 1 ||
              (yetkiTuru == 2 && yetkiListesi.includes('YK-projeR200'))
            "
          >
            <span slot="title">Proje Listesi</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu
          v-if="
            yetkiTuru === '1' ||
            (yetkiTuru === '2' && yetkiListesi.includes('YK-sozlesmeC100')) ||
            yetkiListesi.includes('YK-sozlesmeR200')
          "
          index="sozlesme"
          popper-class="main-navigation-submenu"
        >
          <template slot="title">
            <i class="mdi mdi-pen"></i><span slot="title">Sözleşme</span>
          </template>
          <el-menu-item
            index="/sozlesme/tanimla"
            v-if="
              yetkiTuru == 1 ||
              (yetkiTuru == 2 && yetkiListesi.includes('YK-sozlesmeC100'))
            "
          >
            <span slot="title">Sözleşme Tanımla</span>
          </el-menu-item>
          <el-menu-item
            index="/sozlesme/liste"
            v-if="
              yetkiTuru == 1 ||
              (yetkiTuru == 2 && yetkiListesi.includes('YK-sozlesmeR200'))
            "
          >
            <span slot="title">Sözleşme Listesi</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu
          v-if="
            yetkiTuru === '1' ||
            (yetkiTuru === '2' && yetkiListesi.includes('YK-projeP200')) ||
            yetkiListesi.includes('YK-musteriP100') ||
            yetkiListesi.includes('YK-projeP100') ||
            yetkiListesi.includes('YK-sozlesmeP100')
          "
          index="rapor"
          popper-class="main-navigation-submenu"
        >
          <template slot="title">
            <i class="mdi mdi-poll"></i><span slot="title">Raporlar</span>
          </template>
          <el-menu-item
            index="/rapor/genel"
            v-if="
              yetkiTuru == '1' ||
              (yetkiTuru == '2' && yetkiListesi.includes('YK-projeP200'))
            "
          >
            <span slot="title">Genel Rapor</span>
          </el-menu-item>
          <el-menu-item
            index="/rapor/musteri"
            v-if="
              yetkiTuru == '1' ||
              (yetkiTuru == '2' && yetkiListesi.includes('YK-musteriP100'))
            "
          >
            <span slot="title">Müşteri Rapor</span>
          </el-menu-item>
          <el-menu-item
            index="/rapor/proje"
            v-if="
              yetkiTuru == '1' ||
              (yetkiTuru == '2' && yetkiListesi.includes('YK-projeP100'))
            "
          >
            <span slot="title">Proje Rapor</span>
          </el-menu-item>
          <el-menu-item
            index="/rapor/surec"
            v-if="
              yetkiTuru == '1' ||
              (yetkiTuru == '2' && yetkiListesi.includes('YK-sozlesmeP100'))
            "
          >
            <span slot="title">Süreç Rapor</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu
          v-if="
            yetkiTuru === '1' ||
            (yetkiTuru === '2' &&
              (yetkiListesi.includes('YK-etutC100') ||
                yetkiListesi.includes('YK-etutR100') ||
                yetkiListesi.includes('YK-etutR200') ||
                yetkiListesi.includes('YK-etutU100') ||
                yetkiListesi.includes('YK-etutD100'))) ||
            (yetkiTuru === '2' && sozlesmeKategoriID == '1')
          "
          index="etut"
          popper-class="main-navigation-submenu"
        >
          <template slot="title">
            <i class="mdi mdi-format-list-bulleted"></i
            ><span slot="title">BG Proje</span>
          </template>
          <el-menu-item
            index="/etut/tanimla"
            v-if="
              yetkiTuru == '1' ||
              (yetkiTuru == '2' && yetkiListesi.includes('YK-etutC100')) ||
              (yetkiTuru === '2' && sozlesmeKategoriID === '1')
            "
          >
            <span slot="title">Etüt Teklif Tanımla</span>
          </el-menu-item>
          <el-menu-item
            index="/etut/liste"
            v-if="
              yetkiTuru == '1' ||
              (yetkiTuru == '2' && yetkiListesi.includes('YK-etutR100')) ||
              (yetkiTuru === '2' && sozlesmeKategoriID === '1')
            "
          >
            <span slot="title">Etüt Teklif Listesi</span>
          </el-menu-item>
        </el-submenu>

        <el-submenu
          v-if="
            yetkiTuru == '1' || (yetkiTuru == '2' && sozlesmeKategoriID == '4')
          "
          index="bgcelik"
          popper-class="main-navigation-submenu"
        >
          <template slot="title">
            <i class="mdi mdi-file-document-outline"></i><span>BG Çelik</span>
          </template>
          <el-menu-item index="/celikmusteri/tanimla">
            <span slot="title"><strong>BGÇelik</strong> Müşteri Tanımla</span>
          </el-menu-item>
          <el-menu-item index="/celikmusteri/liste">
            <span slot="title"><strong>BGÇelik</strong> Müşteri Listesi</span>
          </el-menu-item>
          <el-menu-item index="/siparis/tanimla">
            <span slot="title">Sipariş Tanımla</span>
          </el-menu-item>
          <el-menu-item index="/siparis/liste">
            <span slot="title">Sipariş Listesi</span>
          </el-menu-item>
        </el-submenu>
        <!--
        <el-menu-item index="/personel">
            <i class="mdi mdi-file-outline"></i><span slot="title">Personel</span>
        </el-menu-item>-->
      </el-menu>
    </div>
  </div>
</template>

<script>
import { detect } from "detect-browser";
import JQuery from "jquery";

let $ = JQuery;
const browser = detect();

export default {
  name: "Nav",
  props: ["mode", "isCollapse"],
  computed: {
    menuSecim(val) {
      return this.$store.getters.getMenuSecim;
    },

    sozlesmeKategoriID() {
      return this.$store.getters.getSozlesmeKategoriID;
    },
  },
  watch: {
    menuSecim(val) {
      this.$store.commit("changeRefreshKey");
    },
  },
  data() {
    return {
      yetkiTuru: "",
      isIe: true,
      isEdge: true,
      activeLink: null,
      tabList: [],
      yetkiListesi: [],
      yetkiList: {
        yoneticiTanimlama: false,
        yoneticiListesi: false,
        sayfaTanimlama: false,
        haberTanimlama: false,
        haberKategoriTanimlama: false,
        sayfaListesi: false,
        haberListesi: false,
        haberKategoriListesi: false,
        popupTanimlama: false,
        popuplistesi: false,
        sliderTanimlama: false,
        sliderListesi: false,
        iletisim: false,
        musteriEkle: false,
        urunListeleme: false,
        urunKategoriTanimlamaYet: false,
        urunOpsiyonTanimlamaYet: false,
      },
    };
  },
  methods: {
    goto(index, indexPath, id) {
      let self = this;
      this.tabList = [...this.$store.getters.getTabList];
      /*if (this.$store.state.tabList.length === 0) {
          this.tabList = [];
      } */
      var obj = this.tabList.find((data) => data.title === id.$el.textContent);
      var name = linkTemizle(id.$el.textContent.replace(" ", ""));
      if (obj === undefined) {
        this.tabList.push({
          title: id.$el.textContent,
          name: name,
          contentName: name,
        });
        this.$store.commit("changeTabList", this.tabList);
        this.$store.commit("changeActiveTabs", name);
      } else {
        this.$store.commit("changeActiveTabs", name);
      }
      this.$emit("push-page", { page: index });
      $(".horizontal-nav.pos-top").addClass("shadowBox");
    },
    closeSidebar() {
      this.openSidebar = false;
    },
    setLink(path) {
      this.activeLink = path;
    },
  },
  created() {
    if (browser.name !== "ie") this.isIe = false;
    if (browser.name !== "edge") this.isEdge = false;
    this.setLink(this.$router.currentRoute.path);
    this.$router.afterEach((to, from) => {
      this.setLink(this.$router.currentRoute.path);
    });
    if (localStorage.getItem("userDataBGSurec")) {
      this.yetkiTuru = this.$store.getters.getYetkiTuru;
      //console.log(this.yetkiTuru)
      this.yetkiListesi = this.$store.getters.getYetkiListesi;
      console.log(this.yetkiListesi);
      //console.log(this.yetkiListesi)

      // var tokendecode = jwt_decode(localStorage.getItem("userDataBGSurec"));
      // console.log("tokendecode : ", tokendecode)
      // this.yetkiTuru = tokendecode.yetkiTuru
    }
  },
};

function linkTemizle(text) {
  var trMap = {
    çÇ: "c",
    ğĞ: "g",
    şŞ: "s",
    üÜ: "u",
    ıİ: "i",
    öÖ: "o",
  };
  for (var key in trMap) {
    text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
  }
  return text
    .replace(/[^-a-zA-Z0-9\s]+/gi, "")
    .replace(/\s/gi, "-")
    .replace(/[-]+/gi, "-")
    .toLowerCase();
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";

.main-navigation-menu {
  transition: width 0.5s;

  &:not(.el-menu--collapse) {
    .el-submenu__title,
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      background-color: transparent !important;
    }

    &:not(.el-menu--horizontal) {
      .el-menu-item,
      .el-submenu {
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 0px;
          height: 1px;
          position: absolute;
          bottom: 10px;
          left: 30px;
          background: $text-color;
          z-index: 1;
          opacity: 0;
          transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
        }

        &:hover {
          &::before {
            width: 100px;
            opacity: 1;
            //left: 50px;
            transform: translate(20px, 0);
          }
        }

        &.is-active {
          &::before {
            background: $text-color-accent;
          }
        }
      }
    }

    .el-submenu.is-opened {
      //background: #edf1f6 !important;
      //background: rgba(223, 228, 234, 0.38) !important;
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 2px;
        position: absolute;
        top: 40px;
        bottom: 10px;
        left: 31px;
        background: $text-color;
        z-index: 1;
      }

      &::before {
        display: none;
      }

      .el-menu-item,
      .el-submenu {
        &::before,
        &::after {
          display: none;
        }
      }
    }

    .el-menu-item-group__title {
      padding: 15px 0 0px 20px;
      color: transparentize($text-color, 0.65);
    }
  }

  .el-submenu__title,
  .el-menu-item:not(.is-active) {
    color: $text-color;

    i {
      color: $text-color;
    }
  }

  &.el-menu--collapse {
    .el-menu-item-group__title {
      padding: 15px 0 0px 0px;
      width: 100%;
      text-align: center;
    }

    .el-submenu__title:hover,
    .el-menu-item:hover {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }

  &.el-menu--horizontal {
    white-space: nowrap;
    /*width: fit-content;
        width: max-content;*/
    overflow: hidden;
    display: table;

    & > * {
      float: inherit !important;
      display: inline-block;
    }
  }

  &.nav-collapsed {
    .el-menu-item,
    .el-submenu__title {
      & > span {
        display: none;
      }
    }
  }
}

.main-navigation-submenu {
  .el-menu {
    background: #fff !important;

    .el-menu-item:not(.is-active) {
      color: $text-color;
    }

    .el-menu-item:hover {
      background-color: transparentize($background-color, 0.3) !important;
    }
  }
}

.main-navigation-menu:not(.el-menu--collapse):not(.el-menu--horizontal)
  .el-submenu {
  position: relative;
  border: none !important;
}

.main-navigation-menu:not(.el-menu--collapse):not(.el-menu--horizontal)
  .el-menu-item,
.main-navigation-menu:not(.el-menu--collapse):not(.el-menu--horizontal)
  .el-submenu {
  position: relative;
  border: none !important;
}

@media (min-width: 768px) {
  @import "../assets/scss/_variables";

  .main-navigation-menu {
    transition: width 0.5s;

    &:not(.el-menu--collapse) {
      .el-submenu__title,
      .el-menu-item {
        height: 69px;
        line-height: 20px;
        text-align: -webkit-center;
        background-color: transparent !important;
      }
    }

    &.el-menu--horizontal {
      white-space: nowrap;
      overflow: hidden;
      display: table;

      & > * {
        float: inherit !important;
        display: inline-block;
        border-right: 1px solid #dadfe3;
      }
    }
  }

  .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
    display: table-cell;
    position: relative;
    right: 0;
    top: -3px;
    vertical-align: middle;
  }

  .el-submenu__title span {
    position: relative !important;
    bottom: 2px !important;
  }

  .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    position: relative;
    top: 2px;
  }

  .main-navigation-submenu {
    .el-menu {
      background: #e3eaf0 !important;

      .el-menu-item:not(.is-active) {
        color: $text-color;
      }

      .el-menu-item:hover {
        background-color: transparentize($background-color, 0.3) !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import "../assets/scss/_variables";

.el-menu {
  border: none;
}

.el-menu::before,
.el-menu::after {
  display: none;
}

.el-submenu,
.el-menu-item {
  .mdi {
    vertical-align: middle;
    margin-right: 0;
    display: inline-block;
    width: 24px;
    text-align: center;
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .el-submenu,
  .el-menu-item {
    .mdi {
      vertical-align: middle;
      position: relative;
      top: 5px;
      display: flex;
      width: 24px;
      text-align: center;
      font-size: 17px;
    }
  }
}
</style>
<style scoped>
.main-navigation-menu.el-menu--horizontal > li {
  position: relative;
}

.main-navigation-menu.el-menu--horizontal > li:before {
  position: absolute;
  display: block !important;
  content: "";
  top: -5px;
  left: 0;
  height: 8px;
  width: 100%;
  z-index: 99;
}
</style>
<style lang="scss" scoped>
// Flowpro
.otomasyon {
  .main-navigation-menu.el-menu--horizontal > li:nth-child(1):before {
    background: #006cae;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(2):before {
    background: #0166a5;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(3):before {
    background: #0063a0;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(4):before {
    background: #01629e;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(5):before {
    background: #005991;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(6):before {
    background: #015488;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(7):before {
    background: #014e7e;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(8):before {
    background: #014a77;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(9):before {
    background: #014670;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(10):before {
    background: #013f66;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(11):before {
    background: #013b5f;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(12):before {
    background: #01385a;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(13):before {
    background: #013555;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(14):before {
    background: #012e49;
  }
}

/* Yönetim  */
.web {
  .main-navigation-menu.el-menu--horizontal > li:nth-child(1):before {
    background: #006cae;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(2):before {
    background: #0166a5;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(3):before {
    background: #0063a0;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(4):before {
    background: #01629e;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(5):before {
    background: #005991;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(6):before {
    background: #015488;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(7):before {
    background: #014e7e;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(8):before {
    background: #014a77;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(9):before {
    background: #014670;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(10):before {
    background: #013f66;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(11):before {
    background: #013b5f;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(12):before {
    background: #01385a;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(13):before {
    background: #013555;
  }

  .main-navigation-menu.el-menu--horizontal > li:nth-child(14):before {
    background: #012e49;
  }
}
</style>
