import WsProvider from "./provider/WSProvider";

var serviceName = "webservis";
var moduleName = "personel";

var personelService = {
  imagePath: WsProvider.imagePath,
  ozlukPath: WsProvider.ozlukPath,
  ozlukImagePath: WsProvider.ozlukResim,
  imagePathBuyuk: WsProvider.imagePathBuyuk,
  imagePathOrta: WsProvider.imagePathOrta,
  imagePathKucuk: WsProvider.imagePathKucuk,
  belgePath: WsProvider.documentPath,
  path: WsProvider.path,

  personelSozlesmeListesi(durum, baslangic, limit) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      durum: durum,
      baslangic: baslangic,
      limit,
      serviceName: "surec",
      methodName: "detayliGorevListele",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  gecikenSozlesmeler(baslangic, limit, durum) {
    //30 kapatılan //10 kapatılmayan
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      baslangic,
      limit,
      durum,
      serviceName: "surec",
      methodName: "gecikenSozlesmeler",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  gecikmeDurumDegis(gecikmeID, durum) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      gecikmeID,
      durum,
      serviceName: "surec",
      methodName: "gecikmeIncelendi",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  projeFilter(
    musteriAdi,
    projeBaslik,
    alan,
    ulke,
    il,
    ilce,
    baslangicTarihi,
    bitisTarihi,
    durum,
    baslangic,
    limit
  ) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      musteriAdi,
      projeBaslik,
      alan,
      ulke,
      il,
      ilce,
      baslangicTarihi,
      bitisTarihi,
      durum,
      baslangic,
      limit,
      serviceName: moduleName,
      methodName: "projeArama",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  gecikenSozlesmelerFiltrele(
    sozlesmeKategoriAdi,
    yoneticiAdi,
    musteriAdi,
    projeBaslik,
    sozlesmeBaslik,
    gecikenGun,
    aciklama,
    baslangic,
    limit,
    durum
  ) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      sozlesmeKategoriAdi,
      yoneticiAdi,
      musteriAdi,
      projeBaslik,
      sozlesmeBaslik,
      gecikenGun,
      aciklama,
      baslangic,
      limit,
      durum,
      serviceName: "surec",
      methodName: "gecikenSozlesmelerFiltrele",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  gorevGuncelle(
    gorevAtamaID,
    isDurumu,
    aciklama,
    isTamamlanmaTarihi,
    gecikmeSuresi
  ) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      gorevAtamaID,
      isDurumu,
      aciklama,
      gecikmeSuresi,
      isTamamlanmaTarihi,
      serviceName: "surec",
      methodName: "gorevBitir",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  // ? Ozluk
  createPersonalInfoFiles({ personelID, images, baslik, belge, updateID }) {
    try {
      var token = localStorage.getItem("userDataBGSurec");
      var param = {
        token,
        [`data[${!updateID ? "personelID" : "ozlukID"}]`]: !updateID
          ? personelID
          : updateID,
        "data[baslik]": baslik,
        belge: belge,
        methodName: updateID === undefined ? "ozlukEkle" : "ozlukGuncelle",
        serviceName: moduleName,
      };
      if (images !== undefined && typeof images.forEach === "function") {
        images.forEach((item, index) => (param[`resim[${index}]`] = item));
      }
      var result = new Promise(function (resolve, reject) {
        WsProvider.ajaxPost(serviceName, param)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  },
  createPersonalInfoImage({ personelID, images }) {
    try {
      var token = localStorage.getItem("userDataBGSurec");
      var param = {
        token,
        "data[personelID]": personelID,
        methodName: "resimEkle",
        serviceName: moduleName,
      };
      if (images !== undefined && typeof images.forEach === "function") {
        images.forEach((item, index) => (param[`resim[${index}]`] = item));
      }
      var result = new Promise(function (resolve, reject) {
        WsProvider.ajaxPost(serviceName, param)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  },
  createPersonalInfoFileDetail({ personelID, files, images }) {
    console.log({ personelID, files, images });
    var token = localStorage.getItem("userDataBGSurec");
    var param = {
      token,
      personelID,
      methodName: "ozlukEkle",
      serviceName: moduleName,
    };

    if (typeof files.forEach === "function") {
      files.forEach((item, index) => (param[`belge[${index}]`] = item));
    }

    if (typeof images.forEach === "function") {
      images.forEach((item, index) => (param[`resim[${index}]`] = item));
    }

    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  personelOzlukList(durum = 1, baslangic = 0, limit = 20) {
    var token = localStorage.getItem("userDataBGSurec");
    var param = {
      token,
      "data[baslangic]": baslangic,
      "data[durum]": durum,
      "data[limit]": limit,
      methodName: "listele",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  getPersonalDetail({ personelID }) {
    var token = localStorage.getItem("userDataBGSurec");
    var param = {
      token,
      "data[personelID]": personelID,
      methodName: "ozlukDetay",
      serviceName: moduleName,
    };

    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  personelResimSil(id) {
    var token = localStorage.getItem("userDataBGSurec");
    var param = {
      token,
      "data[id]": id,
      methodName: "resimSil",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  personelOzlukSil(id) {
    var token = localStorage.getItem("userDataBGSurec");
    var param = {
      token,
      "data[ozlukID]": id,
      methodName: "ozlukSil",
      serviceName: moduleName,
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  personelList(durum, baslangic, limit) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      durum,
      baslangic,
      limit,
      methodName: "personelListesi",
      serviceName: "personel",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
};

export default personelService;
