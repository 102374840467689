import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate"; // store değişkenlerinin otomatik olarak localstore'a kaydediyor.

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {
    faaliyetID: "",
    tatilList: [],
    projeSelectedRows: [],
    musteriUpdateData: "",
    tatilUpdateData: "",
    sozlesmeUpdateData: "",
    projeUpdateData: "",
    musteriDetayData: "",
    atamaDetayData: "",
    atamaUpdateData: "",
    tatilDetayData: "",
    projeDetayData: "",
    projeBaslikDetayData: "",
    ilData: "",
    subeUpdateData: "",
    brandUpdateData: "",
    urunUpdateData: "",
    multipleSelectionOrder: [],
    yoneticiUpdateData: "",
    sayfaData: {},
    haberData: {},
    sliderData: {},
    popData: {},
    urunKategoriUpdateData: "",
    galeriUpdateData: {},
    refreshKey: 0,
    tabList: [],
    activeTabs: "",
    updateKodu: "",
    siparisDurum: "2",
    yetkiTuru: "", // 0 = yetkisiz, 1 = root(yonetici), 2 = personel
    yoneticiID: "",
    sozlesmeKategoriID: "",
    yetkiListesi: [],
    secure: [],
    navVisible: true,
    dilDurum: false,
    tabsClose: true,
    sayfaList: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
    layout: {
      menuSecim: false,
      dashboardGorunum: [
        "sayfaSayisi",
        "siparisSayisi",
        "urunSayisi",
        "musteriSayisi",
        "markaSayisi",
        "ziyaretciIstatistik",
        "islemListesi",
        "yoneticiListesi",
      ],
      karanlikMod: true, //true = aydınlık, false = karanlik
      navPos: "top", //top, bottom, left, right, false
      toolbar: "top", //top, bottom, false
      footer: true, //above, below, false
      boxed: false, //true, false
      roundedCorners: false, //true, false
      viewAnimation: "fade-top", // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
    },
    splashScreen: true,
    logged: true,
    userName: "",
    adSoyad: "",
    tutanakData: {
      musteriID: "",
      projeID: "",
    },
    faaliyetData: {
      musteriID: "",
      projeID: "",
    },
    tutanakSearchProjeID: "",
    personelOzlukTanimlamaID: "",
  },
  mutations: {
    setFaaliyetID: (state, value) => {
      state.faaliyetID = value;
    },
    changePersonelOzlukTanimlamaID: (state, value) => {
      state.personelOzlukTanimlamaID = value;
    },
    setTutanakData: (state, value) => {
      if (value) {
        state.tutanakData.musteriID = value.musteriID;
        state.tutanakData.projeID = value.projeID;
      } else {
        state.tutanakData.musteriID = "";
        state.tutanakData.projeID = "";
      }
    },
    setFaaliyetData: (state, value) => {
      if (value) {
        state.faaliyetData.musteriID = value.musteriID;
        state.faaliyetData.projeID = value.projeID;
      } else {
        state.faaliyetData.musteriID = "";
        state.faaliyetData.projeID = "";
      }
    },
    setTutanakSearchProjeID: (state, value) => {
      state.tutanakSearchProjeID = value;
    },
    setTatilList: (state, value) => {
      state.tatilList = value;
    },
    projeSelectedRowsClear: (state, value) => {
      state.projeSelectedRows = [];
    },
    changeProjeSelectedRows: (state, value) => {
      state.projeSelectedRows.push(value);
    },
    removeSelectedRows: (state, value) => {
      state.projeSelectedRows.splice(value, 1);
    },
    changeMusteriUpdateData: (state, value) => {
      state.musteriUpdateData = value;
    },
    changeMusteriDetayData: (state, value) => {
      state.musteriDetayData = value;
    },
    changeAtamaUpdateData: (state, value) => {
      state.atamaUpdateData = value;
    },
    changeAtamaDetayData: (state, value) => {
      state.atamaDetayData = value;
    },
    changeTatilUpdateData: (state, value) => {
      state.tatilUpdateData = value;
    },
    changeTatilDetayData: (state, value) => {
      state.tatilDetayData = value;
    },
    changeProjeDetayData: (state, value) => {
      state.projeDetayData = value;
    },
    changeProjeBaslikDetayData: (state, value) => {
      state.projeBaslikDetayData = value;
    },
    changeSozlesmeUpdateData: (state, value) => {
      state.sozlesmeUpdateData = value;
    },
    changeProjeUpdateData: (state, value) => {
      state.projeUpdateData = value;
    },
    changeIlData: (state, value) => {
      state.ilData = value;
    },
    changeSubeUpdateData: (state, value) => {
      state.subeUpdateData = value;
    },
    changeBrandUpdateData: (state, value) => {
      state.brandUpdateData = value;
    },
    changeYoneticiUpdateData: (state, value) => {
      state.yoneticiUpdateData = value;
    },
    changeUrunUpdateData: (state, value) => {
      state.urunUpdateData = value;
    },
    changeMultipleSelectionOrder: (state, value) => {
      state.multipleSelectionOrder.push(value);
    },
    removeMultipleSelectionOrder: (state, value) => {
      state.multipleSelectionOrder.splice(value, 1);
    },
    clearMultipleSelectionOrder: (state, value) => {
      state.multipleSelectionOrder = [];
    },
    changeRefreshKey: (state, value) => {
      state.refreshKey += 1;
    },
    changeMenuSecim: (state, value) => {
      state.layout.menuSecim = value;
    },
    changeGaleriUpdateData: (state, value) => {
      state.galeriUpdateData = value;
    },
    changeSayfaData: (state, value) => {
      state.sayfaData = value;
    },
    changeHaberData: (state, value) => {
      state.haberData = value;
    },
    changeSliderData: (state, value) => {
      state.sliderData = value;
    },
    changePopData: (state, value) => {
      state.popData = value;
    },
    changeUrunKategoriUpdate: (state, value) => {
      state.urunKategoriUpdateData = value;
    },
    setUserName(state, value) {
      state.userName = value;
    },
    setAdSoyad(state, value) {
      state.adSoyad = value;
    },
    secure: (state, id) => {
      var obj = state.secure.find((data) => data.id === id);
      if (obj) {
        obj.count++;
      } else {
        state.secure.push({
          id: id,
          count: 1,
          sonGiris: new Date().getTime(),
        });
      }
    },
    resSecure: (state, id) => {
      var obj = state.secure.find((data) => data.id === id);
      state.secure.splice(state.secure.indexOf(obj), 1);
    },
    changeModulList: (state, value) => {
      state.modulList = value;
    },
    changeTabList: (state, value) => {
      state.tabList = value;
    },
    changeTabListAdd: (state, value) => {
      state.tabList.push(value);
    },
    changeTabListRemove: (state, value) => {
      state.tabList.splice(value, 1);
    },
    changeActiveTabs: (state, value) => {
      state.activeTabs = value;
    },
    changeUpdateKodu: (state, value) => {
      state.updateKodu = value;
    },
    changeNavVisible: (state, value) => {
      state.navVisible = value;
    },
    changeSayfaList: (state, value) => {
      state.sayfaList = value;
    },
    changeTabsClose: (state, value) => {
      state.tabsClose = value;
    },
    changeGorunum: (state, value) => {
      state.layout.karanlikMod = value;
    },
    changeDashboard: (state, value) => {
      state.layout.dashboardGorunum = value;
    },
    setLayout(state, payload) {
      if (payload && payload.navPos !== undefined) state.layout.navPos = "top";

      if (payload && payload.toolbar !== undefined)
        state.layout.toolbar = payload.toolbar;

      if (payload && payload.footer !== undefined)
        state.layout.footer = payload.footer;

      if (payload && payload.boxed !== undefined)
        state.layout.boxed = payload.boxed;

      if (payload && payload.roundedCorners !== undefined)
        state.layout.roundedCorners = payload.roundedCorners;

      if (payload && payload.viewAnimation !== undefined)
        state.layout.viewAnimation = payload.viewAnimation;
    },
    setLogin(state, payload) {
      state.logged = true;
    },
    setLogout(state, payload) {
      state.layout.navPos = null;
      state.layout.toolbar = null;
      state.logged = false;
    },
    setSplashScreen(state, payload) {
      state.splashScreen = payload;
    },
    setYetkiTuru(state, yetki) {
      state.yetkiTuru = yetki;
    },
    setYoneticiID(state, id) {
      state.yoneticiID = id;
    },
    setSozlesmeKategoriID(state, sozlesmeKategoriID) {
      state.sozlesmeKategoriID = sozlesmeKategoriID;
    },
    setYetkiListesi(state, yetkiler) {
      state.yetkiListesi = yetkiler;
    },
  },
  actions: {},
  getters: {
    getFaaliyetID: (state) => {
      return state.faaliyetID;
    },
    getPersonelOzlukID: (state) => {
      return state.personelOzlukTanimlamaID;
    },
    getTutanakSearchProjeID: (state) => {
      return state.tutanakSearchProjeID;
    },
    getTatilList: (state) => {
      return state.tatilList;
    },
    getTutanakData: (state, value) => {
      return state.tutanakData;
    },
    getFaaliyetData: (state, value) => {
      return state.faaliyetData;
    },
    getProjeSelectedRows: (state, value) => {
      return state.projeSelectedRows;
    },
    getMusteriUpdateData: (state) => {
      return state.musteriUpdateData;
    },
    getMusteriDetayData: (state) => {
      return state.musteriDetayData;
    },
    getAtamaUpdateData: (state) => {
      return state.atamaUpdateData;
    },
    getAtamaDetayData: (state) => {
      return state.atamaDetayData;
    },
    getTatilUpdateData: (state) => {
      return state.tatilUpdateData;
    },
    getTatilDetayData: (state) => {
      return state.tatilDetayData;
    },
    getProjeDetayData: (state) => {
      return state.projeDetayData;
    },
    getProjeBaslikDetayData: (state) => {
      return state.projeBaslikDetayData;
    },
    getSozlesmeUpdateData: (state) => {
      return state.sozlesmeUpdateData;
    },
    getProjeUpdateData: (state) => {
      return state.projeUpdateData;
    },
    getIlData: (state) => {
      return state.ilData;
    },
    getSubeUpdateData: (state) => {
      return state.subeUpdateData;
    },
    getBrandUpdateData: (state) => {
      return state.brandUpdateData;
    },
    getUrunUpdateData: (state) => {
      return state.urunUpdateData;
    },
    getMultipleSelectionOrder: (state) => {
      return state.multipleSelectionOrder;
    },
    getYoneticiUpdateData(state) {
      return state.yoneticiUpdateData;
    },
    getGaleriUpdateData: (state) => {
      return state.galeriUpdateData;
    },
    getRefreshKey: (state) => {
      return state.refreshKey;
    },
    getMenuSecim: (state) => {
      return state.layout.menuSecim;
    },
    getSayfaData(state) {
      return state.sayfaData;
    },
    getHaberData(state) {
      return state.haberData;
    },
    getSliderData(state) {
      return state.sliderData;
    },
    getPopData(state) {
      return state.popData;
    },
    getUrunKategoriData: (state) => {
      return state.urunKategoriUpdateData;
    },
    getUserName(state) {
      return state.userName;
    },
    getAdSoyad(state) {
      return state.adSoyad;
    },
    getSecure: (state) => (id) => {
      var obj = state.secure.find((data) => data.id === id);
      if (obj) {
        return obj.count;
      } else return 1;
    },
    getSecureSonGiris: (state) => (id) => {
      var obj = state.secure.find((data) => data.id === id);
      if (obj) {
        return obj.sonGiris;
      }
    },
    getTabList(state, getters) {
      return state.tabList;
    },
    getUpdateKodu(state, getters) {
      return state.updateKodu;
    },
    layout(state, getters) {
      return state.layout;
    },
    navPos(state, getters) {
      return state.layout.navPos;
    },
    toolbar(state, getters) {
      return state.layout.toolbar;
    },
    footer(state, getters) {
      return state.layout.footer;
    },
    boxed(state, getters) {
      return state.layout.boxed;
    },
    roundedCorners(state, getters) {
      return state.layout.roundedCorners;
    },
    viewAnimation(state, getters) {
      return state.layout.viewAnimation;
    },
    isLogged(state, getters) {
      return state.logged;
    },
    splashScreen(state, getters) {
      return state.splashScreen;
    },
    getYetkiTuru(state, getters) {
      return state.yetkiTuru;
    },
    getYoneticiID(state, getters) {
      return state.yoneticiID;
    },
    getSozlesmeKategoriID(state, getters) {
      return state.sozlesmeKategoriID;
    },
    getYetkiListesi(state, getters) {
      return state.yetkiListesi;
    },
  },
  plugins: [createPersistedState()],
  strict: debug,
});
