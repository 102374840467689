/* ═ ═ ═ ═ ═ ═ ═ ═ ═ *\
|      CORE LIBS      |
\* ═ ═ ═ ═ ═ ═ ═ ═ ═ */
import 'es6-promise/auto'
//import 'es7-shim'
import _ from 'lodash'
import Vue from 'vue'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/tr-TR'
import Vuebar from 'vuebar'
import vClickOutside from 'v-click-outside'
import VueFloatLabel from 'vue-float-label'
import VuePerfectScrollbar from './components/vue-ps'
import md5 from 'md5'


import CKEditor from '@ckeditor/ckeditor5-vue';
import '@ckeditor/ckeditor5-build-classic/build/translations/tr';

Vue.use(CKEditor);

import OtpInput from "@bachdgvn/vue-otp-input";

Vue.component("v-otp-input", OtpInput);

/* ═ ═ ═ ═ ═ ═ ═ ═ ═ *\
|  THIRD PARTS LIBS   |
\* ═ ═ ═ ═ ═ ═ ═ ═ ═ */
import VueI18n from 'vue-i18n'
import moment from 'moment-timezone'
import Fullscreen from 'vue-fullscreen'
import VueResize from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'
import 'flag-icon-css/css/flag-icon.css'
import VueTheMask from 'vue-the-mask'
import VueCookie from 'vue-cookies'
import Peity from 'vue-peity'
import {library} from "@fortawesome/fontawesome-svg-core"
import {fas} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import vueDebounce from 'vue-debounce'
import '@mdi/font/css/materialdesignicons.css'
import VWave from 'v-wave'

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);


/* ═ ═ ═ ═ ═ ═ ═ ═ ═ *\
|     CORE ASSETS     |
\* ═ ═ ═ ═ ═ ═ ═ ═ ═ */
//import './assets/scss/element-variables.scss'
import './assets/scss/global.scss'
import 'flex.box'
import 'animate.css'
//import '../node_modules/mdi/scss/materialdesignicons.scss'
import '../node_modules/@mdi/font/css/materialdesignicons.min.css'
import Affix from './directives/affix'
import App from './App.vue'
import router from './router/'
import store from './store'
import i18n_messages from './i18n.json'

/* ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ *\
|  THIRD PARTS COMPONENTS |
\* ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ */
Vue.use(VWave);
Vue.use(VueResize);
Vue.use(Fullscreen);
Vue.use(VueI18n);
Vue.use(VueTheMask);
Vue.use(VueCookie);

Vue.use(vueDebounce, {
    listenTo: ['input', 'keyup']
})

/* ═ ═ ═ ═ ═ ═ ═ ═ *\
|  CORE COMPONENTS  |
\* ═ ═ ═ ═ ═ ═ ═ ═ */
Vue.use(Element, {locale});
Vue.use(vClickOutside);
Vue.use(VueFloatLabel);
Vue.use(Vuebar);
Vue.component('vue-scroll', VuePerfectScrollbar);
Vue.component('Peity', Peity)
Vue.directive('affix', Affix);
Vue.prototype.$md5 = md5;
Vue.config.productionTip = false

const i18n = new VueI18n({locale: 'tr', messages: i18n_messages});
const sqlapi = "http://localhost:8080/";

new Vue({
    i18n,
    sqlapi,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
