const Rakamlar = resolve => {
    require.ensure(['../views/apps/rakam/Rakamlar'], () => {
        resolve(require('../views/apps/rakam/Rakamlar'))
    })
};

const RakamlarIslem = resolve => {
    require.ensure(['../views/apps/rakam/RakamlarIslem'], () => {
        resolve(require('../views/apps/rakam/RakamlarIslem'))
    })
};

import Vue from 'vue'
Vue.component('rakamlar', RakamlarIslem);

import layouts from '../layout'

export default {
    path: '/rakamlar',
    name: 'rakam',
    component: Rakamlar,
    meta: {
        layout: layouts.navLeft
    },
    children: [
        {
            path: 'islem',
            name: 'rakamislem',
            component: RakamlarIslem,
            meta: {
                layout: layouts.navLeft,
                searchable: true,
                tags: ['app']
            }
        },
    ]
}
