import WsProvider from "./provider/WSProvider";

var serviceName = "webservis";
var moduleName = "resmiTatil";

var tatilService = {
  imagePath: WsProvider.imagePath,
  imagePathBuyuk: WsProvider.imagePathBuyuk,
  imagePathOrta: WsProvider.imagePathOrta,
  imagePathKucuk: WsProvider.imagePathKucuk,
  belgePath: WsProvider.documentPath,
  path: WsProvider.path,

  currentMounthHoliday() {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      serviceName: "resmitatil",
      methodName: "resmiTatilVarmi",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  resmiTatilListele(baslangic, limit, yil) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      baslangic: baslangic,
      limit: limit,
      yil: yil,
      serviceName: moduleName,
      methodName: "resmiTatilListele",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  resmiTatilDetay(resmiTatilID) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      resmiTatilID,
      serviceName: moduleName,
      methodName: "resmiTatilDetay",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  resmiTatilEkle(baslamaTarihi, bitisTarihi, sebep, tamGun, gunMiktari) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      baslamaTarihi: baslamaTarihi,
      bitisTarihi: bitisTarihi,
      sebep: sebep,
      tamGun: tamGun,
      gunMiktari: gunMiktari,
      methodName: "resmiTatilEkle",
      serviceName: moduleName,
    };

    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  resmiTatilFiltrele(
    baslamaTarihi,
    bitisTarihi,
    sebep,
    tamGun,
    gunMiktari,
    baslangic,
    limit
  ) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      baslamaTarihi,
      bitisTarihi,
      sebep,
      tamGun,
      gunMiktari,
      baslangic,
      limit,
      serviceName: moduleName,
      methodName: "resmiTatilFiltrele",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  musteriSayisi(durum) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      durum: durum,
      serviceName: moduleName,
      methodName: "musteriSayisi",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  resmiTatilDuzenle(resmiTatilID, updateList) {
    var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    var param = {
      token: userDataBGSurec,
      resmiTatilID,
      updateList,
      serviceName: moduleName,
      methodName: "resmiTatilDuzenle",
    };
    var result = new Promise(function (resolve, reject) {
      WsProvider.ajaxPost(serviceName, param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
};

export default tatilService;
