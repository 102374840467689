const Sozlesme = resolve => {
    require.ensure(['../views/apps/sozlesme/Sozlesme'], () => {
        resolve(require('../views/apps/sozlesme/Sozlesme'))
    })
};
const SozlesmeListesi = resolve => {
    require.ensure(['../views/apps/sozlesme/SozlesmeListesi'], () => {
        resolve(require('../views/apps/sozlesme/SozlesmeListesi'))
    })
};
const SurecTakipListesi = resolve => {
    require.ensure(['../views/apps/sozlesme/SurecTakipListesi'], () => {
        resolve(require('../views/apps/sozlesme/SurecTakipListesi'))
    })
};
const SozlesmeTanimla = resolve => {
    require.ensure(['../views/apps/sozlesme/SozlesmeTanimla'], () => {
        resolve(require('../views/apps/sozlesme/SozlesmeTanimla'))
    })
};
const SozlesmeGuncelle = resolve => {
    require.ensure(['../views/apps/sozlesme/SozlesmeGuncelle'], () => {
        resolve(require('../views/apps/sozlesme/SozlesmeGuncelle'))
    })
};

import layouts from '../layout'
import Vue from 'vue'
Vue.component('sozlesmelistesi', SozlesmeListesi);
Vue.component('akissemasi', SurecTakipListesi);
Vue.component('sozlesmetanimla', SozlesmeTanimla);
Vue.component('sozlesmeguncelle',SozlesmeGuncelle);

export default {
    path: '/sozlesme',
    name: 'sozlesme',
    component: Sozlesme,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [
        {
            path: 'liste',
            name: 'sozlesmeListesi',
            component: SozlesmeListesi,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                searchable: true,
                tags: ['app']
            }
        }
    ],

}
