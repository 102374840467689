const Musteri = resolve => {
    require.ensure(['../views/apps/musteri/Musteri'], () => {
        resolve(require('../views/apps/musteri/Musteri'))
    })
};
const MusteriTanimla = resolve => {
    require.ensure(['../views/apps/musteri/MusteriTanimla'], () => {
        resolve(require('../views/apps/musteri/MusteriTanimla'))
    })
};
const MusteriListesi = resolve => {
    require.ensure(['../views/apps/musteri/MusteriListesi'], () => {
        resolve(require('../views/apps/musteri/MusteriListesi'))
    })
};
const MusteriGuncelle = resolve => {
    require.ensure(['../views/apps/musteri/MusteriGuncelle'], () => {
        resolve(require('../views/apps/musteri/MusteriGuncelle'))
    })
};


import layouts from '../layout'
import Vue from 'vue'
Vue.component('musterilistesi', MusteriListesi);
Vue.component('musteritanimla', MusteriTanimla);
Vue.component('musteriguncelle', MusteriGuncelle);


export default {
    path: '/musteri',
    name: 'musteri',
    component: Musteri,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [{
        path: 'liste',
        name: 'musteriListesi',
        component: MusteriListesi,
        meta: {
            auth: true,
            layout: layouts.navLeft,
            searchable: true,
            tags: ['app']
        }
    }],

}