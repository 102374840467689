import Vue from 'vue'
import CxltToastr from 'cxlt-vue2-toastr'
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'
import router from './router/index'
import store from './store'

var toastrConfigs = {
    position: 'bottom-right',
    showMethod: 'headShake',
    hideMethod: 'slideOutRight',
};
Vue.use(CxltToastr, toastrConfigs);

const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

var durum = {
    Status: function(message, self, text) {
        switch (message) {
            case 'success':
                self.$toast.success({
                    title: 'Başarılı!',
                    message: text,
                    timeOut: 3000,
                });
                break;
            case 'warning':
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: text,
                    timeOut: 5000,
                });
                break;
            case 'info':
                self.$toast.info({
                    title: 'Bilgi!',
                    message: text,
                    timeOut: 5000,
                });
                break;
            case 'errorMsg':
                self.$toast.info({
                    title: 'Uyarı!',
                    message: capitalize(text),
                    timeOut: 5000,
                });
                break;
            case 100:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'You are not authorized for this operation!',
                    timeOut: 5000,
                });
                break;
            case 150:
                var tabList = [];
                self.$store.commit('changeTabList', tabList);
                self.$store.commit('changeNavVisible', false);
                store.commit('setLogout');
                router.push("/login");
                localStorage.removeItem("userDataBGSurec");
                // self.$toast.info({
                //     title: 'Bilgilendirme',
                //     message: 'Token Hatası! Login Sayfasına Yönlendiriliyorsunuz!',
                //     timeOut: 5000,
                // });

                break;
            case 210:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'Username or password is incorrect',
                    timeOut: 5000,
                });
                break;
            case 250:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'Invalid Transaction!',
                    timeOut: 5000,
                });
                break;
            case 270:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'The data you want to process is passive!',
                    timeOut: 5000,
                });
                break;
            case 280:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'The link you want to trade is the same!',
                    timeOut: 5000,
                });
                break;
            case 305:
                self.$toast.error({
                    title: 'Error Code: 305!',
                    message: 'Missing Data!',
                    timeOut: 5000,
                });
                break;
            case 310:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'An error occurred while updating the link!',
                    timeOut: 5000,
                });
                break;
            case 320:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'Error occurred while updating SEO!',
                    timeOut: 5000,
                });
                break;
            case 330:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'An error occurred while updating the image!',
                    timeOut: 5000,
                });
                break;
            case 340:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'An error occurred while updating the document!',
                    timeOut: 5000,
                });
                break;
            case 350:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'Error occurred during language update!',
                    timeOut: 5000,
                });
                break;
            case 360:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'No changes have been made!',
                    timeOut: 5000,
                });
                break;
            case 380:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'The data you want to process is used elsewhere!',
                    timeOut: 5000,
                });
                break;
            case 400:
                self.$toast.error({
                    title: 'Error Code: 400!',
                    message: 'Kullanıcı Bulunamadı!',
                    timeOut: 10000,
                });
                break;
            case 410:
                self.$toast.error({
                    title: 'Error Code: 410!',
                    message: 'Veri bulunamadı',
                    timeOut: 10000,
                });
                break;
            case 403:
                self.$toast.error({
                    title: 'Error Code: 403!',
                    message: 'Kullanıcı adı veya şifre yanlış.',
                    timeOut: 10000,
                });
                break;
            case 404:
                self.$toast.error({
                    title: 'Error Code: 404!',
                    message: "Metot bulunamadı",
                    timeOut: 10000,
                });
                break;
            case 503:
                self.$toast.error({
                    title: 'Error Code: 503!',
                    message: 'İşlem sırasında bir hata meydana geldi.',
                    timeOut: 10000,
                });
                break;
        }

    },

}
export default durum;