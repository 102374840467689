const Yetki = resolve => {
    require.ensure(['../views/apps/yetki/Yetki'], () => {
        resolve(require('../views/apps/yetki/Yetki'))
    })
};
const YetkiTanimla = resolve => {
    require.ensure(['../views/apps/yetki/YetkiTanimla'], () => {
        resolve(require('../views/apps/yetki/YetkiTanimla'))
    })
};


import layouts from '../layout'
import Vue from 'vue'
Vue.component('yetkitanimla', YetkiTanimla);


export default {
    path: '/yetki',
    name: 'yetki',
    component: Yetki,
    meta: {
        layout: layouts.navLeft
    }

}