<template>
  <div class="page-table scrollable mainDiv" id="affix-container">
    <el-tabs
      class="click-event"
      @tab-click="handleClick"
      style="width: 100%"
      :value="$store.state.activeTabs"
      type="card"
      editable
      @edit="handleTabsEdit"
    >
      <!--<el-tab-pane name="home">
          <span slot="label"><i style="position: relative; top: 5px;" class="mdi mdi-home mdi-18px"></i></span>
          <dashboard></dashboard>
      </el-tab-pane> -->
      <el-tab-pane
        v-for="item in $store.state.tabList"
        :key="item.title"
        :label="item.title"
        :name="item.name"
      >
        <component :is="item.contentName"></component>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import JQuery from "jquery";
import Sortable from "sortablejs";
import Dashboard from "../views/apps/Dashboard";
import functions from "@/functions";

let $ = JQuery;
let base64 = require("base64_js");
export default {
  name: "tabs",
  components: { Dashboard },
  mounted() {
    let state = false;
    if (localStorage.getItem("userDataBGSurec")) {
      state = true;
    }
    let el = document.querySelector(".el-tabs__nav");
    let sortTabs = Sortable.create(el, {
      animation: 200,
      filter: ".el-icon-close",
    });
    $(document).on("mousedown", function (e1) {
      if (e1.which === 2) {
        $(document).one("mouseup", function (e2) {
          if (e1.target === e2.target) {
            var e3 = $.event.fix(e2);
            e3.type = "middleclick";
            $(e2.target).trigger(e3);
          }
        });
      }
    });
    $(document).on("middleclick", ".click-event", function (e) {
      // functions.sayfaKapat(e.target.outerText)
    });
  },
  component: {
    Sortable,
  },
  data() {
    return {
      closeDialog: false,
      targetName: "",
    };
  },
  methods: {
    handleClick(tab, event) {
      this.$store.commit("changeActiveTabs", tab.name);
    },
    handleTabsEdit(targetName, action) {
      if (action === "remove") {
        this.targetName = targetName;
        if (this.$store.state.tabsClose === false) {
          this.closeDialog = true;
        } else {
          this.cik();
        }
        this.cik();
      }
    },
    cik() {
      let tabs = this.$store.getters.getTabList;
      if (tabs.length === 1) {
        $(".horizontal-nav.pos-top").removeClass("shadowBox");
      }
      var obj = tabs.find((data) => data.name === this.targetName);
      if (obj !== undefined) {
        tabs.forEach((tab, index) => {
          if (tab.name === this.targetName) {
            this.$store.commit("changeTabListRemove", index);
            if (tabs[index]) {
              this.$store.commit("changeActiveTabs", tabs[index].name);
            } else if (tabs[index - 1]) {
              this.$store.commit("changeActiveTabs", tabs[index - 1].name);
            }
          }
        });
      }
      this.$store.commit("changeTabsClose", true);
    },
  },
};
</script>

<style lang="scss">
.el-tabs--card > .el-tabs__header {
  background: #4b5970f0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.el-tabs--card > .el-tabs__header {
  animation: 0.5s ease-out fadeInTop;
}

/*.el-tabs__item:first-child .el-icon-close {*/
/*display: none;*/
/*}*/

/*.el-tabs--card > .el-tabs__header .el-tabs__item:first-child.is-active {*/
/*background: unset;*/
/*border-bottom: none;*/
/*border-bottom-color: unset;*/
/*color: #fff;*/
/*}*/

@keyframes fadeInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
</style>
