const Haber = resolve => {
    require.ensure(['../views/apps/haber/Haber'], () => {
        resolve(require('../views/apps/haber/Haber'))
    })
};
const HaberTanimla = resolve => {
    require.ensure(['../views/apps/haber/HaberTanimla'], () => {
        resolve(require('../views/apps/haber/HaberTanimla'))
    })
};
const HaberlerListe = resolve => {
    require.ensure(['../views/apps/haber/HaberlerListe'], () => {
        resolve(require('../views/apps/haber/HaberlerListe'))
    })
};
const HaberlerDuzenle = resolve => {
    require.ensure(['../views/apps/haber/HaberlerDuzenle'], () => {
        resolve(require('../views/apps/haber/HaberlerDuzenle'))
    })
};

const BlogKategoriListe = resolve => {
    require.ensure(['../views/apps/haber/kategori/BlogKategoriTanimlama'], () => {
        resolve(require('../views/apps/haber/kategori/BlogKategoriTanimlama'))
    })
};

import Vue from 'vue'
import layouts from '../layout'

Vue.component('blogtanimla', HaberTanimla);
Vue.component('bloglistesi', HaberlerListe);
Vue.component('blogduzenle', HaberlerDuzenle);
Vue.component('blogkategori-listesi', BlogKategoriListe);


export default {
    path: '/blog',
    name: 'blog',
    component: Haber,
    meta: {
        layout: layouts.navLeft
    },
}
