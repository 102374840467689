const Pop = resolve => {
    require.ensure(['../views/apps/pop/Pop'], () => {
        resolve(require('../views/apps/pop/Pop'))
    })
};
const PopDetay = resolve => {
    require.ensure(['../views/apps/pop/PopDetay'], () => {
        resolve(require('../views/apps/pop/PopDetay'))
    })
};
const PopupTanimla = resolve => {
    require.ensure(['../views/apps/pop/PopupTanimla'], () => {
        resolve(require('../views/apps/pop/PopupTanimla'))
    })
};
const PopupListe = resolve => {
    require.ensure(['../views/apps/pop/PopupListe'], () => {
        resolve(require('../views/apps/pop/PopupListe'))
    })
};
const PopDuzenle = resolve => {
    require.ensure(['../views/apps/pop/PopDuzenle'], () => {
        resolve(require('../views/apps/pop/PopDuzenle'))
    })
};

import Vue from 'vue'
import layouts from '../layout'
Vue.component('PopDetay', PopDetay);
Vue.component('pop-uptanimla', PopupTanimla);
Vue.component('pop-uplistesi', PopupListe);
Vue.component('popupguncelle', PopDuzenle);

export default {
    path: '/pop',
    name: 'pop',
    component: Pop,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
}