import WsProvider from './provider/WSProvider'


var serviceName = "webservis";
var moduleName = "musteri";

var musteriService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    musteriListesi(durum, baslangic, limit, tur = 1) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            tur,
            durum: durum,
            baslangic: baslangic,
            limit,
            serviceName: moduleName,
            methodName: "musteriListesi"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    musteriProjeBaslik() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "musteriProjeBaslik"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    musteriProjeleri(baslangic, limit, musteriID, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            baslangic: baslangic,
            limit,
            musteriID,
            durum: durum,
            serviceName: moduleName,
            methodName: "musteriProjeleri"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    musterilerinProjeleri(durum, baslangic, limit, tur = 1) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            tur,
            durum: durum,
            baslangic: baslangic,
            limit,
            serviceName: moduleName,
            methodName: "musterilerinProjeleri"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    musterilerinProjeleri2(durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum: durum,
            baslangic: baslangic,
            limit,
            serviceName: moduleName,
            methodName: "musterilerinProjeleri2"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    musteriDetay(musteriID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            musteriID,
            serviceName: moduleName,
            methodName: "musteriDetay"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    musteriRapor(musteriID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            musteriID,
            serviceName: moduleName,
            methodName: "musteriRapor"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    musteriEkle(musteriAdi, unvan, telefon, eposta, vergiDairesi, vergiNo, website, adres, ekBilgiler, durum, tur = 1) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            tur,
            musteriAdi: musteriAdi,
            unvan: unvan,
            eposta: eposta,
            telefon: telefon,
            vergiDairesi: vergiDairesi,
            vergiNo: vergiNo,
            website: website,
            adres: adres,
            ekBilgiler: ekBilgiler.length > 0 ? ekBilgiler : '',
            durum: durum,
            methodName: "musteriEkle",
            serviceName: moduleName
        }

        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },
    musteriFilter(firmaAdi, unvan, adres, telefon, eposta, durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            firmaAdi,
            unvan,
            adres,
            telefon,
            eposta,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "musteriArama"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    musteriSayisi(durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum: durum,
            serviceName: moduleName,
            methodName: "musteriSayisi"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    /*
       musteriAktifPasifSayisi() {
           var userDataBGSurec = localStorage.getItem("userDataBGSurec");
           var param = {
               token: userDataBGSurec,
               serviceName: moduleName,
               methodName: "musteriAktifPasifSayisi"
           }
           var result = new Promise(function(resolve, reject) {
               WsProvider.ajaxPost(serviceName, param).then((response) => {
                   resolve(response)
               }).catch(error => {
                   reject(error)
               })
           })
           return result
       },*/
    //  musteriSiparisListesi(musteriKodu, baslangic, limit) {},
    /*
        siparisListele(musteriID, baslangic, limit) {
            var userDataBGSurec = localStorage.getItem("userDataBGSurec");
            var param = {
                token: userDataBGSurec,
                musteriID,
                durum: 30,
                serviceName: moduleName,
                baslangic,
                limit,
                methodName: "musteriSiparisListele"
            }
            var result = new Promise(function(resolve, reject) {
                WsProvider.ajaxPost(serviceName, param).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
            return result
        },*/
    musteriGuncelle(musteriID, updateList, ekbilgiler, tur = 1) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            tur, // ? 1 BG Müşteri, 2 ÇELİK Müşteri
            token: userDataBGSurec,
            adres: updateList.adres,
            durum: updateList.durum,
            eposta: updateList.eposta,
            musteriAdi: updateList.musteriAdi,
            musteriID: updateList.musteriID,
            telefon: updateList.telefon,
            unvan: updateList.unvan,
            vergiDairesi: updateList.vergiDairesi,
            vergiNo: updateList.vergiNo,
            website: updateList.website,
            // ekBilgiler: ekbilgiler,
            serviceName: moduleName,
            methodName: "musteriDuzenle"
        }
        if (ekbilgiler !== undefined && ekbilgiler.length > 0) {
            ekbilgiler.forEach((item, i) => {
                param[`ekBilgiler[${i}][yetkiliAdSoyad]`] = item.yetkiliAdSoyad
                param[`ekBilgiler[${i}][yetkiliEposta]`] = item.yetkiliEposta
                param[`ekBilgiler[${i}][yetkiliTelefon]`] = item.yetkiliTelefon
                param[`ekBilgiler[${i}][yetkiliUnvan]`] = item.yetkiliUnvan
                if (item.musteriDetayID !== undefined) param[`ekBilgiler[${i}][musteriDetayID]`] = item.musteriDetayID
            })
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    musteriDurumDegistir(musteriID, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            musteriID,
            durum,
            serviceName: moduleName,
            methodName: "musteriDurumDegistir"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    musteriSifreGuncelle(musteriID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            musteriID,
            serviceName: moduleName,
            methodName: "musteriSifreGuncelle"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    }

}

export default musteriService