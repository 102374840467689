const Etut = resolve => {
    require.ensure(['../views/apps/etut/Etut'], () => {
        resolve(require('../views/apps/etut/Etut'))
    })
};
const EtutListesi = resolve => {
    require.ensure(['../views/apps/etut/EtutListesi'], () => {
        resolve(require('../views/apps/etut/EtutListesi'))
    })
};
const EtutTanimla = resolve => {
    require.ensure(['../views/apps/etut/EtutTanimla'], () => {
        resolve(require('../views/apps/etut/EtutTanimla'))
    })
};

const EtutGuncelle = resolve => {
    require.ensure(['../views/apps/etut/EtutGuncelle'], () => {
        resolve(require('../views/apps/etut/EtutGuncelle'))
    })
};

import layouts from '../layout'
import Vue from 'vue'
Vue.component('etutteklif-listesi', EtutListesi);
Vue.component('etutteklif-tanimla', EtutTanimla);
Vue.component('etutteklif-guncelle', EtutGuncelle)

export default {
    path: '/etut',
    name: 'etut',
    component: Etut,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [
        {
            path: 'liste',
            name: 'etutListesi',
            component: EtutListesi,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                searchable: true,
                tags: ['app']
            }
        },
    ],

}
